import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from './ui/button';
import { Play, Pause, SkipForward, Volume2, VolumeX, ChevronRight, Loader2 } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Alert, AlertDescription } from './ui/alert';
import useAudioStore from '../hooks/useAudioStore';
import { formatText, hasRussianText } from '../utils/textFormatting';

const SpeechStage = ({ 
    character, 
    text, 
    audioFile,
    questId,
    onNext,
    isTeam = false,
    teamId = null,
    role = '',
    isExiting = false,
    preloadedData = null,
    disabled = false
}) => {
    const [isMuted, setIsMuted] = useState(false);
    const [localError, setLocalError] = useState(null);
    const [canProceed, setCanProceed] = useState(true);
    
    // Определяем, содержит ли текст русские символы
    const isRussianText = hasRussianText(text);
    const formattedText = formatText(text, isRussianText);
    const isRussianCharacter = hasRussianText(character);
    
    const mountedRef = useRef(true);
    const animationTimeoutRef = useRef(null);
    
    const {
        isPlaying,
        progress,
        isLoading,
        error: audioError,
        initSound,
        togglePlayPause,
        skip,
        setVolume,
        cleanup,
        preloadSound
    } = useAudioStore();

    // Предзагрузка следующего аудио, если есть
    useEffect(() => {
        if (preloadedData && preloadedData.audioFile && questId) {
            const nextAudioUrl = `/audio/quests/${questId}/${preloadedData.audioFile}`;
            try {
                preloadSound(nextAudioUrl);
            } catch (error) {
                console.debug('Error preloading next audio:', error);
            }
        }
    }, [preloadedData, questId, preloadSound]);

    // Инициализация аудио
    useEffect(() => {
        mountedRef.current = true;
        
        if (audioFile) {
            const audioUrl = `/audio/quests/${questId}/${audioFile}`;
            try {
                initSound(audioUrl, {});
            } catch (error) {
                console.error('Error initializing audio:', error);
                if (mountedRef.current) {
                    setLocalError('Failed to load audio file');
                }
            }
        }

        return () => {
            mountedRef.current = false;
            if (animationTimeoutRef.current) {
                clearTimeout(animationTimeoutRef.current);
            }
            cleanup();
        };
    }, [audioFile, questId, initSound, cleanup]);

    // Обработчик кнопки "Continue"
    const handleNext = useCallback(() => {
        if (onNext) {
            onNext();
        }
    }, [onNext]);

    // Переключение звука
    const toggleMute = useCallback(() => {
        setIsMuted(prev => {
            const newMuted = !prev;
            setVolume(newMuted ? 0 : 1);
            return newMuted;
        });
    }, [setVolume]);

    // Пропуск аудио
    const handleSkip = useCallback(() => {
        skip();
    }, [skip]);

    return (
        <Card>
            <CardHeader>
                <CardTitle className={`flex items-center justify-between ${isRussianCharacter ? 'font-serif' : ''}`}>
                    <div className={`text-xl md:text-2xl text-primary ${isRussianCharacter ? 'font-serif' : 'font-sans'}`}>
                        {character}
                        {isTeam && (
                            <span className="text-sm ml-2 text-muted-foreground">
                                ({role || 'No Role'})
                            </span>
                        )}
                    </div>
                    {audioFile && (
                        <div className="flex items-center space-x-2">
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={toggleMute}
                                className="rounded-full p-2"
                            >
                                {isMuted ? (
                                    <VolumeX className="h-5 w-5 text-gray-500" />
                                ) : (
                                    <Volume2 className="h-5 w-5 text-gray-500" />
                                )}
                            </Button>
                        </div>
                    )}
                </CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
                {audioFile && (
                    <div className="space-y-4">
                        <div className="flex items-center justify-center space-x-4">
                            <Button
                                onClick={togglePlayPause}
                                disabled={isLoading}
                                className="rounded-full w-12 h-12 flex items-center justify-center"
                            >
                                {isLoading ? (
                                    <Loader2 className="h-6 w-6 animate-spin" />
                                ) : isPlaying ? (
                                    <Pause className="h-6 w-6" />
                                ) : (
                                    <Play className="h-6 w-6" />
                                )}
                            </Button>
                            {!isTeam && (
                                <Button
                                    onClick={handleSkip}
                                    variant="outline"
                                    className="rounded-full"
                                >
                                    <SkipForward className="h-5 w-5" />
                                </Button>
                            )}
                        </div>
                        <div className="space-y-2">
                            <div className="w-full bg-gray-200 rounded-full h-2">
                                <div
                                    className="bg-primary rounded-full h-full transition-all duration-300"
                                    style={{ width: `${progress}%` }}
                                />
                            </div>
                            <div className="flex items-center justify-between text-sm text-muted-foreground">
                                <span>Progress: {Math.round(progress)}%</span>
                                <div className="flex items-center gap-2">
                                    <Volume2 className="h-4 w-4" />
                                    <input 
                                        type="range"
                                        min="0"
                                        max="100"
                                        defaultValue={isMuted ? "0" : "100"}
                                        className="w-24"
                                        onChange={(e) => setVolume(e.target.value / 100)}
                                    />
                                </div>
                            </div>
                        </div>
                        {audioError && (
                            <Alert variant="destructive">
                                <AlertDescription>{audioError}</AlertDescription>
                            </Alert>
                        )}
                    </div>
                )}
                {localError && (
                    <Alert variant="destructive">
                        <AlertDescription className={hasRussianText(localError) ? 'text-ru' : ''}>
                            {localError}
                        </AlertDescription>
                    </Alert>
                )}
                
                {/* Улучшенное отображение текста с поддержкой русского языка и выравниванием по левому краю */}
                <div className={`prose max-w-none text-content ${isRussianText ? 'text-ru' : ''}`}>
                    {formattedText.map((item, index) => {
                        if (item.type === 'heading1') {
                            return <h1 key={index} className={`${item.class} text-left`}>{item.content}</h1>;
                        }
                        if (item.type === 'heading2') {
                            return <h2 key={index} className={`${item.class} text-left`}>{item.content}</h2>;
                        }
                        if (item.type === 'quote') {
                            return <blockquote key={index} className={`${item.class} text-left`}>{item.content}</blockquote>;
                        }
                        return <p key={index} className={`${item.class} text-left`}>{item.content}</p>;
                    })}
                </div>
                
                <div className="flex justify-center pt-4">
                    <Button
                        onClick={handleNext}
                        disabled={disabled}
                    >
                        Continue
                        <ChevronRight className="ml-2 h-4 w-4" />
                    </Button>
                </div>
            </CardContent>
        </Card>
    );
};

SpeechStage.propTypes = {
    character: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    audioFile: PropTypes.string,
    questId: PropTypes.string.isRequired,
    onNext: PropTypes.func.isRequired,
    isTeam: PropTypes.bool,
    teamId: PropTypes.string,
    role: PropTypes.string,
    isExiting: PropTypes.bool,
    preloadedData: PropTypes.object,
    disabled: PropTypes.bool,
};

export default SpeechStage;
