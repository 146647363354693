import React, {
  useState,
  useContext,
  createContext,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

// Create a Context for the Select component
const SelectContext = createContext(null);

/**
 * Select component that manages the open state and selected value.
 *
 * @param {object} props - Component props.
 * @param {string} props.value - The current selected value.
 * @param {function} props.onValueChange - Callback when a new value is selected.
 * @param {React.ReactNode} props.children - Child components (Trigger, Content, Items).
 * @param {string} [props.className] - Additional CSS classes for the container.
 */
const Select = ({ value, onValueChange, children, className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen((prev) => !prev);
  const closeSelect = () => setIsOpen(false);

  // Ref to detect clicks outside the select component
  const selectRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        closeSelect();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const contextValue = {
    value,
    onValueChange,
    isOpen,
    toggleOpen,
    closeSelect,
  };

  return (
    <SelectContext.Provider value={contextValue}>
      <div className={`relative inline-block ${className || ''}`} ref={selectRef}>
        {children}
      </div>
    </SelectContext.Provider>
  );
};

Select.propTypes = {
  value: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

/**
 * SelectTrigger renders the clickable element that toggles the dropdown.
 */
const SelectTrigger = React.forwardRef(({ children, className, ...props }, ref) => {
  const { toggleOpen } = useContext(SelectContext);
  return (
    <button
      type="button"
      ref={ref}
      onClick={toggleOpen}
      className={`w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-left text-sm shadow-sm focus:outline-none focus:border-blue-500 ${className || ''}`}
      {...props}
    >
      {children}
    </button>
  );
});

SelectTrigger.displayName = 'SelectTrigger';

SelectTrigger.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

/**
 * SelectValue displays the currently selected value or a placeholder.
 *
 * @param {object} props - Component props.
 * @param {string} props.placeholder - Placeholder text if no value is selected.
 * @param {string} [props.className] - Additional CSS classes.
 */
const SelectValue = ({ placeholder, className, ...props }) => {
  const { value } = useContext(SelectContext);
  return (
    <span className={`block ${className || ''}`} {...props}>
      {value || placeholder || 'Select an option'}
    </span>
  );
};

SelectValue.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

/**
 * SelectContent renders the dropdown container with the list of items.
 */
const SelectContent = ({ children, className, ...props }) => {
  const { isOpen } = useContext(SelectContext);
  if (!isOpen) return null;
  return (
    <div
      className={`absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg border border-gray-200 ${className || ''}`}
      {...props}
    >
      {children}
    </div>
  );
};

SelectContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

/**
 * SelectItem represents an individual selectable option.
 *
 * @param {object} props - Component props.
 * @param {string} props.value - The value of this option.
 * @param {React.ReactNode} props.children - Display content.
 * @param {string} [props.className] - Additional CSS classes.
 */
const SelectItem = ({ value: itemValue, children, className, ...props }) => {
  const { onValueChange, closeSelect, value } = useContext(SelectContext);
  const handleClick = () => {
    onValueChange(itemValue);
    closeSelect();
  };

  // Highlight the selected item
  const isSelected = value === itemValue;
  return (
    <div
      role="button"
      onClick={handleClick}
      className={`cursor-pointer px-3 py-2 hover:bg-blue-100 ${isSelected ? 'bg-blue-100' : ''} ${className || ''}`}
      {...props}
    >
      {children}
    </div>
  );
};

SelectItem.propTypes = {
  value: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export { Select, SelectTrigger, SelectValue, SelectContent, SelectItem };

