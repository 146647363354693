import React, { useState, useEffect } from 'react';
import { Button } from '../ui/button';
import { Textarea } from '../ui/textarea';
import { Label } from '../ui/label';
import { Alert, AlertDescription } from '../ui/alert';
import { FileUpload } from '../ui/fileUpload';
import { Progress } from '../ui/progress';
import { 
  AlertCircle, 
  Upload, 
  X, 
  Plus,
  ImageIcon,
  Loader2,
  ListOrdered,
  GripVertical
} from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';
import { 
  DragDropContext, 
  Droppable, 
  Draggable 
} from 'react-beautiful-dnd';

const SequenceItem = ({ 
  item, 
  onRemove, 
  onChange,
  onImageUpload,
  questId,
  pointIndex,
  stepIndex,
  index
}) => {
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  
  const handleUploadImage = async (file) => {
    setUploading(true);
    setUploadError(null);
    setUploadProgress(0);
    
    try {
      // Simulate progress while uploading
      const mockProgress = setInterval(() => {
        setUploadProgress(prev => {
          if (prev >= 90) {
            clearInterval(mockProgress);
            return 90;
          }
          return prev + 10;
        });
      }, 200);
      
      // Логируем перед отправкой файла
      console.log(`[SequenceItem] Uploading image for item ${item.id}:`, {
        file: file.name,
        size: file.size,
        type: file.type
      });
      
      // Вызываем функцию загрузки и получаем результат
      const result = await onImageUpload(file);
      clearInterval(mockProgress);
      
      // Логируем ответ сервера
      console.log(`[SequenceItem] Upload response for item ${item.id}:`, result);
      
      if (result) {
        setUploadProgress(100);
        
        // Проверяем, что в ответе есть поле filename
        if (result.filename) {
          console.log(`[SequenceItem] Setting image filename: ${result.filename}`);
          onChange('image', result.filename);
        } else {
          console.warn(`[SequenceItem] No filename in response, using original filename: ${file.name}`);
          onChange('image', file.name);
        }
        
        setTimeout(() => {
          setUploadProgress(0);
        }, 1000);
      }
    } catch (error) {
      console.error('Error uploading sequence item image:', error);
      setUploadError('Failed to upload image');
    } finally {
      setUploading(false);
    }
  };
  
  // Отладочное отображение текущего изображения
  const currentImagePath = item.image 
    ? `/sequence-images/${questId}/${item.image}` 
    : null;
  
  return (
    <div className="border rounded-lg p-3 mb-2 relative">
      <div className="flex justify-between items-center mb-2">
        <div className="flex items-center">
          <div className="h-6 w-6 flex items-center justify-center bg-primary/10 rounded-full text-primary font-medium mr-2">
            {index + 1}
          </div>
          <Label className="text-sm font-medium">Sequence Item</Label>
        </div>
        <Button 
          type="button"
          size="sm" 
          variant="ghost" 
          onClick={onRemove}
          className="text-red-500 p-1 h-7 w-7"
        >
          <X className="h-4 w-4" />
        </Button>
      </div>
      
      <div className="space-y-3">
        <div>
          <Label className="text-xs">Item Text</Label>
          <Textarea
            value={item.text}
            onChange={(e) => onChange('text', e.target.value)}
            placeholder="Enter item text"
            className="mt-1"
            rows={2}
          />
        </div>
        
        <div>
          <Label className="text-xs">Item Image (Optional)</Label>
          <div className="flex items-center gap-2 mt-1">
            {item.image ? (
              <div className="flex items-center justify-between w-full border rounded p-2">
                <div className="flex items-center gap-2">
                  <ImageIcon className="h-4 w-4 text-muted-foreground" />
                  <span className="text-sm">{item.image}</span>
                </div>
                <Button
                  type="button"
                  variant="ghost"
                  size="sm"
                  onClick={() => onChange('image', null)}
                  className="text-red-500 p-1 h-7 w-7"
                >
                  <X className="h-4 w-4" />
                </Button>
              </div>
            ) : (
              <FileUpload
                accept={{ 'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.webp'] }}
                maxSize={5242880} // 5MB
                onUpload={handleUploadImage}
                className="w-full"
                disabled={uploading}
              >
                <Button
                  type="button"
                  variant="outline"
                  className="w-full"
                  disabled={uploading}
                >
                  {uploading ? (
                    <>
                      <Loader2 className="h-4 w-4 animate-spin" />
                      Uploading {uploadProgress}%
                    </>
                  ) : (
                    <>
                      <Upload className="h-4 w-4" />
                      Upload Image
                    </>
                  )}
                </Button>
              </FileUpload>
            )}
          </div>
          
          {/* Отображение загруженного изображения для превью */}
          {item.image && currentImagePath && (
            <div className="mt-2 border p-2 rounded">
              <Label className="text-xs mb-1 block">Preview:</Label>
              <img 
                src={currentImagePath} 
                alt={item.text}
                className="w-full h-32 object-cover rounded-md"
                onError={(e) => {
                  console.error(`Failed to load preview image: ${currentImagePath}`);
                  e.target.style.display = 'none';
                  alert(`Error loading image: ${currentImagePath}`);
                }}
              />
            </div>
          )}
          
          {uploadProgress > 0 && uploadProgress < 100 && (
            <Progress 
              value={uploadProgress} 
              className="h-1 mt-1" 
            />
          )}
          
          {uploadError && (
            <p className="text-sm text-red-500 mt-1">{uploadError}</p>
          )}
        </div>
      </div>
    </div>
  );
};

const SequenceEditor = ({
  sequenceItems = [],
  correctSequence = [],
  onChange,
  onImageUpload,
  questId,
  pointIndex,
  stepIndex,
  validationErrors = {}
}) => {
  const [localItems, setLocalItems] = useState(sequenceItems);
  const [localCorrectSequence, setLocalCorrectSequence] = useState(correctSequence);
  
  // Update parent component whenever local state changes
  useEffect(() => {
    onChange({
      sequenceItems: localItems,
      correctSequence: localCorrectSequence
    });
  }, [localItems, localCorrectSequence, onChange]);
  
  // Update local state when props change
  useEffect(() => {
    setLocalItems(sequenceItems);
    setLocalCorrectSequence(correctSequence);
  }, [sequenceItems, correctSequence]);
  
  const handleAddItem = () => {
    const newItem = {
      id: uuidv4(),
      text: '',
      image: null
    };
    const newItems = [...localItems, newItem];
    setLocalItems(newItems);
    setLocalCorrectSequence([...localCorrectSequence, newItem.id]);
  };
  
  const handleRemoveItem = (itemId) => {
    setLocalItems(localItems.filter(item => item.id !== itemId));
    setLocalCorrectSequence(localCorrectSequence.filter(id => id !== itemId));
  };
  
  const handleItemChange = (itemId, field, value) => {
    setLocalItems(localItems.map(item => 
      item.id === itemId ? { ...item, [field]: value } : item
    ));
  };
  
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    
    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };
    
    const newSequence = reorder(
      localCorrectSequence,
      result.source.index,
      result.destination.index
    );
    
    setLocalCorrectSequence(newSequence);
  };
  
  // Helper to get item by ID
  const getItemById = (itemId) => {
    return localItems.find(item => item.id === itemId);
  };
  
  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <Label className="text-base font-medium">Sequence Items</Label>
        <Button
          type="button"
          size="sm"
          onClick={handleAddItem}
        >
          <Plus className="h-4 w-4" /> 
          Add Item
        </Button>
      </div>
      
      {localItems.length === 0 ? (
        <Alert>
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>
            Add at least two items to create a sequence
          </AlertDescription>
        </Alert>
      ) : (
        <div className="space-y-4">
          <div className="space-y-2">
            <p className="text-sm text-muted-foreground flex items-center gap-1">
              <ListOrdered className="h-4 w-4" />
              Correct Order (drag to reorder)
            </p>
            
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="sequence-order">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="space-y-2 mb-4"
                  >
                    {localCorrectSequence.map((itemId, index) => {
                      const item = getItemById(itemId);
                      if (!item) return null;
                      
                      return (
                        <Draggable 
                          key={itemId} 
                          draggableId={itemId} 
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`flex items-center p-2 rounded border ${
                                snapshot.isDragging ? 'border-primary bg-primary/5' : 'border-gray-200'
                              }`}
                            >
                              <div className="h-5 w-5 bg-primary/10 rounded-full flex items-center justify-center text-primary text-xs mr-2">
                                {index + 1}
                              </div>
                              <div className="text-sm flex-1 truncate">{item.text}</div>
                              <GripVertical className="ml-auto h-4 w-4 text-muted-foreground" />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            
            <div className="space-y-2">
              <p className="text-sm text-muted-foreground flex items-center gap-1">
                <ListOrdered className="h-4 w-4" />
                Edit Sequence Items
              </p>
              
              {localItems.map((item, idx) => (
                <SequenceItem
                  key={item.id}
                  item={item}
                  index={idx}
                  onRemove={() => handleRemoveItem(item.id)}
                  onChange={(field, value) => handleItemChange(item.id, field, value)}
                  onImageUpload={(file) => onImageUpload && onImageUpload(item.id, file)}
                  questId={questId}
                  pointIndex={pointIndex}
                  stepIndex={stepIndex}
                />
              ))}
            </div>
            
            {localItems.length < 2 && (
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertDescription>
                  Add at least two items for sequence ordering
                </AlertDescription>
              </Alert>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SequenceEditor;
