import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Alert, AlertDescription } from '../ui/alert';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '../ui/alert-dialog';
import { Progress } from '../ui/progress';
import { AlertCircle, Plus, Search, Loader2, RefreshCcw } from 'lucide-react';
import { adminApi } from '../../api/adminApi';
import QuestList from './QuestList';
import QuestForm from './QuestForm';
import UserManager from './UserManager';

const QuestManager = () => {
    const [quests, setQuests] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(0);
    const [totalQuests, setTotalQuests] = useState(0);
    const [selectedQuest, setSelectedQuest] = useState(null);
    const [showQuestForm, setShowQuestForm] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState(null);
    const [serverStatus, setServerStatus] = useState(null);
    
    // Состояния для двухэтапного создания квеста
    const [creationStage, setCreationStage] = useState('info'); // 'info' или 'points'
    const [newQuestId, setNewQuestId] = useState(null);

    const pageSize = 10;

    const checkServerConnection = async () => {
        try {
            const result = await adminApi.testServerConnection();
            setServerStatus(result);
            return result.success;
        } catch (error) {
            console.error('Error checking server connection:', error);
            setServerStatus({ success: false, error });
            return false;
        }
    };

    const fetchQuests = async () => {
        try {
            setIsLoading(true);
            setError(null);
            const isConnected = await checkServerConnection();
            if (!isConnected) {
                console.warn('Server connection test failed, but attempting to fetch quests anyway');
            }
            const response = await adminApi.getQuests({
                skip: page * pageSize,
                limit: pageSize,
                search: searchQuery.trim()
            });
            const formattedData = adminApi.formatQuestList(response);
            setQuests(formattedData.quests);
            setTotalQuests(formattedData.total);
        } catch (error) {
            console.error('Error fetching quests:', error);
            const handledError = adminApi.handleError(error);
            setError(handledError);
            setQuests([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchQuests();
    }, [page, searchQuery]);

    useEffect(() => {
        checkServerConnection();
    }, []);

    const handleSearch = useCallback((event) => {
        setSearchQuery(event.target.value);
        setPage(0);
    }, []);

    const handleCreateQuest = () => {
        setSelectedQuest(null);
        setNewQuestId(null);
        setCreationStage('info');
        setShowQuestForm(true);
    };

    // Изменено: При редактировании квеста сразу переходим к этапам и шагам
    const handleEditQuest = async (quest) => {
        try {
            // Сначала получаем полные данные о квесте, чтобы у нас были все поля
            const fullQuest = await adminApi.getQuest(quest.id);
            
            setSelectedQuest(fullQuest);
            setNewQuestId(fullQuest.id);
            
            // Сразу переходим к редактированию этапов и шагов
            setCreationStage('points');
            
            setShowQuestForm(true);
        } catch (error) {
            console.error("Error loading quest for editing:", error);
            setError({
                message: "Failed to load quest details for editing: " + (error.message || "Unknown error")
            });
        }
    };

    const handleToggleStatus = async (quest) => {
        try {
            setIsLoading(true);
            await adminApi.toggleQuestStatus(quest.id);
            await fetchQuests();
        } catch (error) {
            console.error('Error toggling quest status:', error);
            setError(adminApi.handleError(error));
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteClick = (quest) => {
        setDeleteTarget(quest);
        setShowDeleteConfirm(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            setIsLoading(true);
            await adminApi.deleteQuest(deleteTarget.id);
            setShowDeleteConfirm(false);
            setDeleteTarget(null);
            await fetchQuests();
        } catch (error) {
            console.error('Error deleting quest:', error);
            setError(adminApi.handleError(error));
        } finally {
            setIsLoading(false);
        }
    };

    const validateQuestData = (formData) => {
        const errors = {};
        // Изменённое условие: разрешаем цену равную 0, но не допускаем отрицательных значений
        if (formData.price === '' || isNaN(formData.price) || Number(formData.price) < 0) {
            errors.price = 'Valid price is required';
        }
        if (!formData.name.trim()) {
            errors.name = 'Quest name is required';
        }
        if (!formData.description.trim()) {
            errors.description = 'Description is required';
        }
        if (!formData.duration || isNaN(formData.duration) || Number(formData.duration) <= 0) {
            errors.duration = 'Valid duration is required';
        }
        
        // Проверяем наличие points только если мы в режиме редактирования points
        if (creationStage === 'points') {
            if (!formData.points || formData.points.length === 0) {
                errors.points = 'At least one point is required';
            } else {
                formData.points.forEach((point, pointIndex) => {
                    if (!point.character.trim()) {
                        errors[`point_${pointIndex}_character`] = `Character name is required for point ${pointIndex + 1}`;
                    }
                    if (!point.steps || point.steps.length === 0) {
                        errors[`point_${pointIndex}_steps`] = `At least one step is required for point ${pointIndex + 1}`;
                    } else {
                        point.steps.forEach((step, stepIndex) => {
                            if (!step.text.trim()) {
                                errors[`point_${pointIndex}_step_${stepIndex}_text`] = `Text is required for step ${stepIndex + 1}`;
                            }
                            if (step.type === 'riddle' && !step.correctAnswer?.trim()) {
                                errors[`point_${pointIndex}_step_${stepIndex}_answer`] = `Correct answer is required for riddle step ${stepIndex + 1}`;
                            }
                            if (step.type === 'image' && !step.templateText?.trim()) {
                                errors[`point_${pointIndex}_step_${stepIndex}_template`] = `Template text is required for image step ${stepIndex + 1}`;
                            }
                        });
                    }
                });
            }
        }
        
        return errors;
    };

    const handleFormSubmit = async (formData) => {
        try {
            setIsLoading(true);
            setError(null);

            console.log(`[QuestManager] Form submit in stage: ${creationStage}`, formData);

            // Общая валидация основной информации всегда
            const errors = validateQuestData(formData);
            if (Object.keys(errors).length > 0) {
                throw new Error(Object.values(errors).join('. '));
            }

            const basicInfo = {
                name: formData.name,
                description: formData.description,
                price: Number(formData.price),
                duration: Number(formData.duration),
                difficulty: formData.difficulty,
                maxParticipants: Number(formData.maxParticipants),
                allowTeams: formData.allowTeams,
                points: formData.points, // Всегда включаем points в обновление
                is_active: formData.is_active // Включаем статус активности
            };
            
            let questId;
            if (!selectedQuest) {
                // Создание нового квеста
                console.log('[QuestManager] Creating new quest with basic info:', basicInfo);
                const response = await adminApi.createQuest(basicInfo);
                questId = response.id;
                setNewQuestId(questId);
                
                // После создания нового квеста переключаемся на редактирование points
                setCreationStage('points');
                
                // Загружаем созданный квест для получения полной структуры
                try {
                    const updatedQuest = await adminApi.getQuest(questId);
                    setSelectedQuest(updatedQuest);
                } catch (fetchError) {
                    console.error('[QuestManager] Error fetching created quest:', fetchError);
                }
            } else {
                // Обновление существующего квеста
                questId = selectedQuest.id;
                console.log(`[QuestManager] Updating existing quest (${questId})`, basicInfo);
                await adminApi.updateQuest(questId, basicInfo);
                
                // После успешного обновления
                if (creationStage === 'info') {
                    // Если обновляли основную информацию, переходим к points
                    setCreationStage('points');
                } else {
                    // Если уже были в режиме points и успешно обновили, закрываем форму
                    setShowQuestForm(false);
                    setCreationStage('info');
                    setNewQuestId(null);
                    setSelectedQuest(null);
                    await fetchQuests();
                }
            }
        } catch (error) {
            console.error('[QuestManager] Error submitting quest form:', error);
            setError({
                message: error.message || 'Please fix the validation errors'
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleFormCancel = () => {
        if (creationStage === 'points' && !selectedQuest) {
            if (window.confirm("Are you sure you want to cancel? The quest has been created but content will be lost.")) {
                setShowQuestForm(false);
                setCreationStage('info');
                setNewQuestId(null);
                setSelectedQuest(null);
                fetchQuests();
            }
        } else {
            setShowQuestForm(false);
            setCreationStage('info');
            setNewQuestId(null);
            setSelectedQuest(null);
        }
    };

    const handleFileUpload = async (questId, pointIndex, stepIndex, file, fileType) => {
        try {
            console.group('[QuestManager] File Upload');
            console.log('[QuestManager] Current quest ID:', questId);
            console.log('[QuestManager] Upload params:', { pointIndex, stepIndex, fileType });
            
            setIsUploading(true);
            setUploadProgress(0);
            setError(null);
            
            if (!questId) {
                const errorMsg = 'Quest ID is missing. Save the quest first before uploading files.';
                console.error('[QuestManager] ' + errorMsg);
                setError({ message: errorMsg });
                return;
            }
            
            const connectionTest = await adminApi.testServerConnection();
            console.log('[QuestManager] Server connection test:', connectionTest);
            
            if (!connectionTest.success) {
                const errorMsg = 'Cannot connect to server. Please check your connection.';
                console.error('[QuestManager] ' + errorMsg);
                setError({ message: errorMsg });
                return;
            }
            
            if (fileType === 'audio') {
                console.log('[QuestManager] Starting audio upload');
                const result = await adminApi.uploadAudio(
                    questId,
                    pointIndex,
                    stepIndex,
                    file,
                    {
                        onUploadProgress: (progressEvent) => {
                            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            console.log(`[QuestManager] Upload progress: ${progress}%`);
                            setUploadProgress(progress);
                        }
                    }
                );
                console.log('[QuestManager] Audio upload result:', result);
                
                // После успешной загрузки, обновляем данные квеста
                if (selectedQuest && selectedQuest.id === questId) {
                    try {
                        const updatedQuest = await adminApi.getQuest(questId);
                        setSelectedQuest(updatedQuest);
                    } catch (error) {
                        console.warn('[QuestManager] Failed to refresh quest after audio upload:', error);
                    }
                }
                
                return result;
            } else if (fileType === 'template') {
                console.log('[QuestManager] Saving template');
                const { templateText, similarityThreshold } = file;
                const result = await adminApi.saveTemplate(
                    questId,
                    pointIndex,
                    stepIndex,
                    templateText,
                    similarityThreshold
                );
                console.log('[QuestManager] Template save result:', result);
                
                // После успешного сохранения, обновляем данные квеста
                if (selectedQuest && selectedQuest.id === questId) {
                    try {
                        const updatedQuest = await adminApi.getQuest(questId);
                        setSelectedQuest(updatedQuest);
                    } catch (error) {
                        console.warn('[QuestManager] Failed to refresh quest after template save:', error);
                    }
                }
                
                return result;
            } else if (fileType === 'option-image') {
                console.log('[QuestManager] Starting option image upload');
                const { optionId, file: imageFile } = file;
                const result = await adminApi.uploadOptionImage(
                    questId,
                    pointIndex,
                    stepIndex,
                    optionId,
                    imageFile,
                    {
                        onUploadProgress: (progressEvent) => {
                            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setUploadProgress(progress);
                        }
                    }
                );
                console.log('[QuestManager] Option image upload result:', result);
                return result;
            } else if (fileType === 'sequence-image') {
                console.log('[QuestManager] Starting sequence image upload');
                const { itemId, file: imageFile } = file;
                const result = await adminApi.uploadSequenceImage(
                    questId,
                    pointIndex,
                    stepIndex,
                    itemId,
                    imageFile,
                    {
                        onUploadProgress: (progressEvent) => {
                            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setUploadProgress(progress);
                        }
                    }
                );
                console.log('[QuestManager] Sequence image upload result:', result);
                return result;
            }
            
            console.warn('[QuestManager] Unknown file type:', fileType);
            return null;
        } catch (error) {
            console.error('[QuestManager] Error in file upload:', error);
            setError(adminApi.handleError(error));
            throw error;
        } finally {
            console.groupEnd();
            setIsUploading(false);
            setUploadProgress(0);
        }
    };

    return (
        <div className="space-y-6">
            <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                    <CardTitle className="text-2xl font-bold">Quest Management</CardTitle>
                    <div className="flex items-center space-x-2">
                        {serverStatus && (
                            <div className={`px-2 py-1 rounded text-xs ${serverStatus.success 
                                ? 'bg-green-100 text-green-800' 
                                : 'bg-red-100 text-red-800'}`}>
                                {serverStatus.success ? 'Server connected' : 'Server connection issues'}
                            </div>
                        )}
                        <Button 
                            onClick={handleCreateQuest} 
                            disabled={isLoading}
                            variant="brown"
                        >
                            <Plus className="h-4 w-4" />
                            Create Quest
                        </Button>
                    </div>
                </CardHeader>

                <CardContent>
                    <div className="flex items-center space-x-2 mb-4">
                        <div className="relative flex-1">
                            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                            <Input
                                placeholder="Search quests..."
                                value={searchQuery}
                                onChange={handleSearch}
                                className="pl-8"
                                disabled={isLoading}
                            />
                        </div>
                        
                        <Button
                            variant="outline"
                            size="icon"
                            onClick={checkServerConnection}
                            disabled={isLoading}
                            title="Check server connection"
                        >
                            <RefreshCcw className="h-4 w-4" />
                        </Button>
                    </div>

                    {error && (
                        <Alert variant="destructive" className="mb-4">
                            <AlertCircle className="h-4 w-4" />
                            <AlertDescription>{error.message}</AlertDescription>
                        </Alert>
                    )}

                    {isUploading && (
                        <div className="mb-4">
                            <Progress value={uploadProgress} />
                            <p className="text-sm text-muted-foreground mt-1">
                                Uploading... {uploadProgress}%
                            </p>
                        </div>
                    )}

                    <QuestList
                        quests={quests}
                        isLoading={isLoading}
                        onEdit={handleEditQuest}
                        onDelete={handleDeleteClick}
                        onToggleStatus={handleToggleStatus}
                        onUpload={handleFileUpload}
                    />

                    {totalQuests > pageSize && (
                        <div className="flex items-center justify-between mt-4">
                            <Button
                                variant="outline"
                                onClick={() => setPage(p => Math.max(0, p - 1))}
                                disabled={page === 0 || isLoading}
                            >
                                Previous
                            </Button>
                            <span className="text-sm text-muted-foreground">
                                Page {page + 1} of {Math.ceil(totalQuests / pageSize)}
                            </span>
                            <Button
                                variant="outline"
                                onClick={() => setPage(p => p + 1)}
                                disabled={page >= Math.ceil(totalQuests / pageSize) - 1 || isLoading}
                            >
                                Next
                            </Button>
                        </div>
                    )}
                </CardContent>
            </Card>

            {showQuestForm && (
                <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
                    <div className="bg-background rounded-lg w-full max-w-4xl p-6 max-h-[90vh] overflow-y-auto">
                        <QuestForm
                            quest={selectedQuest}
                            questId={newQuestId || selectedQuest?.id}
                            creationStage={creationStage}
                            onSubmit={handleFormSubmit}
                            onCancel={handleFormCancel}
                        />
                    </div>
                </div>
            )}

            {showDeleteConfirm && deleteTarget && (
                <AlertDialog open={showDeleteConfirm} onOpenChange={setShowDeleteConfirm}>
                    <AlertDialogContent>
                        <AlertDialogHeader>
                            <AlertDialogTitle>Delete Quest</AlertDialogTitle>
                            <AlertDialogDescription>
                                {deleteTarget.stats?.purchased > 0 
                                    ? `This quest has been purchased ${deleteTarget.stats.purchased} times. Deleting it may affect users who have bought it. Are you sure you want to proceed?`
                                    : "Are you sure you want to delete this quest? This action cannot be undone."}
                            </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction onClick={handleDeleteConfirm}>Delete</AlertDialogAction>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>
            )}
        </div>
    );
};

export default QuestManager;
