import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from './ui/button';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Alert, AlertDescription } from './ui/alert';
import {
    CheckCircle,
    Loader2,
    Volume2,
    RefreshCcw,
    Lightbulb,
    Check
} from 'lucide-react';
import useAudioStore from '../hooks/useAudioStore';
import api from '../api';
import { formatText, hasRussianText } from '../utils/textFormatting';

const ChoiceStage = ({
    character,
    text,
    audioFile,
    questId,
    options = [],
    correctOptions = [],
    multipleSelect = false,
    onNext,
    showHint,
    onHint,
    hint,
    isTeam = false,
    teamId = null,
    role = '',
    isExiting = false,
    preloadedData = null,
    currentStepNum,
    currentSubStepNum
}) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [resultMessage, setResultMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hintText, setHintText] = useState('');
    const [localError, setLocalError] = useState(null);
    
    // Определяем, содержит ли текст русские символы
    const isRussianText = hasRussianText(text);
    const formattedText = formatText(text, isRussianText);
    const isRussianCharacter = hasRussianText(character);
    
    const mountedRef = useRef(true);

    const {
        isPlaying,
        progress,
        isLoading: audioLoading,
        error: audioError,
        initSound,
        togglePlayPause,
        setVolume,
        cleanup,
        preloadSound
    } = useAudioStore();

    // Предзагрузка следующего аудио
    useEffect(() => {
        if (preloadedData && preloadedData.audioFile && questId) {
            const nextAudioUrl = `/audio/quests/${questId}/${preloadedData.audioFile}`;
            try {
                preloadSound(nextAudioUrl);
            } catch (error) {
                console.debug('Error preloading next audio:', error);
            }
        }
    }, [preloadedData, questId, preloadSound]);

    // Инициализация
    useEffect(() => {
        mountedRef.current = true;

        if (audioFile && questId) {
            const audioUrl = `/audio/quests/${questId}/${audioFile}`;
            try {
                initSound(audioUrl, {});
            } catch (error) {
                console.error('Error initializing audio:', error);
                if (mountedRef.current) {
                    setLocalError('Failed to load audio file');
                }
            }
        }

        return () => {
            mountedRef.current = false;
            cleanup();
        };
    }, [audioFile, questId, initSound, cleanup]);

    // Обработка нажатия на вариант
    const handleOptionSelect = useCallback((optionId) => {
        if (isSubmitting) return;

        setSelectedOptions(prev => {
            // Если вариант уже выбран, удаляем его
            if (prev.includes(optionId)) {
                return prev.filter(id => id !== optionId);
            } 
            // Если единичный выбор, заменяем выбор
            else if (!multipleSelect) {
                return [optionId];
            } 
            // Если множественный выбор, добавляем к выбранным
            else {
                return [...prev, optionId];
            }
        });
    }, [isSubmitting, multipleSelect]);

    // Обработчик подтверждения выбора
    const handleSubmit = useCallback(async () => {
        if (isSubmitting || selectedOptions.length === 0 || !questId) return;

        setIsSubmitting(true);
        setResultMessage('');

        try {
            const formData = new FormData();
            formData.append('quest_id', questId);
            formData.append('point_index', currentStepNum - 1);
            formData.append('step_index', currentSubStepNum);
            
            // Добавляем каждый выбранный вариант как отдельный элемент формы
            selectedOptions.forEach(optionId => {
                formData.append('selected_options', optionId);
            });

            if (isTeam && teamId) {
                formData.append('team_id', teamId);
            }

            const response = await api.post('/verify-choice/', formData);
            
            if (response.data.success) {
                setResultMessage('Correct!');
                
                setTimeout(() => {
                    if (mountedRef.current) {
                        onNext();
                    }
                }, 1500);
            } else {
                setResultMessage(response.data.message);
                // Сбрасываем выбор при неправильном ответе
                setSelectedOptions([]);
            }
        } catch (error) {
            console.error('Error submitting choice:', error);
            setLocalError(error.response?.data?.message || 'Error verifying answer');
        } finally {
            if (mountedRef.current) {
                setIsSubmitting(false);
            }
        }
    }, [
        isSubmitting, 
        selectedOptions, 
        questId, 
        currentStepNum, 
        currentSubStepNum, 
        isTeam, 
        teamId, 
        onNext
    ]);

    // Обработчик показа подсказки
    const handleHintClick = useCallback(() => {
        setHintText(hint);
        if (onHint) {
            onHint();
        }
    }, [hint, onHint]);

    // Функция для получения url изображения опции
    const getOptionImagePath = useCallback((option) => {
        if (!option.image || !questId) return null;
        // Используем сохраненное имя файла напрямую без добавления расширения
        return `/option-images/${questId}/${option.image}`;
    }, [questId]);

    return (
        <Card>
            <CardHeader>
                <CardTitle className={`text-xl md:text-2xl text-primary ${isRussianCharacter ? 'font-serif' : 'font-sans'}`}>
                    {character}
                    {isTeam && (
                        <span className="text-sm ml-2 text-muted-foreground">
                            ({role || 'No Role'})
                        </span>
                    )}
                </CardTitle>
            </CardHeader>

            <CardContent className="space-y-6">
                {audioFile && (
                    <div className="space-y-4">
                        <Button
                            onClick={togglePlayPause}
                            disabled={audioLoading}
                            className="w-full flex items-center justify-center space-x-2"
                            variant="outline"
                        >
                            {audioLoading ? (
                                <Loader2 className="h-5 w-5 animate-spin" />
                            ) : isPlaying ? (
                                <>
                                    <RefreshCcw className="h-5 w-5" />
                                    <span>Pause Audio</span>
                                </>
                            ) : (
                                <>
                                    <Volume2 className="h-5 w-5" />
                                    <span>Play Audio</span>
                                </>
                            )}
                        </Button>
                        {!audioError && (
                            <div className="space-y-2">
                                <div className="w-full bg-gray-200 rounded-full h-2">
                                    <div
                                        className="bg-primary rounded-full h-full transition-all duration-300"
                                        style={{ width: `${progress}%` }}
                                    />
                                </div>
                                <div className="flex items-center justify-between text-sm text-muted-foreground">
                                    <span>Progress: {Math.round(progress)}%</span>
                                    <div className="flex items-center gap-2">
                                        <Volume2 className="h-4 w-4" />
                                        <input
                                            type="range"
                                            min="0"
                                            max="100"
                                            defaultValue="100"
                                            className="w-24"
                                            onChange={(e) => setVolume(e.target.value / 100)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {audioError && (
                            <Alert variant="destructive">
                                <AlertDescription>{audioError}</AlertDescription>
                            </Alert>
                        )}
                    </div>
                )}

                {/* Улучшенное отображение текста с поддержкой русского языка и выравниванием по левому краю */}
                <div className={`prose max-w-none text-content ${isRussianText ? 'text-ru' : ''}`}>
                    {formattedText.map((item, index) => {
                        if (item.type === 'heading1') {
                            return <h1 key={index} className={`${item.class} text-left`}>{item.content}</h1>;
                        }
                        if (item.type === 'heading2') {
                            return <h2 key={index} className={`${item.class} text-left`}>{item.content}</h2>;
                        }
                        if (item.type === 'quote') {
                            return <blockquote key={index} className={`${item.class} text-left`}>{item.content}</blockquote>;
                        }
                        return <p key={index} className={`${item.class} text-left`}>{item.content}</p>;
                    })}
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6">
                    {options.map((option) => {
                        const isSelected = selectedOptions.includes(option.id);
                        const imagePath = getOptionImagePath(option);
                        const isRussianOption = hasRussianText(option.text);
                            
                        return (
                            <div 
                                key={option.id}
                                className={`border rounded-lg p-4 cursor-pointer transition-all duration-300 ${
                                    isSelected 
                                        ? 'border-primary bg-primary/10 shadow-lg' 
                                        : 'border-gray-200 hover:border-primary/50 hover:bg-primary/5'
                                }`}
                                onClick={() => handleOptionSelect(option.id)}
                            >
                                <div className="flex flex-col h-full">
                                    {imagePath && (
                                        <div className="relative mb-3">
                                            <img 
                                                src={imagePath} 
                                                alt={option.text}
                                                className="w-full h-40 object-cover rounded-md"
                                                onError={(e) => {
                                                    console.error(`Failed to load image: ${imagePath}`);
                                                    e.target.style.display = 'none';
                                                }}
                                            />
                                        </div>
                                    )}
                                    <div className="flex items-start gap-3 flex-1">
                                        <div className={`w-5 h-5 rounded-full mt-0.5 flex-shrink-0 border ${
                                            isSelected 
                                                ? 'bg-primary border-primary text-white' 
                                                : 'border-gray-300'
                                        } flex items-center justify-center`}>
                                            {isSelected && <Check className="h-3 w-3 text-white" />}
                                        </div>
                                        <div className="flex-1">
                                            <p className={`text-sm font-medium ${isRussianOption ? 'font-russian text-left' : 'text-left'}`}>
                                                {option.text}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div className="flex flex-col sm:flex-row gap-4 mt-6">
                    <Button
                        onClick={handleSubmit}
                        disabled={selectedOptions.length === 0 || isSubmitting}
                    >
                        {isSubmitting ? (
                            <>
                                <Loader2 className="h-5 w-5 mr-2 animate-spin" />
                                Checking...
                            </>
                        ) : (
                            <>
                                <CheckCircle className="h-5 w-5 mr-2" />
                                Submit Answer
                            </>
                        )}
                    </Button>

                    {showHint && (
                        <Button
                            variant="outline"
                            onClick={handleHintClick}
                            disabled={isSubmitting || hintText !== ''}
                        >
                            <Lightbulb className="h-5 w-5 mr-2" />
                            Get Hint (-10 points)
                        </Button>
                    )}
                </div>

                {hintText && (
                    <Alert>
                        <AlertDescription className={hasRussianText(hintText) ? 'text-ru text-left' : 'text-left'}>
                            {hintText}
                        </AlertDescription>
                    </Alert>
                )}

                {resultMessage && (
                    <Alert variant={resultMessage.includes('Correct') ? 'default' : 'destructive'}>
                        <AlertDescription className={hasRussianText(resultMessage) ? 'text-ru text-left' : 'text-left'}>
                            {resultMessage}
                        </AlertDescription>
                    </Alert>
                )}

                {localError && (
                    <Alert variant="destructive">
                        <AlertDescription className={hasRussianText(localError) ? 'text-ru text-left' : 'text-left'}>
                            {localError}
                        </AlertDescription>
                    </Alert>
                )}
            </CardContent>
        </Card>
    );
};

ChoiceStage.propTypes = {
    character: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    audioFile: PropTypes.string,
    questId: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            image: PropTypes.string
        })
    ),
    correctOptions: PropTypes.arrayOf(PropTypes.string),
    multipleSelect: PropTypes.bool,
    onNext: PropTypes.func.isRequired,
    showHint: PropTypes.bool,
    onHint: PropTypes.func,
    hint: PropTypes.string,
    isTeam: PropTypes.bool,
    teamId: PropTypes.string,
    role: PropTypes.string,
    isExiting: PropTypes.bool,
    preloadedData: PropTypes.object,
    currentStepNum: PropTypes.number.isRequired,
    currentSubStepNum: PropTypes.number.isRequired
};

export default ChoiceStage;
