import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from './ui/button';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Alert, AlertDescription } from './ui/alert';
import { Textarea } from './ui/textarea';
import {
    Send,
    Volume2,
    RefreshCcw,
    Loader2,
    Lightbulb,
    MessageSquare
} from 'lucide-react';
import api from '../api';
import useAudioStore from '../hooks/useAudioStore';
import { formatText, hasRussianText } from '../utils/textFormatting';

const AIChatStage = ({
    character,
    text,
    audioFile,
    questId,
    pointIndex,
    stepIndex,
    showHint,
    onHint,
    isProcessing,
    hint,
    isTeam = false,
    teamId = null,
    role = '',
    imageFile = null,
    onNext // Параметр onNext для перехода к следующему этапу
}) => {
    const [message, setMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [questionCount, setQuestionCount] = useState(0);
    const [remainingQuestions, setRemainingQuestions] = useState(3); // Default, will be updated after first message
    const [isSending, setIsSending] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [showFinalAnswer, setShowFinalAnswer] = useState(false); // Флаг для отображения UI финального ответа
    const chatEndRef = useRef(null);
    
    // Определяем, содержит ли текст русские символы
    const isRussianText = hasRussianText(text);
    const formattedText = formatText(text, isRussianText);
    const isRussianCharacter = hasRussianText(character);

    const {
        isPlaying,
        progress: audioProgress,
        isLoading: audioLoading,
        error: audioError,
        initSound,
        togglePlayPause,
        setVolume,
        cleanup
    } = useAudioStore();

    // Очистка истории чата при размонтировании или изменении этапа
    useEffect(() => {
        // При монтировании компонента и изменении этапа/подэтапа
        // Сбрасываем локальное состояние чата
        setChatHistory([]);
        setQuestionCount(0);
        setRemainingQuestions(3);
        setSuccess(false);
        setError(null);
        setShowFinalAnswer(false);
        
        // При размонтировании отправляем запрос на очистку сессии
        return () => {
            // Если был чат с историей, сбросим историю на сервере
            if (chatHistory.length > 0) {
                resetChatHistory();
            }
            
            // Очищаем аудио при размонтировании
            cleanup();
        };
    }, [questId, pointIndex, stepIndex, isTeam, teamId, character]);

    // Функция для сброса истории чата на сервере
    const resetChatHistory = useCallback(() => {
        try {
            api.post('/chat/reset', {
                quest_id: questId,
                team_id: isTeam ? teamId : null,
                point_index: pointIndex,
                step_index: stepIndex
            }).catch(err => console.error("Failed to reset chat history:", err));
        } catch (error) {
            console.error("Error resetting chat history:", error);
        }
    }, [questId, teamId, isTeam, pointIndex, stepIndex]);

    useEffect(() => {
        if (audioFile) {
            const audioUrl = `/audio/quests/${questId}/${audioFile}`;
            try {
                initSound(audioUrl, {});
            } catch (error) {
                console.error('Error initializing audio:', error);
            }
        }
    }, [audioFile, questId, initSound]);

    useEffect(() => {
        // Scroll to the bottom of the chat when history updates
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatHistory]);

    const handleSendMessage = async () => {
        if (!message.trim() || isSending) return;

        try {
            setIsSending(true);
            setError(null);

            // Использовать функцию финального ответа, если закончились вопросы
            if (remainingQuestions === 0 || showFinalAnswer) {
                await handleSendFinalAnswer();
                return;
            }

            // Prepare data for API request
            const data = {
                quest_id: questId,
                team_id: isTeam ? teamId : null,
                point_index: pointIndex,
                step_index: stepIndex,
                message: message.trim(),
                question_count: questionCount,
                is_final_answer: false // Обычный вопрос, не финальный ответ
            };

            // Add user message to chat history
            setChatHistory(prev => [...prev, {
                sender: 'user',
                text: message,
                timestamp: new Date().toISOString()
            }]);

            // Clear input field
            setMessage('');

            // Отправка сообщения к API
            const response = await api.post('/chat', data);

            // Получаем ответ от AI
            const aiResponse = response.data.response;

            // Add AI response to chat history
            setChatHistory(prev => [...prev, {
                sender: 'ai',
                text: aiResponse,
                timestamp: new Date().toISOString()
            }]);

            // Update question count and remaining questions
            setQuestionCount(response.data.question_count);
            setRemainingQuestions(response.data.remaining_questions);
            
            // Если это предпоследний вопрос, показываем кнопку для финального ответа
            if (response.data.is_pre_final || response.data.remaining_questions === 0) {
                setShowFinalAnswer(true);
            }

            // Проверка на правильный ответ в любой момент диалога
            const lowerResponse = aiResponse.toLowerCase();
            
            // Только если это правильный ответ, переходим к следующему этапу
            if (lowerResponse.startsWith("абсолютно верно!") || lowerResponse.startsWith("absolutely correct!")) {
                console.log("Правильный ответ! Готовимся к переходу.");
                setSuccess(true);
                
                // Добавляем задержку перед переходом на следующий этап
                setTimeout(() => {
                    console.log("Выполняем переход к следующему шагу");
                    if (onNext) {
                        onNext(); // Вызываем функцию перехода после успешного ответа
                    }
                }, 1500); // 1.5 секунды для показа сообщения об успехе
            }

        } catch (error) {
            console.error('Error sending message:', error);
            setError('Failed to send message. Please try again.');
        } finally {
            setIsSending(false);
        }
    };

    // Отправляем финальный ответ для проверки
    const handleSendFinalAnswer = async () => {
        if (!message.trim() || isSending) return;

        try {
            setIsSending(true);
            setError(null);

            // Prepare data for final answer submission
            const data = {
                quest_id: questId,
                team_id: isTeam ? teamId : null,
                point_index: pointIndex,
                step_index: stepIndex,
                message: message.trim(),
                question_count: questionCount,
                is_final_answer: true, // Указываем, что это финальный ответ
                character: character
            };

            // Add user message to chat history
            setChatHistory(prev => [...prev, {
                sender: 'user',
                text: message,
                timestamp: new Date().toISOString(),
                isFinalAnswer: true
            }]);

            // Clear input field
            setMessage('');

            // Отправка сообщения к API
            const response = await api.post('/chat', data);

            // Получаем ответ от AI
            const aiResponse = response.data.response;

            // Add AI response to chat history
            setChatHistory(prev => [...prev, {
                sender: 'ai',
                text: aiResponse,
                timestamp: new Date().toISOString(),
                isAnswerResponse: true
            }]);

            // Проверяем начало ответа
            const lowerResponse = aiResponse.toLowerCase();
            
            if (lowerResponse.startsWith("абсолютно верно!") || lowerResponse.startsWith("absolutely correct!")) {
                console.log("Финальный ответ правильный! Готовимся к переходу.");
                setSuccess(true);
                
                // Добавляем задержку перед переходом на следующий этап
                setTimeout(() => {
                    console.log("Выполняем переход к следующему шагу");
                    if (onNext) {
                        onNext();
                    }
                }, 1500);
            } 
            else if (lowerResponse.startsWith("к сожалению, не верно...") || lowerResponse.startsWith("unfortunately, that's not correct...")) {
                console.log("Финальный ответ неправильный! Снимаем баллы и переходим.");
                setError("Incorrect answer. -15 points");
                
                // Снимаем баллы
                try {
                    // Получаем текущее состояние
                    let stateResponse;
                    if (isTeam) {
                        stateResponse = await api.getTeamStatus(teamId);
                    } else {
                        stateResponse = await api.getQuestState(questId);
                    }
                        
                    // Вычисляем новое количество баллов (минимум 0)
                    const currentPoints = stateResponse.data.points || 100;
                    const newPoints = Math.max(0, currentPoints - 15);
                    
                    // Обновляем состояние
                    if (isTeam) {
                        await api.updateTeamState(teamId, { points: newPoints });
                    } else {
                        await api.updateQuestState(questId, { points: newPoints });
                    }
                    
                    // Ждем 2 секунды и переходим к следующему шагу
                    setTimeout(() => {
                        setError(null);
                        if (onNext) {
                            onNext();
                        }
                    }, 2000);
                } catch (error) {
                    console.error("Error updating quest state:", error);
                    
                    // Даже при ошибке обновления баллов все равно переходим к следующему шагу
                    setTimeout(() => {
                        setError(null);
                        if (onNext) {
                            onNext();
                        }
                    }, 2000);
                }
            }

        } catch (error) {
            console.error('Error sending final answer:', error);
            setError('Failed to send final answer. Please try again.');
        } finally {
            setIsSending(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    return (
        <Card>
            <CardHeader>
                <CardTitle className={`text-xl md:text-2xl text-primary ${isRussianCharacter ? 'font-serif' : 'font-sans'}`}>
                    {character}
                    {isTeam && (
                        <span className="text-sm ml-2 text-muted-foreground">
                            ({role || 'No Role'})
                        </span>
                    )}
                </CardTitle>
            </CardHeader>
            <CardContent className="space-y-6">
                {/* Аудио контент */}
                {audioFile && (
                    <div className="space-y-4">
                        <Button
                            onClick={togglePlayPause}
                            disabled={audioLoading}
                            className="w-full flex items-center justify-center space-x-2"
                            variant="outline"
                        >
                            {audioLoading ? (
                                <Loader2 className="h-5 w-5 animate-spin" />
                            ) : isPlaying ? (
                                <>
                                    <RefreshCcw className="h-5 w-5" />
                                    <span>Pause Audio</span>
                                </>
                            ) : (
                                <>
                                    <Volume2 className="h-5 w-5" />
                                    <span>Play Audio</span>
                                </>
                            )}
                        </Button>
                        {!audioError && (
                            <div className="space-y-2">
                                <div className="w-full bg-gray-200 rounded-full h-2">
                                    <div
                                        className="bg-primary rounded-full h-full"
                                        style={{ width: `${audioProgress}%` }}
                                    />
                                </div>
                                <div className="flex items-center justify-between text-sm text-muted-foreground">
                                    <span>Progress: {Math.round(audioProgress)}%</span>
                                    <div className="flex items-center gap-2">
                                        <Volume2 className="h-4 w-4" />
                                        <input
                                            type="range"
                                            min="0"
                                            max="100"
                                            defaultValue="100"
                                            className="w-24"
                                            onChange={(e) => setVolume(e.target.value / 100)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {audioError && (
                            <Alert variant="destructive">
                                <AlertDescription>{audioError}</AlertDescription>
                            </Alert>
                        )}
                    </div>
                )}

                {/* Улучшенное отображение текста с поддержкой русского языка и выравниванием по левому краю */}
                {text && (
                    <div className={`prose max-w-none text-content ${isRussianText ? 'text-ru' : ''}`}>
                        {formattedText.map((item, index) => {
                            if (item.type === 'heading1') {
                                return <h1 key={index} className={`${item.class} text-left`}>{item.content}</h1>;
                            }
                            if (item.type === 'heading2') {
                                return <h2 key={index} className={`${item.class} text-left`}>{item.content}</h2>;
                            }
                            if (item.type === 'quote') {
                                return <blockquote key={index} className={`${item.class} text-left`}>{item.content}</blockquote>;
                            }
                            return <p key={index} className={`${item.class} text-left`}>{item.content}</p>;
                        })}
                    </div>
                )}

                {/* Блок для отображения изображения */}
                {imageFile && (
                    <div className="my-6">
                        <div className="w-full max-w-md mx-auto bg-white rounded-lg border border-gray-200 overflow-hidden shadow-md">
                            <div className="p-1 bg-gradient-to-r from-amber-500 to-amber-700">
                                <div className="bg-white p-2">
                                    <img
                                        src={`/image-uploads/${questId}/${imageFile}`}
                                        alt={character}
                                        className="w-full h-auto object-cover rounded"
                                        style={{ maxHeight: '320px', objectFit: 'contain', background: '#f8f8f8' }}
                                    />
                                </div>
                            </div>
                            <div className="px-3 py-2 text-sm text-center text-muted-foreground border-t border-gray-100">
                                {character}
                            </div>
                        </div>
                    </div>
                )}

                {/* Chat Interface */}
                <div className="space-y-4">
                    <div className="flex items-center justify-between">
                        <h3 className={`text-lg font-medium ${isRussianCharacter ? 'font-serif' : ''}`}>
                            {isRussianText ? `Чат с ${character}` : `Chat with ${character}`}
                        </h3>
                        {remainingQuestions > 0 && (
                            <span className="text-sm text-muted-foreground">
                                {isRussianText 
                                    ? `Осталось вопросов: ${remainingQuestions}` 
                                    : `Questions left: ${remainingQuestions}`}
                            </span>
                        )}
                        {remainingQuestions === 0 && (
                            <span className="text-sm text-amber-500 font-medium">
                                {isRussianText ? "Время для финального ответа!" : "Time for your final answer!"}
                            </span>
                        )}
                    </div>

                    <div className="bg-muted/50 rounded-lg p-4 h-80 overflow-y-auto space-y-4">
                        {chatHistory.length === 0 ? (
                            <div className="flex flex-col items-center justify-center h-full text-center text-muted-foreground">
                                <MessageSquare className="h-12 w-12 mb-2 text-muted-foreground/50" />
                                <p className={isRussianText ? 'text-ru no-indent text-left' : 'text-left'}>
                                    {isRussianText 
                                      ? "Начните диалог, отправив сообщение!" 
                                      : "Start the conversation by sending a message!"}
                                </p>
                                <p className={`text-sm mt-2 ${isRussianText ? 'text-ru no-indent text-left' : 'text-left'}`}>
                                    {isRussianText 
                                      ? "Задайте вопросы персонажу, чтобы выяснить, кто он." 
                                      : "Ask questions to find out who this character is."}
                                </p>
                            </div>
                        ) : (
                            <>
                                {chatHistory.map((msg, index) => (
                                    <div
                                        key={index}
                                        className={`flex ${msg.sender === 'user' ? 'justify-end' : 'justify-start'}`}
                                    >
                                        <div
                                            className={`max-w-3/4 rounded-lg px-4 py-2 ${
                                                msg.sender === 'user'
                                                ? 'bg-primary text-primary-foreground'
                                                : 'bg-muted'
                                            } ${hasRussianText(msg.text) ? 'text-ru no-indent text-left' : 'text-left'}`}
                                        >
                                            {msg.isFinalAnswer && (
                                                <div className="text-xs mb-1 opacity-75">
                                                    {isRussianText ? 'Финальный ответ:' : 'Final answer:'}
                                                </div>
                                            )}
                                            <p className="whitespace-pre-wrap text-left">{msg.text}</p>
                                        </div>
                                    </div>
                                ))}
                                <div ref={chatEndRef} />
                            </>
                        )}
                    </div>

                    <div className="flex flex-col space-y-2">
                        {/* Улучшенный блок ввода для мобильных устройств */}
                        <div className="relative flex flex-col sm:flex-row sm:space-x-2">
                            <Textarea
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                onKeyPress={handleKeyPress}
                                placeholder={remainingQuestions === 0 
                                    ? (isRussianText ? "Введите ваш ответ, кто этот персонаж..." : "Enter your answer about who this character is...")
                                    : (isRussianText ? "Введите сообщение..." : "Type your message...")
                                }
                                className={`flex-1 pr-12 sm:pr-3 min-h-[80px] ${hasRussianText(message) ? 'font-russian' : ''}`}
                                disabled={isSending || isProcessing || success}
                            />
                            <div className="flex mt-2 sm:mt-0">
                                <Button
                                    onClick={handleSendMessage}
                                    disabled={!message.trim() || isSending || isProcessing || success}
                                    className="flex-1 sm:flex-initial"
                                    size="sm"
                                >
                                    {isSending ? (
                                        <Loader2 className="h-4 w-4 animate-spin mr-1" />
                                    ) : (
                                        <Send className="h-4 w-4 mr-1" />
                                    )}
                                    {isRussianText ? "Отправить" : "Send"}
                                </Button>
                            </div>
                        </div>
                    </div>

                    {error && (
                        <Alert variant="destructive">
                            <AlertDescription className={hasRussianText(error) ? 'text-ru no-indent text-left' : 'text-left'}>
                                {error}
                            </AlertDescription>
                        </Alert>
                    )}

                    {success && (
                        <Alert variant="default" className="bg-green-50 border-green-200">
                            <AlertDescription className="text-green-800 text-left">
                                {isRussianText 
                                  ? "Правильный ответ! Переходим к следующему шагу..." 
                                  : "Correct answer! Moving to the next step..."}
                            </AlertDescription>
                        </Alert>
                    )}
                </div>

                {showHint && (
                    <Button
                        variant="outline"
                        onClick={onHint}
                        disabled={isProcessing || isSending}
                    >
                        <Lightbulb className="w-4 h-4 mr-2" />
                        {isRussianText ? "Получить подсказку (-10 баллов)" : "Get Hint (-10 points)"}
                    </Button>
                )}

                {hint && (
                    <Alert>
                        <AlertDescription className={hasRussianText(hint) ? 'text-ru text-left' : 'text-left'}>
                            {hint}
                        </AlertDescription>
                    </Alert>
                )}
            </CardContent>
        </Card>
    );
};

AIChatStage.propTypes = {
    character: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    audioFile: PropTypes.string,
    questId: PropTypes.string.isRequired,
    pointIndex: PropTypes.number,
    stepIndex: PropTypes.number,
    showHint: PropTypes.bool,
    onHint: PropTypes.func,
    isProcessing: PropTypes.bool,
    hint: PropTypes.string,
    isTeam: PropTypes.bool,
    teamId: PropTypes.string,
    role: PropTypes.string,
    imageFile: PropTypes.string,
    onNext: PropTypes.func
};

export default AIChatStage;
