import api from '../api';

class AdminApiError extends Error {
    constructor(type, message, details = null) {
        super(message);
        this.type = type;
        this.details = details;
    }
}

export const adminApi = {
    // CRUD операции с квестами
    getQuests: async (params = { skip: 0, limit: 50, search: '' }) => {
        try {
            const response = await api.get('/admin/quests', { 
                params: {
                    ...params,
                    search: params.search?.trim()
                }
            });
            return response.data;
        } catch (error) {
            throw adminApi.handleError(error);
        }
    },

    getQuest: async (id) => {
        try {
            const response = await api.get(`/admin/quests/${id}`);
            return adminApi.formatQuest(response.data);
        } catch (error) {
            throw adminApi.handleError(error);
        }
    },

    createQuest: async (data) => {
        try {
            const response = await api.post('/admin/quests', data);
            return response.data;
        } catch (error) {
            throw adminApi.handleError(error);
        }
    },

    updateQuest: async (id, data) => {
        try {
            const response = await api.put(`/admin/quests/${id}`, data);
            return response.data;
        } catch (error) {
            throw adminApi.handleError(error);
        }
    },

    deleteQuest: async (id) => {
        try {
            const response = await api.delete(`/admin/quests/${id}`);
            return response.data;
        } catch (error) {
            throw adminApi.handleError(error);
        }
    },

    toggleQuestStatus: async (questId) => {
        try {
            const response = await api.put(`/admin/quests/${questId}/toggle-status`);
            return response.data;
        } catch (error) {
            throw adminApi.handleError(error);
        }
    },

    // Операции с пользователями
    getUsers: async (params = { skip: 0, limit: 50, search: '' }) => {
        try {
            const response = await api.get('/admin/users', {
                params: {
                    ...params,
                    search: params.search?.trim()
                }
            });
            return response.data;
        } catch (error) {
            throw adminApi.handleError(error);
        }
    },

    deleteUser: async (userId) => {
        try {
            const response = await api.delete(`/admin/users/${userId}`);
            return response.data;
        } catch (error) {
            throw adminApi.handleError(error);
        }
    },

    // Исправленная функция загрузки аудио
    uploadAudio: async (questId, pointIndex, stepIndex, file, config = {}) => {
        try {
            console.group('Audio Upload Process');
            console.log('Starting upload with params:', {
                questId, 
                pointIndex, 
                stepIndex, 
                fileName: file.name, 
                fileSize: file.size, 
                fileType: file.type
            });
            
            // Проверка корректности файла
            try {
                await adminApi.validateAudioFile(file);
                console.log('File validation passed');
            } catch (validationError) {
                console.error('File validation failed:', validationError);
                throw validationError;
            }
            
            // Проверка доступности сервера
            try {
                const pingResponse = await api.get('/admin/ping-upload');
                console.log('Server ping response:', pingResponse.data);
            } catch (pingError) {
                console.warn('Server ping failed, but attempting upload anyway:', pingError.message);
            }

            // Формируем данные для отправки
            const formData = new FormData();
            
            // ВАЖНО: порядок добавления полей имеет значение для multipart/form-data
            // Сначала добавляем текстовые поля, затем файл
            formData.append('quest_id', questId);
            formData.append('point_index', pointIndex);
            formData.append('step_index', stepIndex);
            formData.append('file', file);
            
            console.log('FormData created successfully');
            
            // Отладка содержимого FormData
            for (let [key, value] of formData.entries()) {
                if (key === 'file') {
                    console.log(`FormData: ${key} = [File ${value.name}, ${value.size} bytes, ${value.type}]`);
                } else {
                    console.log(`FormData: ${key} = ${value}`);
                }
            }
            
            // Детальная отладка запроса перед отправкой
            console.log('About to send POST request to: /api/admin/audio');
            
            // Упрощаем конфигурацию запроса для лучшей отладки
            const uploadConfig = {
                // НЕ указываем явно Content-Type для FormData, 
                // браузер сам установит его с правильным boundary
                headers: {
                    // Явно удаляем Content-Type, если он установлен в конфиге
                    'Content-Type': undefined
                },
                // Отслеживание прогресса загрузки
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    console.log(`Upload progress: ${percentCompleted}%`);
                    if (config.onUploadProgress) {
                        config.onUploadProgress(progressEvent);
                    }
                },
                // Увеличенный таймаут для больших файлов
                timeout: 60000,
                ...config
            };
            
            // Отправка запроса напрямую через axios без использования api.post
            try {
                const startTime = Date.now();
                console.log(`Upload started at ${new Date(startTime).toISOString()}`);
                
                // Используем глобальный axios вместо api для тестирования
                const axios = window.axios || api;
                const response = await axios.post(
                    '/api/admin/audio', 
                    formData, 
                    uploadConfig
                );
                
                const endTime = Date.now();
                console.log(`Upload completed in ${endTime - startTime}ms`);
                console.log('Server response:', response.data);
                
                console.groupEnd();
                return response.data;
            } catch (uploadError) {
                console.error('Upload request failed:', uploadError);
                throw uploadError;
            }
            
        } catch (error) {
            console.error('Error in uploadAudio:', error);
            
            // Детальная диагностика ошибки
            if (error.response) {
                // Ошибка с ответом от сервера
                console.error('Server responded with error:', {
                    status: error.response.status,
                    statusText: error.response.statusText,
                    data: error.response.data,
                    headers: error.response.headers
                });
            } else if (error.request) {
                // Запрос был отправлен, но ответа не получено
                console.error('No response received from server. Request details:', {
                    method: error.request.method,
                    url: error.request.url,
                    responseURL: error.request.responseURL,
                    status: error.request.status,
                    statusText: error.request.statusText,
                    readyState: error.request.readyState
                });
            } else {
                // Ошибка при настройке запроса
                console.error('Request setup error:', error.message);
            }
            
            console.groupEnd();
            throw adminApi.handleError(error);
        }
    },

    // Добавленный метод для загрузки изображений для AI Chat
    uploadAIChatImage: async (questId, pointIndex, stepIndex, file, config = {}) => {
        try {
            console.group('AI Chat Image Upload Process');
            console.log('Starting upload with params:', {
                questId, 
                pointIndex, 
                stepIndex, 
                fileName: file.name, 
                fileSize: file.size, 
                fileType: file.type
            });
            
            // Проверка файла
            if (!file) {
                throw new AdminApiError(
                    'validation',
                    'No file provided',
                    { field: 'file', type: 'missing_file' }
                );
            }
            
            // Проверка типа файла
            const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
            const validExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp'];
            const maxSize = 5 * 1024 * 1024; // 5MB
            
            // Проверка MIME типа
            let isValidType = validTypes.includes(file.type);
            
            // Если MIME тип не соответствует, проверяем расширение файла
            if (!isValidType) {
                const extension = file.name.split('.').pop().toLowerCase();
                isValidType = validExtensions.includes(`.${extension}`);
                
                if (!isValidType) {
                    throw new AdminApiError(
                        'validation',
                        `Invalid image file type: ${file.type}. Supported formats: JPG, PNG, GIF, WEBP`,
                        { field: 'file', type: 'invalid_type' }
                    );
                }
            }

            // Проверка размера файла
            if (file.size > maxSize) {
                throw new AdminApiError(
                    'validation',
                    `Image file is too large (${(file.size / 1024 / 1024).toFixed(2)}MB). Maximum size is 5MB`,
                    { field: 'file', type: 'file_too_large' }
                );
            }
            
            // Формируем данные для отправки
            const formData = new FormData();
            formData.append('quest_id', questId);
            formData.append('point_index', pointIndex);
            formData.append('step_index', stepIndex);
            formData.append('file', file);
            
            console.log('AI Chat image FormData created successfully');
            
            // Упрощаем конфигурацию запроса
            const uploadConfig = {
                headers: {
                    'Content-Type': undefined
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    console.log(`Upload progress: ${percentCompleted}%`);
                    if (config.onUploadProgress) {
                        config.onUploadProgress(progressEvent);
                    }
                },
                timeout: 60000,
                ...config
            };
            
            // Отправка запроса
            const startTime = Date.now();
            console.log(`AI Chat image upload started at ${new Date(startTime).toISOString()}`);
            
            const response = await api.post('/admin/aichat-image', formData, uploadConfig);
            
            const endTime = Date.now();
            console.log(`AI Chat image upload completed in ${endTime - startTime}ms`);
            console.log('Server response:', response.data);
            
            console.groupEnd();
            return response.data;
        } catch (error) {
            console.error('Error in uploadAIChatImage:', error);
            console.groupEnd();
            throw adminApi.handleError(error);
        }
    },

    // Метод для загрузки изображений для вариантов выбора
    uploadOptionImage: async (questId, pointIndex, stepIndex, optionId, file, config = {}) => {
        try {
            console.group('Option Image Upload Process');
            console.log('Starting upload with params:', {
                questId, 
                pointIndex, 
                stepIndex, 
                optionId,
                fileName: file.name, 
                fileSize: file.size, 
                fileType: file.type
            });
            
            // Формируем данные для отправки
            const formData = new FormData();
            formData.append('quest_id', questId);
            formData.append('point_index', pointIndex);
            formData.append('step_index', stepIndex);
            formData.append('option_id', optionId);
            formData.append('file', file);
            
            console.log('FormData created successfully');
            
            // Упрощаем конфигурацию запроса для лучшей отладки
            const uploadConfig = {
                headers: {
                    'Content-Type': undefined
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    console.log(`Upload progress: ${percentCompleted}%`);
                    if (config.onUploadProgress) {
                        config.onUploadProgress(progressEvent);
                    }
                },
                timeout: 60000,
                ...config
            };
            
            // Отправка запроса
            const startTime = Date.now();
            console.log(`Upload started at ${new Date(startTime).toISOString()}`);
            
            const response = await api.post('/admin/option-image', formData, uploadConfig);
            
            const endTime = Date.now();
            console.log(`Upload completed in ${endTime - startTime}ms`);
            console.log('Server response:', response.data);
            
            console.groupEnd();
            return response.data;
        } catch (error) {
            console.error('Error in uploadOptionImage:', error);
            console.groupEnd();
            throw adminApi.handleError(error);
        }
    },

    // Добавляем метод для загрузки изображений для элементов последовательности
    uploadSequenceImage: async (questId, pointIndex, stepIndex, itemId, file, config = {}) => {
        try {
            console.group('Sequence Image Upload Process');
            console.log('Starting upload with params:', {
                questId, 
                pointIndex, 
                stepIndex, 
                itemId,
                fileName: file.name, 
                fileSize: file.size, 
                fileType: file.type
            });
            
            // Формируем данные для отправки
            const formData = new FormData();
            formData.append('quest_id', questId);
            formData.append('point_index', pointIndex);
            formData.append('step_index', stepIndex);
            formData.append('item_id', itemId);
            formData.append('file', file);
            
            console.log('FormData created successfully');
            
            // Упрощаем конфигурацию запроса для лучшей отладки
            const uploadConfig = {
                headers: {
                    'Content-Type': undefined
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    console.log(`Upload progress: ${percentCompleted}%`);
                    if (config.onUploadProgress) {
                        config.onUploadProgress(progressEvent);
                    }
                },
                timeout: 60000,
                ...config
            };
            
            // Отправка запроса
            const startTime = Date.now();
            console.log(`Upload started at ${new Date(startTime).toISOString()}`);
            
            const response = await api.post('/admin/sequence-image', formData, uploadConfig);
            
            const endTime = Date.now();
            console.log(`Upload completed in ${endTime - startTime}ms`);
            console.log('Server response:', response.data);
            
            console.groupEnd();
            return response.data;
        } catch (error) {
            console.error('Error in uploadSequenceImage:', error);
            console.groupEnd();
            throw adminApi.handleError(error);
        }
    },

    saveTemplate: async (questId, pointIndex, stepIndex, templateText, similarityThreshold = 25.0) => {
        try {
            if (!templateText || !templateText.trim()) {
                throw new Error('Template text is required for image comparison');
            }

            // Создаем FormData
            const formData = new FormData();
            formData.append('quest_id', questId);
            formData.append('point_index', pointIndex);
            formData.append('step_index', stepIndex);
            formData.append('template_text', templateText);
            formData.append('similarity_threshold', similarityThreshold);

            console.log('Saving template with data:', {
                questId, pointIndex, stepIndex, templateText, similarityThreshold
            });

            const response = await api.post('/admin/save-template', formData);
            console.log('Template save response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error saving template:', error);
            throw adminApi.handleError(error);
        }
    },

    deleteAudio: async (questId, pointIndex, stepIndex) => {
        try {
            const response = await api.delete(`/admin/audio/${questId}`, {
                params: {
                    point_index: pointIndex,
                    step_index: stepIndex
                }
            });
            return response.data;
        } catch (error) {
            throw adminApi.handleError(error);
        }
    },

    deleteTemplate: async (questId, pointIndex, stepIndex) => {
        try {
            const response = await api.delete(`/admin/templates/${questId}`, {
                params: {
                    point_index: pointIndex,
                    step_index: stepIndex
                }
            });
            return response.data;
        } catch (error) {
            throw adminApi.handleError(error);
        }
    },

    deleteAIChatImage: async (questId, pointIndex, stepIndex) => {
        try {
            const response = await api.delete(`/admin/aichat-image/${questId}`, {
                params: {
                    point_index: pointIndex,
                    step_index: stepIndex
                }
            });
            return response.data;
        } catch (error) {
            throw adminApi.handleError(error);
        }
    },

    getQuestAudio: async (questId) => {
        try {
            const response = await api.get(`/admin/audio/${questId}`);
            return response.data;
        } catch (error) {
            throw adminApi.handleError(error);
        }
    },

    getQuestTemplates: async (questId) => {
        try {
            const response = await api.get(`/admin/templates/${questId}`);
            return response.data;
        } catch (error) {
            throw adminApi.handleError(error);
        }
    },

    getAIChatImages: async (questId) => {
        try {
            const response = await api.get(`/admin/aichat-images/${questId}`);
            return response.data;
        } catch (error) {
            throw adminApi.handleError(error);
        }
    },

    // Операции со статистикой
    getStatistics: async () => {
        try {
            const response = await api.get('/admin/statistics');
            return response.data;
        } catch (error) {
            throw adminApi.handleError(error);
        }
    },

    // Валидация файлов
    validateAudioFile: (file) => {
        if (!file) {
            throw new AdminApiError(
                'validation',
                'No file provided',
                { field: 'file', type: 'missing_file' }
            );
        }
        
        // Проверка на пустой файл
        if (file.size === 0) {
            throw new AdminApiError(
                'validation',
                'Audio file is empty',
                { field: 'file', type: 'empty_file' }
            );
        }

        // Проверка типа файла
        const validTypes = ['audio/mp3', 'audio/wav', 'audio/ogg', 'audio/mpeg', 'audio/x-wav'];
        const validExtensions = ['mp3', 'wav', 'ogg'];
        const maxSize = 10 * 1024 * 1024; // 10MB
        
        // Проверка MIME типа
        let isValidType = validTypes.includes(file.type);
        
        // Если MIME тип не соответствует, проверяем расширение файла
        if (!isValidType) {
            const extension = file.name.split('.').pop().toLowerCase();
            isValidType = validExtensions.includes(extension);
            
            if (!isValidType) {
                throw new AdminApiError(
                    'validation',
                    `Invalid audio file type: ${file.type}. Supported formats: MP3, WAV, OGG`,
                    { field: 'file', type: 'invalid_type' }
                );
            }
            
            console.warn(`File MIME type is not standard (${file.type}). Using file extension validation.`);
        }

        // Проверка размера файла
        if (file.size > maxSize) {
            throw new AdminApiError(
                'validation',
                `Audio file is too large (${(file.size / 1024 / 1024).toFixed(2)}MB). Maximum size is 10MB`,
                { field: 'file', type: 'file_too_large' }
            );
        }

        return true;
    },

    // Обработка ошибок
    handleError: (error) => {
        if (error instanceof AdminApiError) {
            return error;
        }

        if (error.response) {
            const { status, data } = error.response;

            console.error('API Error Response:', {
                status,
                data,
                url: error.response.config?.url,
                method: error.response.config?.method
            });

            switch (status) {
                case 400:
                    return new AdminApiError(
                        'validation',
                        data.detail || 'Invalid request data',
                        data.errors || null
                    );
                case 401:
                    return new AdminApiError(
                        'auth',
                        'Authentication required'
                    );
                case 403:
                    return new AdminApiError(
                        'permission',
                        'Admin access required'
                    );
                case 404:
                    return new AdminApiError(
                        'not_found',
                        data.detail || 'Resource not found'
                    );
                case 413:
                    return new AdminApiError(
                        'file_too_large',
                        'File is too large'
                    );
                case 422:
                    return new AdminApiError(
                        'validation',
                        data.detail || 'Validation error',
                        data.errors || null
                    );
                default:
                    return new AdminApiError(
                        'server',
                        'Server error occurred',
                        { status, message: data?.detail || error.message }
                    );
            }
        }

        if (error.request) {
            return new AdminApiError(
                'network',
                'Network error occurred',
                { message: error.message }
            );
        }

        return new AdminApiError(
            'unknown',
            error.message || 'An unknown error occurred'
        );
    },

    // Тестовый метод для проверки подключения к серверу
    testServerConnection: async () => {
        try {
            const response = await api.get('/admin/ping-upload');
            return {
                success: true,
                data: response.data
            };
        } catch (error) {
            console.error('Server connection test failed:', error);
            return {
                success: false,
                error: adminApi.handleError(error)
            };
        }
    },

    // Форматирование данных
    formatQuest: (quest) => ({
        ...quest,
        id: quest._id,
        stats: {
            purchased: quest.stats?.purchased || 0,
            completed: quest.stats?.completed || 0,
            completionRate: quest.stats?.purchased 
                ? ((quest.stats.completed / quest.stats.purchased) * 100).toFixed(1)
                : 0
        },
        createdAt: new Date(quest.created_at),
        updatedAt: quest.updated_at ? new Date(quest.updated_at) : null,
        points: quest.points?.map(point => ({
            ...point,
            steps: point.steps?.map(step => ({
                ...step,
                audioFile: step.audioFile || null,
                templateText: step.templateText || '',
                similarityThreshold: step.similarityThreshold || 25.0,
                // Add AI Chat specific fields
                aiInstructions: step.aiInstructions || '',
                maxQuestions: step.maxQuestions || 3,
                imageFile: step.imageFile || null
                // Удалено поле gptModel
            }))
        })) || []
    }),

    formatQuestList: (response) => ({
        quests: response.quests.map(adminApi.formatQuest),
        total: response.total,
        skip: response.skip,
        limit: response.limit
    })
};

export default adminApi;
