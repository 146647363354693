import React, { useState } from 'react';
import api from '../api';
import { useNavigate, Link } from 'react-router-dom';
import { Card, CardHeader, CardContent, CardTitle, CardDescription } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Alert, AlertDescription } from './ui/alert';
import { AlertCircle, LogIn, UserPlus, Mail, Lock, User } from 'lucide-react';

const Register = () => {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            setError('');

            const response = await api.post('/account/register', {
                email,
                username,
                password,
            });

            console.log('Registration successful:', response.data);
            navigate('/login', { 
                state: { 
                    message: 'Registration successful! Please log in.' 
                } 
            });
            
        } catch (error) {
            console.error('Registration error:', error);
            if (error.response) {
                setError(error.response.data.detail || 'Error registering');
            } else {
                setError('Error connecting to server');
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center p-4 bg-background">
            <Card className="w-full max-w-md">
                <CardHeader>
                    <CardTitle className="flex items-center gap-2 text-2xl">
                        <UserPlus className="h-6 w-6" />
                        Register
                    </CardTitle>
                    <CardDescription>
                        Create your account to start your journey
                    </CardDescription>
                </CardHeader>

                <CardContent>
                    <form onSubmit={handleRegister} className="space-y-4">
                        {error && (
                            <Alert variant="destructive">
                                <AlertCircle className="h-4 w-4" />
                                <AlertDescription>{error}</AlertDescription>
                            </Alert>
                        )}

                        <div className="space-y-2">
                            <div className="relative">
                                <Mail className="absolute left-3 top-2.5 h-4 w-4 text-muted-foreground" />
                                <Input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="pl-9"
                                    required
                                    disabled={isLoading}
                                />
                            </div>

                            <div className="relative">
                                <User className="absolute left-3 top-2.5 h-4 w-4 text-muted-foreground" />
                                <Input
                                    type="text"
                                    placeholder="Username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    className="pl-9"
                                    required
                                    disabled={isLoading}
                                />
                            </div>

                            <div className="relative">
                                <Lock className="absolute left-3 top-2.5 h-4 w-4 text-muted-foreground" />
                                <Input
                                    type="password"
                                    placeholder="Password (min 6 characters)"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="pl-9"
                                    required
                                    minLength={6}
                                    disabled={isLoading}
                                />
                            </div>
                        </div>

                        <button
                            type="submit"
                            className="w-full h-10 bg-primary text-white font-medium rounded-md flex items-center justify-center uppercase tracking-wider"
                            disabled={isLoading}
                        >
                            {isLoading ? "CREATING ACCOUNT..." : "REGISTER"}
                        </button>

                        <div className="text-center text-sm text-muted-foreground">
                            Already have an account?{" "}
                            <Button variant="link" asChild className="p-0">
                                <Link to="/login" className="text-primary">
                                    <LogIn className="h-4 w-4 inline" />
                                    Login
                                </Link>
                            </Button>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
};

export default Register;
