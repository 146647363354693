/**
 * Улучшенное форматирование текста с поддержкой русского языка
 * @param {string} text - Исходный текст
 * @param {boolean} isRussian - Флаг для определения языка
 * @returns {Array} Массив параграфов с дополнительной разметкой
 */
export const formatText = (text, isRussian = false) => {
  if (!text) return [];
  
  // Разбиваем по абзацам (двойные переносы строк тоже обрабатываем)
  const paragraphs = text.split(/\n{2,}|\n+/g)
    .filter(p => p.trim().length > 0);
  
  // Обработка каждого параграфа
  return paragraphs.map(paragraph => {
    // Выделяем заголовки (начинаются с # или ##)
    if (paragraph.startsWith('# ')) {
      return {
        type: 'heading1',
        content: paragraph.substring(2).trim(),
        class: isRussian ? 'font-serif font-bold text-xl md:text-2xl text-primary mb-4 text-left' 
                         : 'font-bold text-xl md:text-2xl text-primary mb-4 text-left'
      };
    }
    
    if (paragraph.startsWith('## ')) {
      return {
        type: 'heading2',
        content: paragraph.substring(3).trim(),
        class: isRussian ? 'font-serif font-bold text-lg md:text-xl text-primary mb-3 text-left' 
                         : 'font-bold text-lg md:text-xl text-primary mb-3 text-left'
      };
    }
    
    // Выделяем цитаты (начинаются с >)
    if (paragraph.startsWith('> ')) {
      return {
        type: 'quote',
        content: paragraph.substring(2).trim(),
        class: 'italic pl-4 border-l-4 border-primary/30 py-1 my-4 text-left'
      };
    }
    
    // Обычный параграф
    return {
      type: 'paragraph',
      content: paragraph.trim(),
      class: isRussian ? 'font-russian mb-4 last:mb-0 text-left' : 'mb-4 last:mb-0 text-left'
    };
  });
};

/**
 * Определение наличия русского текста
 * @param {string} text - Исходный текст
 * @returns {boolean} true если содержит кириллицу
 */
export const hasRussianText = (text) => {
  if (!text) return false;
  
  // Регулярное выражение для определения кириллицы
  const cyrillicPattern = /[\u0400-\u04FF]/;
  
  // Если больше 10% текста на кириллице, считаем русским
  const cyrillicChars = text.split('').filter(char => cyrillicPattern.test(char)).length;
  return (cyrillicChars / text.length) > 0.1;
};

/**
 * Улучшенный преобразователь текста в HTML для использования без React
 * @param {string} text - Исходный текст
 * @param {boolean} isRussian - Флаг для определения языка 
 * @returns {string} HTML строка
 */
export const textToHtml = (text, isRussian = false) => {
  if (!text) return '';
  
  const formattedItems = formatText(text, isRussian);
  
  return formattedItems.map(item => {
    if (item.type === 'heading1') {
      return `<h1 class="${item.class}">${item.content}</h1>`;
    }
    if (item.type === 'heading2') {
      return `<h2 class="${item.class}">${item.content}</h2>`;
    }
    if (item.type === 'quote') {
      return `<blockquote class="${item.class}">${item.content}</blockquote>`;
    }
    return `<p class="${item.class}">${item.content}</p>`;
  }).join('');
};

/**
 * Преобразование простого разбиения по переносам строк
 * Более простая версия для компонентов, не поддерживающих форматирование
 * @param {string} text - Исходный текст 
 * @param {boolean} isRussian - Флаг для определения языка
 * @returns {Array} Массив параграфов
 */
export const simpleTextSplit = (text, isRussian = false) => {
  if (!text) return [];
  
  return text.split('\n')
    .map(line => line.trim())
    .filter(line => line.length > 0)
    .map(line => ({
      content: line,
      class: isRussian ? 'text-ru mb-4 last:mb-0 text-left' : 'mb-4 last:mb-0 text-left'
    }));
};

/**
 * Проверка текста на содержание Markdown разметки
 * @param {string} text - Исходный текст
 * @returns {boolean} true если содержит Markdown разметку
 */
export const hasMarkdown = (text) => {
  if (!text) return false;
  
  const markdownPatterns = [
    /^#\s+.+$/m,      // Headers
    /^>\s+.+$/m,      // Blockquotes
    /\*\*.+\*\*/,     // Bold
    /\*.+\*/,         // Italic
    /`[^`]+`/,        // Inline code
    /^\s*\d+\.\s+.+/m, // Ordered lists
    /^\s*[-*+]\s+.+/m, // Unordered lists
    /\[.+\]\(.+\)/    // Links
  ];
  
  return markdownPatterns.some(pattern => pattern.test(text));
};

/**
 * Добавление красивых отступов и межбуквенных интервалов для русского текста
 * @param {string} text - Исходный текст
 * @returns {string} Текст с CSS-стилями для русского языка
 */
export const applyRussianTextStyles = (text) => {
  if (!text || !hasRussianText(text)) return text;
  
  // Для длинных текстов добавляем дополнительные стили
  const styles = text.length > 500 ? 
    'letter-spacing: 0.02em; line-height: 1.6; text-indent: 1.5em; text-align: left;' : 
    'letter-spacing: 0.01em; line-height: 1.5; text-align: left;';
  
  return `<span style="${styles}">${text}</span>`;
};

/**
 * Применение форматирования для отображения в компонентах React
 * @param {string} text - Исходный текст
 * @param {boolean} isRussian - Флаг для определения языка (если null, будет определен автоматически)
 * @param {Function} createElement - Функция для создания React-элементов (React.createElement или h из Preact)
 * @returns {Array} Массив React-элементов
 */
export const formatTextForReact = (text, isRussian = null, createElement) => {
  if (!text || !createElement) return [];
  
  // Автоопределение языка, если параметр не указан
  const isRussianText = isRussian === null ? hasRussianText(text) : isRussian;
  
  // Получаем форматированные элементы
  const formattedItems = formatText(text, isRussianText);
  
  // Преобразуем в React-элементы
  return formattedItems.map((item, index) => {
    const props = { key: index, className: `${item.class} text-left` };
    
    switch (item.type) {
      case 'heading1':
        return createElement('h1', props, item.content);
      case 'heading2':
        return createElement('h2', props, item.content);
      case 'quote':
        return createElement('blockquote', props, item.content);
      default:
        return createElement('p', props, item.content);
    }
  });
};

export default {
  formatText,
  hasRussianText,
  textToHtml,
  simpleTextSplit,
  hasMarkdown,
  applyRussianTextStyles,
  formatTextForReact
};
