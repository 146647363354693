import React from 'react';
import { Button } from '../ui/button';
import { FileEdit, Trash2, Eye, EyeOff } from 'lucide-react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '../ui/table';
import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from '../ui/tooltip';
import { Badge } from '../ui/badge';
import { cn } from '../../lib/utils';

const QuestList = ({ 
    quests, 
    isLoading, 
    onEdit, 
    onDelete,
    onToggleStatus 
}) => {
    const getDifficultyColor = (difficulty) => {
        switch (difficulty.toLowerCase()) {
            case 'easy':
                return 'bg-green-100 text-green-800';
            case 'medium':
                return 'bg-yellow-100 text-yellow-800';
            case 'hard':
                return 'bg-red-100 text-red-800';
            default:
                return 'bg-gray-100 text-gray-800';
        }
    };

    const renderQuestStatus = (quest) => {
        const purchased = quest.stats?.purchased || 0;
        const completed = quest.stats?.completed || 0;
        const completionRate = purchased ? ((completed / purchased) * 100).toFixed(1) : 0;

        return (
            <div className="flex flex-col space-y-1">
                <div className="flex items-center justify-between text-sm">
                    <span>Purchased:</span>
                    <span className="font-medium">{purchased}</span>
                </div>
                <div className="flex items-center justify-between text-sm">
                    <span>Completed:</span>
                    <span className="font-medium">{completed}</span>
                </div>
                <div className="flex items-center justify-between text-sm">
                    <span>Success Rate:</span>
                    <span className="font-medium">{completionRate}%</span>
                </div>
            </div>
        );
    };

    if (isLoading) {
        return (
            <div className="min-h-[400px] flex items-center justify-center">
                <div className="flex flex-col items-center space-y-4">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
                    <p className="text-sm text-muted-foreground">Loading quests...</p>
                </div>
            </div>
        );
    }

    if (!quests?.length) {
        return (
            <div className="min-h-[400px] flex items-center justify-center">
                <div className="text-center space-y-2">
                    <p className="text-lg font-medium">No quests found</p>
                    <p className="text-sm text-muted-foreground">
                        Get started by creating your first quest
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className="rounded-md border">
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead>Name</TableHead>
                        <TableHead>Difficulty</TableHead>
                        <TableHead>Price</TableHead>
                        <TableHead>Team Mode</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead className="text-center">Stats</TableHead>
                        <TableHead>Created</TableHead>
                        <TableHead className="text-right">Actions</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {quests.map((quest) => (
                        <TableRow key={quest.id}>
                            <TableCell className="font-medium">{quest.name}</TableCell>
                            <TableCell>
                                <Badge 
                                    variant="secondary"
                                    className={cn("font-medium", getDifficultyColor(quest.difficulty))}
                                >
                                    {quest.difficulty}
                                </Badge>
                            </TableCell>
                            <TableCell>${quest.price}</TableCell>
                            <TableCell>
                                <Badge 
                                    variant="secondary"
                                    className={cn(
                                        "font-medium",
                                        quest.allowTeams 
                                            ? "bg-blue-100 text-blue-800" 
                                            : "bg-gray-100 text-gray-800"
                                    )}
                                >
                                    {quest.allowTeams 
                                        ? `Team (max ${quest.maxParticipants})` 
                                        : 'Solo'}
                                </Badge>
                            </TableCell>
                            <TableCell>
                                <Badge 
                                    variant="secondary"
                                    className={cn(
                                        "font-medium",
                                        quest.is_active 
                                            ? "bg-green-100 text-green-800" 
                                            : "bg-gray-100 text-gray-800"
                                    )}
                                >
                                    {quest.is_active ? 'Active' : 'Inactive'}
                                </Badge>
                            </TableCell>
                            <TableCell>
                                <Tooltip>
                                    <TooltipTrigger>
                                        {renderQuestStatus(quest)}
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <p>Detailed quest statistics</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TableCell>
                            <TableCell>
                                {new Date(quest.createdAt).toLocaleDateString()}
                            </TableCell>
                            <TableCell className="text-right">
                                <div className="flex items-center justify-end space-x-2">
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button
                                                variant="outline"
                                                size="sm"
                                                onClick={() => onEdit(quest)}
                                            >
                                                <FileEdit className="h-4 w-4" />
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent>Edit quest</TooltipContent>
                                    </Tooltip>

                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button
                                                variant="outline"
                                                size="sm"
                                                onClick={() => onToggleStatus(quest)}
                                                className={quest.is_active 
                                                    ? "hover:bg-amber-100 hover:text-amber-600" 
                                                    : "hover:bg-green-100 hover:text-green-600"}
                                            >
                                                {quest.is_active 
                                                    ? <EyeOff className="h-4 w-4" />
                                                    : <Eye className="h-4 w-4" />}
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            {quest.is_active ? 'Deactivate quest' : 'Activate quest'}
                                        </TooltipContent>
                                    </Tooltip>

                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button
                                                variant="outline"
                                                size="sm"
                                                onClick={() => onDelete(quest)}
                                                className="hover:bg-red-100 hover:text-red-600"
                                            >
                                                <Trash2 className="h-4 w-4" />
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent>Delete quest</TooltipContent>
                                    </Tooltip>
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default QuestList;
