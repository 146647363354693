import React from 'react';
import { createRoot } from 'react-dom/client';
import { cn } from "../../lib/utils";

const TOAST_LIMIT = 5;
const TOAST_REMOVE_DELAY = 1000;

let toastCount = 0;

// Toaster и toast context
const ToastContext = React.createContext({});

function ToastProvider({ children }) {
  const [toasts, setToasts] = React.useState([]);

  const addToast = React.useCallback(
    ({ title, description, variant, duration = 5000 }) => {
      setToasts((prevToasts) => {
        const id = toastCount++;
        const newToast = {
          id,
          title,
          description,
          variant,
          duration,
        };

        const updatedToasts = [newToast, ...prevToasts].slice(0, TOAST_LIMIT);

        if (duration) {
          setTimeout(() => {
            setToasts((current) => current.filter((toast) => toast.id !== id));
          }, duration);
        }

        return updatedToasts;
      });
    },
    [setToasts]
  );

  const removeToast = React.useCallback((id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  }, []);

  const value = React.useMemo(() => ({ toasts, addToast, removeToast }), [toasts, addToast, removeToast]);

  return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>;
}

function useToast() {
  const toastContext = React.useContext(ToastContext);

  if (!toastContext) {
    throw new Error("useToast must be used within a ToastProvider");
  }

  return {
    toast: toastContext.addToast,
    dismiss: toastContext.removeToast,
  };
}

// Создаем простую реализацию toaster
const toastContainerId = "toast-container";

function createToastContainer() {
  const existingContainer = document.getElementById(toastContainerId);
  if (existingContainer) return existingContainer;

  const container = document.createElement("div");
  container.id = toastContainerId;
  container.className = "fixed top-4 right-4 z-50 flex flex-col gap-2";
  document.body.appendChild(container);
  return container;
}

function Toast({ title, description, variant, onClose }) {
  return (
    <div
      className={cn(
        "rounded-md border p-4 shadow-md transition-all animate-in fade-in-0 zoom-in-95",
        {
          "bg-white border-gray-200": !variant,
          "bg-red-50 border-red-300": variant === "destructive",
          "bg-green-50 border-green-300": variant === "success",
        }
      )}
    >
      {title && <h5 className="text-sm font-semibold">{title}</h5>}
      {description && <p className="text-sm text-gray-500 mt-1">{description}</p>}
      <button
        onClick={onClose}
        className="absolute top-2 right-2 opacity-70 hover:opacity-100"
        aria-label="Close"
      >
        &times;
      </button>
    </div>
  );
}

function showToast({ title, description, variant, duration = 5000 }) {
  const container = createToastContainer();
  const toastElement = document.createElement("div");
  container.appendChild(toastElement);

  const root = createRoot(toastElement);
  
  const removeToast = () => {
    toastElement.classList.add("animate-out", "fade-out");
    setTimeout(() => {
      if (container.contains(toastElement)) {
        container.removeChild(toastElement);
      }
      root.unmount();
    }, TOAST_REMOVE_DELAY);
  };

  root.render(
    <Toast
      title={title}
      description={description}
      variant={variant}
      onClose={removeToast}
    />
  );

  if (duration) {
    setTimeout(removeToast, duration);
  }

  return {
    dismiss: removeToast,
  };
}

// Упрощенная реализация без контекста
export const toast = showToast;
