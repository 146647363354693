import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Alert, AlertDescription } from '../ui/alert';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../ui/accordion';
import { Plus, AlertCircle, ChevronDown, Trash } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';
import StepEditor from './StepEditor';

const PointEditor = ({ 
  points = [], 
  onChange, 
  onFileUpload,
  onOptionImageUpload,
  onSequenceImageUpload,
  onStepDataChange,
  validationErrors = {},
  questId = null 
}) => {
  const [expandedPoints, setExpandedPoints] = useState({});
  const [expandedSteps, setExpandedSteps] = useState({});

  // Initialize expanded state based on points
  useEffect(() => {
    const newExpandedPoints = {};
    const newExpandedSteps = {};
    
    points.forEach((point, pointIndex) => {
      // Always expand the first point by default
      newExpandedPoints[pointIndex] = pointIndex === 0;
      
      // Initialize steps expanded state
      if (point.steps) {
        point.steps.forEach((step, stepIndex) => {
          // Expand the first step of the first point by default
          newExpandedSteps[`${pointIndex}-${stepIndex}`] = pointIndex === 0 && stepIndex === 0;
        });
      }
    });
    
    setExpandedPoints(newExpandedPoints);
    setExpandedSteps(newExpandedSteps);
  }, []);

  const handleAddPoint = () => {
    const newPoint = {
      character: '',
      steps: []
    };
    
    const updatedPoints = [...points, newPoint];
    onChange(updatedPoints);
    
    // Auto-expand the newly added point
    const newPointIndex = updatedPoints.length - 1;
    setExpandedPoints(prev => ({
      ...prev,
      [newPointIndex]: true
    }));
  };

  const handleRemovePoint = (pointIndex) => {
    const updatedPoints = [...points];
    updatedPoints.splice(pointIndex, 1);
    onChange(updatedPoints);
  };

  const handlePointChange = (pointIndex, field, value) => {
    const updatedPoints = [...points];
    updatedPoints[pointIndex][field] = value;
    onChange(updatedPoints);
  };

  const handleAddStep = (pointIndex) => {
    const newStep = {
      type: 'speech',
      text: '',
      id: uuidv4(),
      audioFile: null
    };
    
    const updatedPoints = [...points];
    if (!updatedPoints[pointIndex].steps) {
      updatedPoints[pointIndex].steps = [];
    }
    updatedPoints[pointIndex].steps.push(newStep);
    onChange(updatedPoints);
    
    // Auto-expand the newly added step
    const newStepIndex = updatedPoints[pointIndex].steps.length - 1;
    setExpandedSteps(prev => ({
      ...prev,
      [`${pointIndex}-${newStepIndex}`]: true
    }));
  };

  const handleRemoveStep = (pointIndex, stepIndex) => {
    const updatedPoints = [...points];
    updatedPoints[pointIndex].steps.splice(stepIndex, 1);
    onChange(updatedPoints);
  };

  const handleStepChange = (pointIndex, stepIndex, field, value) => {
    const updatedPoints = [...points];
    updatedPoints[pointIndex].steps[stepIndex][field] = value;
    onChange(updatedPoints);
  };

  // Handle step data updates in a batch - добавляем метод для пакетного обновления данных шага
  const handleStepDataUpdate = (pointIndex, stepIndex, data) => {
    if (!onStepDataChange) {
      const updatedPoints = [...points];
      // Если родительский компонент не предоставил метод onStepDataChange,
      // делаем пакетное обновление здесь
      updatedPoints[pointIndex].steps[stepIndex] = {
        ...updatedPoints[pointIndex].steps[stepIndex],
        ...data
      };
      onChange(updatedPoints);
    } else {
      // Если родительский компонент предоставил onStepDataChange, используем его
      onStepDataChange(pointIndex, stepIndex, data);
    }
  };

  const togglePointExpanded = (pointIndex) => {
    setExpandedPoints(prev => ({
      ...prev,
      [pointIndex]: !prev[pointIndex]
    }));
  };

  const toggleStepExpanded = (pointIndex, stepIndex) => {
    const key = `${pointIndex}-${stepIndex}`;
    setExpandedSteps(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  return (
    <div className="space-y-6">
      {points.length === 0 ? (
        <Alert className="my-4">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>
            Start by adding a point to your quest. Each point represents a character or location.
          </AlertDescription>
        </Alert>
      ) : (
        <div className="space-y-6">
          {points.map((point, pointIndex) => (
            <Card key={pointIndex} className="overflow-hidden">
              <CardHeader 
                className={`bg-primary/5 cursor-pointer flex flex-row items-center justify-between p-4 ${
                  validationErrors[`point_${pointIndex}_character`] ? 'border-b border-red-500' : ''
                }`}
                onClick={() => togglePointExpanded(pointIndex)}
              >
                <div className="flex items-center space-x-2">
                  <ChevronDown 
                    className={`h-5 w-5 transition-transform ${
                      expandedPoints[pointIndex] ? 'transform rotate-180' : ''
                    }`} 
                  />
                  <CardTitle className="text-lg font-medium">
                    {point.character || `Point ${pointIndex + 1}`}
                  </CardTitle>
                </div>
                <Button
                  type="button"
                  variant="ghost"
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemovePoint(pointIndex);
                  }}
                  className="text-red-500 hover:text-red-700 hover:bg-red-100"
                >
                  <Trash className="h-4 w-4" />
                </Button>
              </CardHeader>

              {expandedPoints[pointIndex] && (
                <CardContent className="p-4 space-y-4">
                  <div className="space-y-2">
                    <Input
                      value={point.character}
                      onChange={(e) => handlePointChange(pointIndex, 'character', e.target.value)}
                      placeholder="Enter character name or location"
                      error={validationErrors[`point_${pointIndex}_character`]}
                    />
                    {validationErrors[`point_${pointIndex}_character`] && (
                      <p className="text-sm text-red-500">
                        {validationErrors[`point_${pointIndex}_character`]}
                      </p>
                    )}
                  </div>

                  <div className="space-y-4">
                    <div className="flex items-center justify-between">
                      <h3 className="text-md font-medium">Steps</h3>
                      <Button 
                        type="button"
                        size="sm"
                        onClick={() => handleAddStep(pointIndex)}
                      >
                        <Plus className="h-4 w-4" />
                        Add Step
                      </Button>
                    </div>

                    {validationErrors[`point_${pointIndex}_steps`] && (
                      <Alert variant="destructive">
                        <AlertCircle className="h-4 w-4" />
                        <AlertDescription>
                          {validationErrors[`point_${pointIndex}_steps`]}
                        </AlertDescription>
                      </Alert>
                    )}

                    {point.steps && point.steps.length > 0 ? (
                      <div className="space-y-4">
                        {point.steps.map((step, stepIndex) => (
                          <div key={step.id || stepIndex} className="relative">
                            <StepEditor
                              step={step}
                              index={stepIndex}
                              questId={questId}
                              pointIndex={pointIndex}
                              onRemove={() => handleRemoveStep(pointIndex, stepIndex)}
                              onChange={(field, value) => handleStepChange(pointIndex, stepIndex, field, value)}
                              onFileUpload={(data, type) => onFileUpload(questId, pointIndex, stepIndex, data, type)}
                              onOptionImageUpload={onOptionImageUpload}
                              onSequenceImageUpload={onSequenceImageUpload}
                              onStepDataChange={handleStepDataUpdate} // Передаем функцию для пакетного обновления
                              validationErrors={validationErrors}
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <Alert>
                        <AlertCircle className="h-4 w-4" />
                        <AlertDescription>
                          Add at least one step to this point
                        </AlertDescription>
                      </Alert>
                    )}
                  </div>
                </CardContent>
              )}
            </Card>
          ))}
        </div>
      )}

      <Button 
        type="button"
        variant="outline" 
        className="w-full"
        onClick={handleAddPoint}
      >
        <Plus className="h-4 w-4" />
        Add Point
      </Button>
    </div>
  );
};

export default PointEditor;
