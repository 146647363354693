import React from 'react';
import { Button } from '../ui/button';
import { Trash2 } from 'lucide-react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '../ui/table';
import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from '../ui/tooltip';
import { Badge } from '../ui/badge';

const UserList = ({ 
    users, 
    isLoading, 
    onDelete
}) => {
    if (isLoading) {
        return (
            <div className="min-h-[400px] flex items-center justify-center">
                <div className="flex flex-col items-center space-y-4">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
                    <p className="text-sm text-muted-foreground">Loading users...</p>
                </div>
            </div>
        );
    }

    if (!users?.length) {
        return (
            <div className="min-h-[400px] flex items-center justify-center">
                <div className="text-center space-y-2">
                    <p className="text-lg font-medium">No users found</p>
                    <p className="text-sm text-muted-foreground">
                        No users match the search criteria
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className="rounded-md border">
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead>Username</TableHead>
                        <TableHead>Email</TableHead>
                        <TableHead>Registration Date</TableHead>
                        <TableHead>Purchased Quests</TableHead>
                        <TableHead>Started Quests</TableHead>
                        <TableHead>Completed Quests</TableHead>
                        <TableHead>Role</TableHead>
                        <TableHead className="text-right">Actions</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {users.map((user) => (
                        <TableRow key={user.id}>
                            <TableCell className="font-medium">{user.username}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>
                                {new Date(user.created_at).toLocaleDateString()}
                            </TableCell>
                            <TableCell>{user.stats.purchased_quests}</TableCell>
                            <TableCell>{user.stats.started_quests}</TableCell>
                            <TableCell>{user.stats.completed_quests}</TableCell>
                            <TableCell>
                                <Badge 
                                    variant="secondary"
                                    className={user.is_admin ? "bg-purple-100 text-purple-800" : "bg-blue-100 text-blue-800"}
                                >
                                    {user.is_admin ? 'Admin' : 'User'}
                                </Badge>
                            </TableCell>
                            <TableCell className="text-right">
                                {!user.is_admin && (
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button
                                                variant="outline"
                                                size="sm"
                                                onClick={() => onDelete(user)}
                                                className="hover:bg-red-100 hover:text-red-600"
                                            >
                                                <Trash2 className="h-4 w-4" />
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent>Delete user</TooltipContent>
                                    </Tooltip>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default UserList;
