import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from './ui/button';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Alert, AlertDescription } from './ui/alert';
import {
    X,
    Camera,
    Lightbulb,
    Loader2,
    Volume2,
    RefreshCcw,
    CheckCircle,
} from 'lucide-react';
import useAudioStore from '../hooks/useAudioStore';
import { formatText, hasRussianText } from '../utils/textFormatting';

const ImageStage = ({
    character,
    text,
    audioFile,
    questId,
    onFileChange,
    onSubmit,
    showHint,
    onHint,
    resultMessage,
    isProcessing,
    hint,
    isTeam = false,
    teamId = null,
    role = '',
    isExiting = false,
    preloadedData = null
}) => {
    const [hintText, setHintText] = useState('');
    const [previewUrl, setPreviewUrl] = useState('');
    const [isDragging, setIsDragging] = useState(false);
    const [uploadError, setUploadError] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isMobile, setIsMobile] = useState(false);
    
    // Определяем, содержит ли текст русские символы
    const isRussianText = hasRussianText(text);
    const formattedText = formatText(text, isRussianText);
    const isRussianCharacter = hasRussianText(character);

    const dropzoneRef = useRef(null);
    const mountedRef = useRef(true);

    const {
        isPlaying,
        progress: audioProgress,
        isLoading: audioLoading,
        error: audioError,
        initSound,
        togglePlayPause,
        setVolume,
        cleanup,
        preloadSound
    } = useAudioStore();

    // Функция для определения мобильного устройства
    const detectMobileDevice = useCallback(() => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }, []);

    // Установка флага мобильного устройства при монтировании
    useEffect(() => {
        setIsMobile(detectMobileDevice());
    }, [detectMobileDevice]);

    // Предзагрузка следующего аудио
    useEffect(() => {
        if (preloadedData && preloadedData.audioFile && questId) {
            const nextAudioUrl = `/audio/quests/${questId}/${preloadedData.audioFile}`;
            try {
                preloadSound(nextAudioUrl);
            } catch (error) {
                console.debug('Error preloading next audio:', error);
            }
        }
    }, [preloadedData, questId, preloadSound]);

    // Инициализация компонента и аудио
    useEffect(() => {
        mountedRef.current = true;

        if (audioFile) {
            const audioUrl = `/audio/quests/${questId}/${audioFile}`;
            try {
                initSound(audioUrl, {});
            } catch (error) {
                console.error('Error initializing audio:', error);
                if (mountedRef.current) {
                    setUploadError('Failed to load audio file');
                }
            }
        }

        return () => {
            mountedRef.current = false;
            cleanup();

            // Освобождение URL для превью
            if (previewUrl) {
                URL.revokeObjectURL(previewUrl);
            }
        };
    }, [audioFile, questId, initSound, cleanup, previewUrl]);

    // Обработка выбора файла
    const handleFileSelect = useCallback(
        (file) => {
            if (file) {
                if (!file.type.startsWith('image/')) {
                    setUploadError(isRussianText 
                      ? 'Пожалуйста, выберите файл изображения' 
                      : 'Please select an image file');
                    return;
                }
                if (file.size > 5 * 1024 * 1024) {
                    setUploadError(isRussianText 
                      ? 'Размер файла не должен превышать 5МБ' 
                      : 'File size should not exceed 5MB');
                    return;
                }
                const reader = new FileReader();
                reader.onprogress = (e) => {
                    if (e.lengthComputable) {
                        const prog = Math.round((e.loaded / e.total) * 100);
                        setUploadProgress(prog);
                    }
                };
                reader.onload = () => {
                    if (!mountedRef.current) return;
                    setUploadProgress(100);
                    if (previewUrl) {
                        URL.revokeObjectURL(previewUrl);
                    }
                    const newPreviewUrl = URL.createObjectURL(file);
                    setPreviewUrl(newPreviewUrl);
                    setImageFile(file);
                    setUploadError('');
                    onFileChange({ target: { files: [file] } });
                };
                reader.onerror = () => {
                    if (!mountedRef.current) return;
                    setUploadError(isRussianText 
                      ? 'Ошибка чтения файла' 
                      : 'Error reading file');
                };
                reader.readAsDataURL(file);
            }
        },
        [onFileChange, previewUrl, isRussianText]
    );

    // Обработчики перетаскивания файлов
    const handleDrop = useCallback(
        (e) => {
            e.preventDefault();
            if (isMobile) return; // Отключаем drag-and-drop на мобильных
            setIsDragging(false);
            const file = e.dataTransfer.files[0];
            handleFileSelect(file);
        },
        [handleFileSelect, isMobile]
    );

    const handleDragOver = useCallback(
        (e) => {
            e.preventDefault();
            if (!isMobile) { // Отключаем drag-and-drop на мобильных
                setIsDragging(true);
            }
        },
        [isMobile]
    );

    const handleDragLeave = useCallback((e) => {
        e.preventDefault();
        setIsDragging(false);
    }, []);

    // Обработчик нажатия кнопки подсказки
    const handleHintClick = useCallback(() => {
        setHintText(hint);
        if (onHint) {
            onHint();
        }
    }, [hint, onHint]);

    // Обработчик отправки изображения
    const handleSubmitImage = useCallback(async (e) => {
        e.preventDefault();
        if (!imageFile) return;
        
        try {
            await onSubmit(e);
        } catch (error) {
            console.error('Error submitting image:', error);
            setUploadError(isRussianText 
              ? 'Не удалось загрузить изображение' 
              : 'Failed to upload image');
        }
    }, [imageFile, onSubmit, isRussianText]);

    // Удаление выбранного изображения
    const removeImage = useCallback(() => {
        if (previewUrl) {
            URL.revokeObjectURL(previewUrl);
        }
        setPreviewUrl('');
        setImageFile(null);
        setUploadProgress(0);
        onFileChange({ target: { files: [] } });
    }, [previewUrl, onFileChange]);

    return (
        <Card>
            <CardHeader>
                <CardTitle className={`text-xl md:text-2xl text-primary ${isRussianCharacter ? 'font-serif' : 'font-sans'}`}>
                    {character}
                    {isTeam && (
                        <span className="text-sm ml-2 text-muted-foreground">
                            ({role || 'No Role'})
                        </span>
                    )}
                </CardTitle>
            </CardHeader>
            <CardContent className="space-y-6">
                {audioFile && (
                    <div className="space-y-4">
                        <Button
                            onClick={togglePlayPause}
                            disabled={audioLoading}
                            className="w-full flex items-center justify-center space-x-2"
                            variant="outline"
                        >
                            {audioLoading ? (
                                <Loader2 className="h-5 w-5 animate-spin" />
                            ) : isPlaying ? (
                                <>
                                    <RefreshCcw className="h-5 w-5" />
                                    <span>{isRussianText ? 'Остановить' : 'Pause Audio'}</span>
                                </>
                            ) : (
                                <>
                                    <Volume2 className="h-5 w-5" />
                                    <span>{isRussianText ? 'Слушать' : 'Play Audio'}</span>
                                </>
                            )}
                        </Button>
                        {!audioError && (
                            <div className="space-y-2">
                                <div className="w-full bg-gray-200 rounded-full h-2">
                                    <div
                                        className="bg-primary rounded-full h-full"
                                        style={{ width: `${audioProgress}%` }}
                                    />
                                </div>
                                <div className="flex items-center justify-between text-sm text-muted-foreground">
                                    <span>Progress: {Math.round(audioProgress)}%</span>
                                    <div className="flex items-center gap-2">
                                        <Volume2 className="h-4 w-4" />
                                        <input
                                            type="range"
                                            min="0"
                                            max="100"
                                            defaultValue="100"
                                            className="w-24"
                                            onChange={(e) => setVolume(e.target.value / 100)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {audioError && (
                            <Alert variant="destructive">
                                <AlertDescription>{audioError}</AlertDescription>
                            </Alert>
                        )}
                    </div>
                )}

                {/* Улучшенное отображение текста с поддержкой русского языка и выравниванием по левому краю */}
                <div className={`prose max-w-none text-content ${isRussianText ? 'text-ru' : ''}`}>
                    {formattedText.map((item, index) => {
                        if (item.type === 'heading1') {
                            return <h1 key={index} className={`${item.class} text-left`}>{item.content}</h1>;
                        }
                        if (item.type === 'heading2') {
                            return <h2 key={index} className={`${item.class} text-left`}>{item.content}</h2>;
                        }
                        if (item.type === 'quote') {
                            return <blockquote key={index} className={`${item.class} text-left`}>{item.content}</blockquote>;
                        }
                        return <p key={index} className={`${item.class} text-left`}>{item.content}</p>;
                    })}
                </div>

                <div
                    className={`relative border-2 border-dashed rounded-lg p-6 ${
                        isDragging
                            ? 'border-primary bg-primary/10'
                            : 'border-gray-300 hover:border-primary'
                    }`}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    ref={dropzoneRef}
                >
                    {!previewUrl ? (
                        <div className="flex flex-col items-center justify-center space-y-4">
                            <div className="rounded-full bg-primary/10 p-4">
                                <Camera className="w-8 h-8 text-primary" />
                            </div>
                            <div className="text-center">
                                <p className={`text-sm font-medium ${isRussianText ? 'text-ru no-indent' : ''}`}>
                                    {isMobile 
                                        ? (isRussianText ? "Нажмите, чтобы сделать фото с камеры" : "Tap to take a photo") 
                                        : (isRussianText 
                                            ? "Перетащите изображение сюда или" 
                                            : "Drag and drop your image here, or ")}
                                    <label className="ml-1 text-primary cursor-pointer hover:underline">
                                        {isMobile 
                                            ? (isRussianText ? "сделать фото" : "take photo") 
                                            : (isRussianText ? "выберите файл" : "browse")}
                                        <input
                                            type="file"
                                            className="hidden"
                                            accept="image/*"
                                            capture={isMobile ? "camera" : undefined}
                                            onChange={(e) => handleFileSelect(e.target.files[0])}
                                            disabled={isProcessing}
                                        />
                                    </label>
                                </p>
                                <p className={`text-xs text-muted-foreground mt-2 ${isRussianText ? 'text-ru no-indent' : ''}`}>
                                    {isMobile 
                                        ? (isRussianText ? "Поддерживаемые форматы: JPG, PNG (до 5МБ)" : "Supports: JPG, PNG (up to 5MB)") 
                                        : (isRussianText ? "Поддерживаемые форматы: JPG, PNG, GIF (до 5МБ)" : "Supports: JPG, PNG, GIF (up to 5MB)")}
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className="space-y-4">
                            <div className="relative rounded-lg overflow-hidden">
                                <img
                                    src={previewUrl}
                                    alt="Preview"
                                    className="w-full h-auto max-h-[400px] object-contain"
                                />
                                <Button
                                    variant="destructive"
                                    size="icon"
                                    className="absolute top-2 right-2"
                                    onClick={removeImage}
                                >
                                    <X className="w-4 h-4" />
                                </Button>
                            </div>
                            {uploadProgress > 0 && uploadProgress < 100 && (
                                <div className="w-full bg-gray-200 rounded-full h-2">
                                    <div
                                        className="bg-primary rounded-full h-full"
                                        style={{ width: `${uploadProgress}%` }}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {uploadError && (
                    <Alert variant="destructive">
                        <AlertDescription className={hasRussianText(uploadError) ? 'text-ru no-indent text-left' : 'text-left'}>
                            {uploadError}
                        </AlertDescription>
                    </Alert>
                )}
                <div className="flex flex-col sm:flex-row gap-4">
                    <Button
                        onClick={handleSubmitImage}
                        disabled={!imageFile || isProcessing}
                    >
                        {isProcessing ? (
                            <>
                                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                                Processing...
                            </>
                        ) : (
                            <>
                                <Camera className="w-4 h-4 mr-2" />
                                Submit Image
                            </>
                        )}
                    </Button>
                    {showHint && (
                        <Button
                            variant="outline"
                            onClick={handleHintClick}
                            disabled={isProcessing || hintText !== ''}
                        >
                            <Lightbulb className="w-4 h-4 mr-2" />
                            Get Hint (-10 points)
                        </Button>
                    )}
                </div>
                
                {hintText && (
                    <Alert>
                        <AlertDescription className={hasRussianText(hintText) ? 'text-ru text-left' : 'text-left'}>
                            {hintText}
                        </AlertDescription>
                    </Alert>
                )}
                
                {resultMessage && (
                    <Alert variant={resultMessage.includes('matches') ? 'default' : 'destructive'}>
                        <AlertDescription className={hasRussianText(resultMessage) ? 'text-ru text-left' : 'text-left'}>
                            {resultMessage}
                        </AlertDescription>
                    </Alert>
                )}
            </CardContent>
        </Card>
    );
};

ImageStage.propTypes = {
    character: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    audioFile: PropTypes.string,
    questId: PropTypes.string.isRequired,
    onFileChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    showHint: PropTypes.bool,
    onHint: PropTypes.func,
    resultMessage: PropTypes.string,
    isProcessing: PropTypes.bool,
    hint: PropTypes.string,
    isTeam: PropTypes.bool,
    teamId: PropTypes.string,
    role: PropTypes.string,
    isExiting: PropTypes.bool,
    preloadedData: PropTypes.object,
};

export default ImageStage;
