import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';

export const useQuest = (questId) => {
    const [quest, setQuest] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchQuest = async () => {
            try {
                setLoading(true);
                setError(null);

                // Проверяем режим квеста
                const questMode = localStorage.getItem(`questMode_${questId}`);
                const questModeData = questMode ? JSON.parse(questMode) : null;
                const isTeamMode = questModeData?.isTeam;
                const teamId = questModeData?.teamId;
                const role = questModeData?.role;

                // Отладочный вывод для проверки параметров
                console.log("useQuest: Fetching quest data with params:", {
                    questId,
                    isTeamMode,
                    teamId,
                    role,
                    questModeData
                });

                // Проверка корректности данных о команде
                if (isTeamMode && !teamId) {
                    console.error("КРИТИЧЕСКАЯ ОШИБКА: isTeamMode=true, но teamId отсутствует в useQuest!");
                    
                    // Попытка восстановить teamId из других источников
                    const stateKey = `questState_${questId}`;
                    const savedState = localStorage.getItem(stateKey);
                    if (savedState) {
                        const parsedState = JSON.parse(savedState);
                        if (parsedState.teamId) {
                            console.log("Восстановлен teamId из questState:", parsedState.teamId);
                            // Обновляем questMode с корректным teamId
                            localStorage.setItem(`questMode_${questId}`, JSON.stringify({
                                isTeam: true,
                                role: role || parsedState.role || "",
                                teamId: parsedState.teamId
                            }));
                            // Перезагружаем страницу для применения восстановленных данных
                            window.location.reload();
                            return;
                        }
                    }
                    
                    setError("Team ID is missing. Please restart the quest.");
                    setLoading(false);
                    return;
                }

                // Проверяем сохраненный квест
                const savedQuest = localStorage.getItem('currentQuest');
                const savedQuestData = savedQuest ? JSON.parse(savedQuest) : null;

                // Загружаем квест
                let questData;
                if (savedQuestData && savedQuestData._id === questId) {
                    console.log("Using cached quest data");
                    questData = savedQuestData;
                } else {
                    console.log(`Fetching quest data for quest ${questId}`);
                    const response = await api.get(`/quests/${questId}`);
                    questData = response.data;
                    localStorage.setItem('currentQuest', JSON.stringify(questData));
                }
                setQuest(questData);

                // Загружаем состояние команды, если это командный режим
                if (isTeamMode && teamId) {
                    console.log(`Loading team state for team ${teamId}`);
                    try {
                        const teamResponse = await api.get(`/team/status/${teamId}`);
                        const teamState = teamResponse.data;
                        
                        // Добавляем teamId и isTeam в состояние для гарантии сохранения
                        const completeState = {
                            ...teamState,
                            teamId: teamId,
                            isTeam: true,
                            role: role || teamState.role
                        };
                        
                        console.log("Saving team state to localStorage:", completeState);
                        localStorage.setItem(`questState_${questId}`, JSON.stringify(completeState));

                        // Проверка сохранения
                        const savedState = localStorage.getItem(`questState_${questId}`);
                        if (savedState) {
                            const parsedState = JSON.parse(savedState);
                            if (!parsedState.teamId) {
                                console.error("ОШИБКА: teamId не сохранен в questState!", parsedState);
                                // Повторно сохраняем с явным teamId
                                localStorage.setItem(`questState_${questId}`, JSON.stringify({
                                    ...parsedState,
                                    teamId: teamId,
                                    isTeam: true
                                }));
                            }
                        }
                        
                        // Дополнительно обновляем questMode для надежности
                        localStorage.setItem(`questMode_${questId}`, JSON.stringify({
                            isTeam: true,
                            role: role || teamState.role || "",
                            teamId: teamId
                        }));
                    } catch (teamError) {
                        console.error(`Error loading team state: ${teamError}`);
                        // Даже при ошибке загрузки состояния команды, сохраняем базовую информацию
                        localStorage.setItem(`questState_${questId}`, JSON.stringify({
                            teamId: teamId,
                            isTeam: true,
                            role: role || "",
                            currentStep: 1,
                            currentSubStep: 0,
                            points: 100
                        }));
                    }
                }
            } catch (error) {
                console.error('Error fetching quest:', error);
                setError(error.response?.data?.message || 'Failed to load quest data');

                if (error.response?.status === 404) {
                    navigate('/');
                }
            } finally {
                setLoading(false);
            }
        };

        if (questId) {
            fetchQuest();
        }
    }, [questId, navigate]);

    const updateQuest = useCallback(async (updates) => {
        try {
            setLoading(true);
            
            // Получаем информацию о режиме квеста
            const questMode = localStorage.getItem(`questMode_${questId}`);
            const questModeData = questMode ? JSON.parse(questMode) : null;
            const isTeamMode = questModeData?.isTeam;
            const teamId = questModeData?.teamId;

            console.log("Updating quest with params:", {
                questId,
                isTeamMode,
                teamId,
                updates
            });

            // Проверка наличия teamId для командного режима
            if (isTeamMode && !teamId) {
                console.error("ОШИБКА: isTeamMode=true, но teamId отсутствует при обновлении квеста!");
                
                // Попытка восстановить teamId
                const stateKey = `questState_${questId}`;
                const savedState = localStorage.getItem(stateKey);
                if (savedState) {
                    const parsedState = JSON.parse(savedState);
                    if (parsedState.teamId) {
                        console.log("Восстановлен teamId из questState:", parsedState.teamId);
                        // Обновляем questMode
                        localStorage.setItem(`questMode_${questId}`, JSON.stringify({
                            isTeam: true,
                            role: questModeData?.role || parsedState.role || "",
                            teamId: parsedState.teamId
                        }));
                        // Используем восстановленный teamId
                        const updatedQuest = { ...quest, ...updates };
                        setQuest(updatedQuest);
                        localStorage.setItem('currentQuest', JSON.stringify(updatedQuest));
                        
                        // Обновляем состояние команды
                        await api.post(`/team/status/${parsedState.teamId}`, {
                            ...updates,
                            teamId: parsedState.teamId,
                            team_id: parsedState.teamId
                        });
                        
                        return;
                    }
                }
                
                throw new Error("Team ID is missing for team mode");
            }

            const updatedQuest = { ...quest, ...updates };
            setQuest(updatedQuest);
            localStorage.setItem('currentQuest', JSON.stringify(updatedQuest));

            // Гарантируем сохранение teamId при обновлении
            if (isTeamMode && teamId) {
                const updatesWithTeamId = {
                    ...updates,
                    teamId: teamId,
                    team_id: teamId, // Дублируем в snake_case
                    isTeam: true
                };
                
                console.log(`Updating team state for team ${teamId}:`, updatesWithTeamId);
                await api.post(`/team/status/${teamId}`, updatesWithTeamId);
            } else {
                console.log(`Updating quest state for quest ${questId}:`, updates);
                await api.post(`/quest-state/${questId}/update`, updates);
            }
        } catch (error) {
            console.error('Error updating quest:', error);
            setError(error.response?.data?.message || 'Failed to update quest');
        } finally {
            setLoading(false);
        }
    }, [quest, questId]);

    const clearQuest = useCallback(() => {
        console.log(`Clearing quest data for quest ${questId}`);
        localStorage.removeItem('currentQuest');
        localStorage.removeItem(`questState_${questId}`);
        localStorage.removeItem(`questMode_${questId}`);
        setQuest(null);
    }, [questId]);

    const resetQuestState = useCallback(async () => {
        try {
            setLoading(true);
            
            // Получаем информацию о режиме квеста
            const questMode = localStorage.getItem(`questMode_${questId}`);
            const questModeData = questMode ? JSON.parse(questMode) : null;
            const isTeamMode = questModeData?.isTeam;
            const teamId = questModeData?.teamId;

            console.log("Resetting quest state with params:", {
                questId,
                isTeamMode,
                teamId
            });

            // Проверка наличия teamId для командного режима
            if (isTeamMode && !teamId) {
                console.error("ОШИБКА: isTeamMode=true, но teamId отсутствует при сбросе состояния!");
                throw new Error("Team ID is missing for team mode reset");
            }

            // Очищаем состояние
            localStorage.removeItem(`questState_${questId}`);

            if (isTeamMode && teamId) {
                console.log(`Resetting team state for team ${teamId}`);
                await api.post(`/team/${teamId}/reset`);
            } else {
                console.log(`Resetting quest state for quest ${questId}`);
                await api.post(`/quest-state/${questId}/reset`);
            }

            // Перезагружаем квест
            const response = await api.get(`/quests/${questId}`);
            setQuest(response.data);
            localStorage.setItem('currentQuest', JSON.stringify(response.data));
            
            // Сохраняем режим квеста, если в командном режиме
            if (isTeamMode && teamId) {
                const initialState = {
                    currentStep: 1,
                    currentSubStep: 0,
                    points: 100,
                    isTeam: true,
                    teamId: teamId,
                    role: questModeData.role || ""
                };
                
                console.log("Saving initial state after reset:", initialState);
                localStorage.setItem(`questState_${questId}`, JSON.stringify(initialState));
                
                // Дополнительно обновляем questMode для надежности
                localStorage.setItem(`questMode_${questId}`, JSON.stringify({
                    isTeam: true,
                    role: questModeData.role || "",
                    teamId: teamId
                }));
            }
        } catch (error) {
            console.error('Error resetting quest state:', error);
            setError(error.response?.data?.message || 'Failed to reset quest state');
        } finally {
            setLoading(false);
        }
    }, [questId]);

    return {
        quest,
        loading,
        error,
        updateQuest,
        clearQuest,
        resetQuestState
    };
};

export default useQuest;
