import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Card, CardHeader, CardContent, CardTitle, CardDescription, CardFooter } from './ui/card';
import { Button } from './ui/button';
import { Trophy, Home, Users, Star, Clock, Share2, Loader2, AlertCircle, Facebook, Twitter, MessageCircle, Copy, ThumbsUp, ThumbsDown } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from './ui/alert';
import { Textarea } from './ui/textarea';
import { RadioGroup, RadioGroupItem } from './ui/radio-group';
import { Label } from './ui/label';
import { toast } from './ui/use-toast';
import api from '../api';

const FinishScreen = ({ points = null }) => {
  const { id: questId } = useParams();
  const [quest, setQuest] = useState(null);
  const [isTeam, setIsTeam] = useState(false);
  const [teamData, setTeamData] = useState(null);
  const [finalPoints, setFinalPoints] = useState(points);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [completionData, setCompletionData] = useState(null);
  
  // Feedback states
  const [rating, setRating] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [showFeedbackForm, setShowFeedbackForm] = useState(true);

  // Function to clear all timers and intervals
  const clearAllTimers = () => {
    console.log("Очистка всех таймеров и интервалов на финальном экране");
    // Clear a large range of IDs to ensure all intervals are cleared
    for (let i = 0; i < 1000; i++) {
      clearInterval(i);
      clearTimeout(i);
    }
  };

  useEffect(() => {
    // When mounting the FinishScreen component, ensure all synchronizations are disabled
    if (questId) {
      localStorage.setItem(`quest_${questId}_completed`, 'true');
      
      // Clear all possible synchronization timers
      clearAllTimers();
      
      // Check if feedback was already submitted for this quest
      const feedbackStatus = localStorage.getItem(`feedback_submitted_${questId}`);
      if (feedbackStatus === 'true') {
        setFeedbackSubmitted(true);
        setShowFeedbackForm(false);
      }
    }
    
    // Cleanup on unmounting
    return () => {
      // Do not remove completion flag on unmounting
    };
  }, [questId]);

  useEffect(() => {
    const fetchQuestData = async () => {
      try {
        setIsLoading(true);
        
        // Load quest data
        if (questId) {
          try {
            const response = await api.get(`/quests/${questId}`);
            setQuest(response.data);
          } catch (err) {
            console.error("Error fetching quest data:", err);
          }
        }
        
        // Check if this was a team quest
        const questMode = localStorage.getItem(`questMode_${questId}`);
        if (questMode) {
          const { isTeam: wasTeam, teamId } = JSON.parse(questMode);
          setIsTeam(wasTeam);
          
          if (wasTeam && teamId) {
            try {
              const teamResponse = await api.get(`/team/status/${teamId}`);
              setTeamData(teamResponse.data);
              
              // Get points from team data
              if (teamResponse.data.points && !finalPoints) {
                setFinalPoints(teamResponse.data.points);
              }
              
              // Get completion data from team
              if (teamResponse.data.completed_at) {
                setCompletionData({
                  completedAt: teamResponse.data.completed_at,
                  startedAt: teamResponse.data.started_at
                });
              }
            } catch (err) {
              console.error("Error fetching team data:", err);
            }
          }
        }
        
        // Load quest state to get points if not provided
        const storedState = localStorage.getItem(`questState_${questId}`);
        if (storedState) {
          const parsedState = JSON.parse(storedState);
          if (parsedState.points && !finalPoints) {
            setFinalPoints(parsedState.points);
          }
          
          // Get completion data from individual state
          if (parsedState.completed_at) {
            setCompletionData({
              completedAt: parsedState.completed_at,
              startedAt: parsedState.started_at
            });
          }
        }
      } catch (err) {
        console.error("Error in fetchQuestData:", err);
        setError("Failed to load quest completion information");
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchQuestData();
  }, [questId, finalPoints, points]);

  // Define text class based on points
  const getPointsClass = () => {
    if (!finalPoints) return "text-primary";
    if (finalPoints >= 90) return "text-green-500";
    if (finalPoints >= 70) return "text-blue-500";
    if (finalPoints >= 50) return "text-yellow-500";
    return "text-red-500";
  };
  
  // Pretty display of completion time
  const formatCompletionTime = (startTime, endTime) => {
    if (!startTime || !endTime) return "Unknown";
    
    try {
      const start = new Date(startTime);
      const end = new Date(endTime);
      const diff = Math.floor((end - start) / 1000); // seconds
      
      const hours = Math.floor(diff / 3600);
      const minutes = Math.floor((diff % 3600) / 60);
      const seconds = diff % 60;
      
      return `${hours > 0 ? hours + 'h ' : ''}${minutes}m ${seconds}s`;
    } catch (err) {
      return "Unknown";
    }
  };

  const getStarsForPoints = () => {
    if (!finalPoints) return "★★★☆☆";
    if (finalPoints >= 90) return "★★★★★";
    if (finalPoints >= 70) return "★★★★☆";
    if (finalPoints >= 50) return "★★★☆☆";
    if (finalPoints >= 30) return "★★☆☆☆";
    return "★☆☆☆☆";
  };

  // Handle feedback submission
  const handleFeedbackSubmit = async () => {
    if (!rating) {
      toast({
        title: "Rating required",
        description: "Please select a rating before submitting",
        variant: "destructive"
      });
      return;
    }
    
    try {
      setIsSubmitting(true);
      
      // Create feedback payload
      const feedbackData = {
        questId,
        rating,
        comment: feedback,
        completedAt: completionData?.completedAt || new Date().toISOString(),
        isTeam,
        teamId: isTeam && teamData ? teamData.id : null,
        points: finalPoints
      };
      
      // Send feedback to API
      await api.post('/feedback', feedbackData);
      
      // Mark feedback as submitted
      localStorage.setItem(`feedback_submitted_${questId}`, 'true');
      
      setFeedbackSubmitted(true);
      setShowFeedbackForm(false);
      
      toast({
        title: "Thank you!",
        description: "Your feedback has been submitted successfully",
        variant: "success" 
      });
    } catch (err) {
      console.error("Error submitting feedback:", err);
      toast({
        title: "Error",
        description: "Failed to submit feedback. Please try again.",
        variant: "destructive"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Platform-specific share handlers
  const handleFacebookShare = () => {
    const shareUrl = encodeURIComponent(window.location.href);
    const shareText = encodeURIComponent(`I just completed "${quest?.name || 'a quest'}" with ${finalPoints || 'some'} points! ${isTeam ? 'It was a team challenge.' : ''}`);
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&quote=${shareText}`, '_blank');
  };
  
  const handleTwitterShare = () => {
    const shareText = encodeURIComponent(`I just completed "${quest?.name || 'a quest'}" with ${finalPoints || 'some'} points! ${isTeam ? 'It was a team challenge.' : ''}`);
    const shareUrl = encodeURIComponent(window.location.href);
    window.open(`https://twitter.com/intent/tweet?text=${shareText}&url=${shareUrl}`, '_blank');
  };
  
  const handleWhatsAppShare = () => {
    const shareText = encodeURIComponent(`I just completed "${quest?.name || 'a quest'}" with ${finalPoints || 'some'} points! ${isTeam ? 'It was a team challenge.' : ''} ${window.location.href}`);
    window.open(`https://wa.me/?text=${shareText}`, '_blank');
  };
  
  const handleTelegramShare = () => {
    const shareText = encodeURIComponent(`I just completed "${quest?.name || 'a quest'}" with ${finalPoints || 'some'} points! ${isTeam ? 'It was a team challenge.' : ''}`);
    const shareUrl = encodeURIComponent(window.location.href);
    window.open(`https://t.me/share/url?url=${shareUrl}&text=${shareText}`, '_blank');
  };
  
  const handleCopyLink = () => {
    const shareText = `I just completed "${quest?.name || 'a quest'}" with ${finalPoints || 'some'} points! ${isTeam ? 'It was a team challenge.' : ''} Check it out: ${window.location.href}`;
    navigator.clipboard.writeText(shareText)
      .then(() => {
        toast({
          title: "Copied!",
          description: "Share text copied to clipboard",
          duration: 3000
        });
      })
      .catch((err) => {
        console.error('Could not copy text: ', err);
        toast({
          title: "Error",
          description: "Failed to copy to clipboard",
          variant: "destructive"
        });
      });
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <Loader2 className="h-12 w-12 animate-spin text-primary mx-auto mb-4" />
          <p className="text-muted-foreground">Loading results...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <Card className="w-full max-w-lg mx-auto">
        <CardContent className="p-6">
          <Alert variant="destructive" className="mb-4">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
          <Button asChild className="w-full">
            <Link to="/">Return Home</Link>
          </Button>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-full max-w-lg mx-auto animate-fade-in">
      <CardHeader className="text-center">
        <div className="mx-auto text-4xl mb-4">
          <Trophy className="h-12 w-12 text-yellow-500 mx-auto" />
        </div>
        <CardTitle className="text-3xl font-bold">
          Quest Completed!
        </CardTitle>
        <CardDescription className="text-lg">
          {quest?.name || "Your adventure is finished"}
        </CardDescription>
      </CardHeader>

      <CardContent className="space-y-6">
        <div className="text-center p-6 bg-muted rounded-lg">
          <h2 className="text-xl mb-2 font-medium">Your Final Score</h2>
          <div className={`text-5xl font-bold ${getPointsClass()}`}>
            {finalPoints || 0} points
          </div>
          <div className="mt-3 text-xl text-yellow-500">
            {getStarsForPoints()}
          </div>
        </div>
        
        {isTeam && teamData && (
          <div className="space-y-4 p-4 border rounded-lg">
            <h3 className="font-medium flex items-center gap-2">
              <Users className="h-5 w-5" />
              Team Challenge
            </h3>
            <div className="space-y-2">
              <p className="text-sm text-muted-foreground">Team: {teamData.name}</p>
              
              {teamData.members && (
                <div className="grid grid-cols-2 gap-2">
                  {teamData.members.map((member, index) => (
                    <div key={index} className="flex items-center gap-2 text-sm p-2 bg-background rounded-lg">
                      <span>{member.username}</span>
                      <span className="text-xs text-muted-foreground ml-auto">
                        {member.role || "No role"}
                      </span>
                    </div>
                  ))}
                </div>
              )}
              
              {completionData && (
                <div className="flex items-center gap-2 mt-2 text-sm text-muted-foreground">
                  <Clock className="h-4 w-4" />
                  <span>
                    Completion time: {formatCompletionTime(completionData.startedAt, completionData.completedAt)}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
        
        {/* Feedback Section */}
        {showFeedbackForm && (
          <div className="p-4 border rounded-lg space-y-4">
            <h3 className="font-medium text-lg">How was your experience?</h3>
            
            <div className="space-y-3">
              <div>
                <Label htmlFor="rating" className="block mb-2">Did you enjoy this quest?</Label>
                <RadioGroup id="rating" value={rating} onValueChange={setRating} className="flex justify-between">
                  <div className="flex flex-col items-center">
                    <RadioGroupItem value="1" id="r1" className="sr-only" />
                    <Label htmlFor="r1" className="cursor-pointer">
                      <div className={`p-2 rounded-full ${rating === '1' ? 'bg-red-100' : ''}`}>
                        <ThumbsDown className={`h-6 w-6 ${rating === '1' ? 'text-red-500' : 'text-muted-foreground'}`} />
                      </div>
                      <span className="text-xs block mt-1">Not at all</span>
                    </Label>
                  </div>
                  
                  <div className="flex flex-col items-center">
                    <RadioGroupItem value="2" id="r2" className="sr-only" />
                    <Label htmlFor="r2" className="cursor-pointer">
                      <div className={`p-2 rounded-full ${rating === '2' ? 'bg-orange-100' : ''}`}>
                        <ThumbsDown className={`h-6 w-6 ${rating === '2' ? 'text-orange-500' : 'text-muted-foreground'}`} />
                      </div>
                      <span className="text-xs block mt-1">Not really</span>
                    </Label>
                  </div>
                  
                  <div className="flex flex-col items-center">
                    <RadioGroupItem value="3" id="r3" className="sr-only" />
                    <Label htmlFor="r3" className="cursor-pointer">
                      <div className={`p-2 rounded-full ${rating === '3' ? 'bg-yellow-100' : ''}`}>
                        <div className="h-6 w-6 flex justify-center items-center text-lg font-bold">
                          <span className={rating === '3' ? 'text-yellow-500' : 'text-muted-foreground'}>😐</span>
                        </div>
                      </div>
                      <span className="text-xs block mt-1">Average</span>
                    </Label>
                  </div>
                  
                  <div className="flex flex-col items-center">
                    <RadioGroupItem value="4" id="r4" className="sr-only" />
                    <Label htmlFor="r4" className="cursor-pointer">
                      <div className={`p-2 rounded-full ${rating === '4' ? 'bg-green-100' : ''}`}>
                        <ThumbsUp className={`h-6 w-6 ${rating === '4' ? 'text-green-500' : 'text-muted-foreground'}`} />
                      </div>
                      <span className="text-xs block mt-1">Enjoyed it</span>
                    </Label>
                  </div>
                  
                  <div className="flex flex-col items-center">
                    <RadioGroupItem value="5" id="r5" className="sr-only" />
                    <Label htmlFor="r5" className="cursor-pointer">
                      <div className={`p-2 rounded-full ${rating === '5' ? 'bg-green-100' : ''}`}>
                        <ThumbsUp className={`h-6 w-6 ${rating === '5' ? 'text-green-500' : 'text-muted-foreground'}`} />
                      </div>
                      <span className="text-xs block mt-1">Loved it!</span>
                    </Label>
                  </div>
                </RadioGroup>
              </div>
              
              <div>
                <Label htmlFor="feedback" className="block mb-2">
                  Share your thoughts about this quest:
                </Label>
                <Textarea 
                  id="feedback"
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  placeholder="What did you like or dislike about this quest? Any suggestions for improvement?"
                  className="w-full min-h-24"
                />
              </div>
              
              <Button 
                onClick={handleFeedbackSubmit} 
                disabled={isSubmitting} 
                className="w-full"
              >
                {isSubmitting && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                Submit Feedback
              </Button>
            </div>
          </div>
        )}
        
        {feedbackSubmitted && (
          <Alert className="bg-green-50 border-green-200">
            <AlertTitle>Thank you for your feedback!</AlertTitle>
            <AlertDescription>
              We appreciate you taking the time to share your thoughts with us.
            </AlertDescription>
          </Alert>
        )}
        
        {/* Navigation Buttons */}
        <div className="grid grid-cols-2 gap-4 mt-8">
          <Button asChild variant="outline">
            <Link to="/">
              <Home className="mr-2 h-4 w-4" />
              Home
            </Link>
          </Button>
          <Button asChild>
            <Link to="/account">
              <Star className="mr-2 h-4 w-4" />
              My Quests
            </Link>
          </Button>
        </div>
        
        {/* Share Section */}
        <div className="space-y-3">
          <h3 className="font-medium">Share your achievement:</h3>
          <div className="grid grid-cols-4 gap-2">
            <Button variant="outline" size="icon" onClick={handleFacebookShare} title="Share on Facebook">
              <Facebook className="h-5 w-5 text-blue-600" />
            </Button>
            <Button variant="outline" size="icon" onClick={handleTwitterShare} title="Share on Twitter/X">
              <Twitter className="h-5 w-5 text-blue-400" />
            </Button>
            <Button variant="outline" size="icon" onClick={handleWhatsAppShare} title="Share on WhatsApp">
              <MessageCircle className="h-5 w-5 text-green-500" />
            </Button>
            <Button variant="outline" size="icon" onClick={handleTelegramShare} title="Share on Telegram">
              <MessageCircle className="h-5 w-5 text-blue-500" />
            </Button>
          </div>
          <Button variant="outline" onClick={handleCopyLink} className="w-full flex items-center justify-center">
            <Copy className="mr-2 h-4 w-4" />
            Copy Link
          </Button>
        </div>
      </CardContent>
      
      <CardFooter className="flex justify-center items-center pt-0 pb-6">
        <p className="text-xs text-muted-foreground">
          Quest completed on {new Date(completionData?.completedAt || Date.now()).toLocaleDateString()}
        </p>
      </CardFooter>
    </Card>
  );
};

export default FinishScreen;
