import React, { useState, useEffect } from 'react';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Textarea } from '../ui/textarea';
import { Alert, AlertDescription } from '../ui/alert';
import { Checkbox } from '../ui/checkbox';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { AlertCircle, Save, X, Loader2, FileEdit } from 'lucide-react';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../ui/tabs';
import PointEditor from './PointEditor';
import OptionEditor from './OptionEditor';
import SequenceEditor from './SequenceEditor';
import { adminApi } from '../../api/adminApi';

const QuestForm = ({ quest, questId, creationStage = 'info', onSubmit, onCancel }) => {
  // If quest is new (missing id), show only basic form initially
  const isNew = !quest || !quest.id;
  const [activeTab, setActiveTab] = useState('basic');
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    duration: '',
    difficulty: 'Medium',
    maxParticipants: '5',
    allowTeams: true,
    is_active: true,
    points: []
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);
  const [isDirty, setIsDirty] = useState(false);
  const [currentStage, setCurrentStage] = useState(creationStage || 'info');

  useEffect(() => {
    setCurrentStage(creationStage);
  }, [creationStage]);

  useEffect(() => {
    if (quest) {
      setFormData({
        name: quest.name || '',
        description: quest.description || '',
        price: quest.price !== undefined ? quest.price.toString() : '',
        duration: quest.duration !== undefined ? quest.duration.toString() : '',
        difficulty: quest.difficulty || 'Medium',
        maxParticipants: quest.maxParticipants !== undefined ? quest.maxParticipants.toString() : '5',
        allowTeams: quest.allowTeams !== undefined ? quest.allowTeams : true,
        is_active: quest.is_active !== undefined ? quest.is_active : true,
        points: quest.points ? quest.points.map(point => ({
          ...point,
          steps: point.steps.map(step => ({
            ...step,
            audioFile: step.audioFile || null,
            templateText: step.templateText || '',
            similarityThreshold: step.similarityThreshold || 25.0,
            // Add fields for choice type
            options: step.options || [],
            correctOptions: step.correctOptions || [],
            multipleSelect: step.multipleSelect || false,
            // Add fields for sequence type
            sequenceItems: step.sequenceItems || [],
            correctSequence: step.correctSequence || [],
            // Add fields for AI Chat
            aiInstructions: step.aiInstructions || '',
            maxQuestions: step.maxQuestions || 3,
            imageFile: step.imageFile || null
            // Удалено поле gptModel
          }))
        })) : []
      });
    }
  }, [quest]);

  const validateForm = () => {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = 'Quest name is required';
    }

    if (!formData.description.trim()) {
      errors.description = 'Description is required';
    }

    // Allow free quests (price = 0) but not negative prices
    if (formData.price === '' || isNaN(formData.price) || Number(formData.price) < 0) {
      errors.price = 'Valid price is required (0 for free is allowed)';
    }

    if (!formData.duration || isNaN(formData.duration) || Number(formData.duration) <= 0) {
      errors.duration = 'Valid duration is required';
    }

    // If not in initial creation stage, validate points and steps
    if (currentStage === 'points') {
      if (formData.points.length === 0) {
        errors.points = 'At least one point is required';
      }

      formData.points.forEach((point, pointIndex) => {
        if (!point.character.trim()) {
          errors[`point_${pointIndex}_character`] = `Character name is required for point ${pointIndex + 1}`;
        }

        if (!point.steps || point.steps.length === 0) {
          errors[`point_${pointIndex}_steps`] = `At least one step is required for point ${pointIndex + 1}`;
        }

        point.steps?.forEach((step, stepIndex) => {
          if (!step.text.trim()) {
            errors[`point_${pointIndex}_step_${stepIndex}_text`] = `Text is required for step ${stepIndex + 1}`;
          }
          if (step.type === 'riddle' && !step.correctAnswer?.trim()) {
            errors[`point_${pointIndex}_step_${stepIndex}_answer`] = `Answer is required for riddle`;
          }
          if (step.type === 'image' && !step.templateText?.trim()) {
            errors[`point_${pointIndex}_step_${stepIndex}_template`] = `Template text is required for image step`;
          }
          if (step.type === 'choice' && step.options.length === 0) {
            errors[`point_${pointIndex}_step_${stepIndex}_options`] = `At least one option is required for choice step`;
          }
          if (step.type === 'choice' && step.correctOptions.length === 0) {
            errors[`point_${pointIndex}_step_${stepIndex}_correctOptions`] = `At least one correct option must be selected`;
          }
          if (step.type === 'sequence' && step.sequenceItems.length < 2) {
            errors[`point_${pointIndex}_step_${stepIndex}_sequenceItems`] = `At least two sequence items are required`;
          }
          // AI Chat validation
          if (step.type === 'aichat') {
            if (!step.aiInstructions?.trim()) {
              errors[`point_${pointIndex}_step_${stepIndex}_aiInstructions`] = `AI instructions are required for AI chat step`;
            }
            if (!step.correctAnswer?.trim()) {
              errors[`point_${pointIndex}_step_${stepIndex}_correctAnswer`] = `Correct answer is required for AI chat step`;
            }
          }
        });
      });
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setValidationErrors({});

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      setError({ message: 'Please fix the validation errors' });
      return;
    }

    try {
      setIsLoading(true);

      const processedData = {
        ...formData,
        price: Number(formData.price),
        duration: Number(formData.duration),
        maxParticipants: Number(formData.maxParticipants)
      };

      await onSubmit(processedData);
      setIsDirty(false);
      
      // If we're in the first stage and everything was saved successfully, 
      // automatically move to the points stage
      if (currentStage === 'info' && !isNew) {
        setCurrentStage('points');
        setActiveTab('points');
      }
    } catch (error) {
      setError({
        message: error.message || 'An error occurred while saving the quest'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePointsChange = (points) => {
    setFormData(prev => ({
      ...prev,
      points
    }));
    setIsDirty(true);
  };

  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
    setIsDirty(true);
    if (validationErrors[field]) {
      setValidationErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[field];
        return newErrors;
      });
    }
  };

  const handleFileUpload = async (pointIndex, stepIndex, data, type) => {
    try {
      setUploadProgress(0);

      if (type === 'audio') {
        const file = data;
        const uploadResult = await adminApi.uploadAudio(
          questId,
          pointIndex,
          stepIndex,
          file,
          {
            onUploadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(progress);
            }
          }
        );
        setFormData(prev => {
          const newPoints = [...prev.points];
          newPoints[pointIndex].steps[stepIndex].audioFile = uploadResult.filename;
          return { ...prev, points: newPoints };
        });
        return uploadResult;
      } else if (type === 'template') {
        const { templateText, similarityThreshold } = data;
        const uploadResult = await adminApi.saveTemplate(
          questId,
          pointIndex,
          stepIndex,
          templateText,
          similarityThreshold
        );
        setFormData(prev => {
          const newPoints = [...prev.points];
          newPoints[pointIndex].steps[stepIndex].templateText = templateText;
          newPoints[pointIndex].steps[stepIndex].similarityThreshold = similarityThreshold;
          return { ...prev, points: newPoints };
        });
        return uploadResult;
      } else if (type === 'option-image') {
        const { optionId, file } = data;
        const uploadResult = await adminApi.uploadOptionImage(
          questId,
          pointIndex,
          stepIndex,
          optionId,
          file,
          {
            onUploadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(progress);
            }
          }
        );
        return uploadResult;
      } else if (type === 'sequence-image') {
        const { itemId, file } = data;
        const uploadResult = await adminApi.uploadSequenceImage(
          questId,
          pointIndex,
          stepIndex,
          itemId,
          file,
          {
            onUploadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(progress);
            }
          }
        );
        return uploadResult;
      } else if (type === 'aichat-image') {
        const file = data;
        const uploadResult = await adminApi.uploadAIChatImage(
          questId,
          pointIndex,
          stepIndex,
          file,
          {
            onUploadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(progress);
            }
          }
        );
        setFormData(prev => {
          const newPoints = [...prev.points];
          newPoints[pointIndex].steps[stepIndex].imageFile = uploadResult.filename;
          return { ...prev, points: newPoints };
        });
        return uploadResult;
      }
      
      setError({
        type: 'success',
        message: `${type === 'audio' ? 'Audio' : (type === 'template' ? 'Template' : 'Image')} saved successfully!`
      });
      setTimeout(() => {
        setError(null);
      }, 3000);
    } catch (error) {
      setError({
        message: error.message || `Error uploading ${type}`
      });
    } finally {
      setUploadProgress(0);
    }
  };

  // Helper for handling option and sequence component changes
  const handleStepDataChange = (pointIndex, stepIndex, data) => {
    setFormData(prev => {
      const newPoints = [...prev.points];
      newPoints[pointIndex].steps[stepIndex] = {
        ...newPoints[pointIndex].steps[stepIndex],
        ...data
      };
      return { ...prev, points: newPoints };
    });
    setIsDirty(true);
  };

  // Handle option image upload
  const handleOptionImageUpload = async (questId, pointIndex, stepIndex, optionId, file) => {
    return handleFileUpload(pointIndex, stepIndex, { optionId, file }, 'option-image');
  };

  // Handle sequence image upload
  const handleSequenceImageUpload = async (questId, pointIndex, stepIndex, itemId, file) => {
    return handleFileUpload(pointIndex, stepIndex, { itemId, file }, 'sequence-image');
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">
          {isNew ? 'Create Quest' : (currentStage === 'info' ? 'Edit Quest Information' : 'Edit Quest Content')}
        </h2>
        <div className="space-x-2">
          <Button
            type="button"
            variant="outline"
            onClick={onCancel}
            disabled={isLoading}
          >
            <X className="h-4 w-4" />
            Cancel
          </Button>
          <Button
            type="submit"
            variant="brown"
            disabled={isLoading || !isDirty}
          >
            {isLoading ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                Saving...
              </>
            ) : (
              <>
                <Save className="h-4 w-4" />
                {currentStage === 'info' 
                  ? (isNew ? 'Create Quest' : 'Save Information & Continue') 
                  : 'Save Quest'}
              </>
            )}
          </Button>
        </div>
      </div>

      {error && (
        <Alert variant={error.type === 'success' ? 'default' : 'destructive'}>
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>{error.message}</AlertDescription>
        </Alert>
      )}

      {uploadProgress > 0 && (
        <div className="w-full bg-gray-200 rounded-full h-2">
          <div
            className="bg-primary rounded-full h-full transition-all duration-300"
            style={{ width: `${uploadProgress}%` }}
          />
        </div>
      )}

      {currentStage === 'info' ? (
        <div className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="name">Quest Name</Label>
              <Input
                id="name"
                value={formData.name}
                onChange={e => handleInputChange('name', e.target.value)}
                error={validationErrors.name}
                placeholder="Enter quest name"
              />
              {validationErrors.name && (
                <p className="text-sm text-red-500">{validationErrors.name}</p>
              )}
            </div>

            <div className="space-y-2">
              <Label htmlFor="difficulty">Difficulty</Label>
              <Select
                value={formData.difficulty}
                onValueChange={value => handleInputChange('difficulty', value)}
              >
                <SelectTrigger id="difficulty">
                  <SelectValue placeholder="Select difficulty" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Easy">Easy</SelectItem>
                  <SelectItem value="Medium">Medium</SelectItem>
                  <SelectItem value="Hard">Hard</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label htmlFor="price">Price ($) (0 for free)</Label>
              <Input
                id="price"
                type="number"
                min="0"
                step="0.01"
                value={formData.price}
                onChange={e => handleInputChange('price', e.target.value)}
                error={validationErrors.price}
                placeholder="Enter price (0 for free)"
              />
              {validationErrors.price && (
                <p className="text-sm text-red-500">{validationErrors.price}</p>
              )}
            </div>

            <div className="space-y-2">
              <Label htmlFor="duration">Duration (minutes)</Label>
              <Input
                id="duration"
                type="number"
                min="1"
                value={formData.duration}
                onChange={e => handleInputChange('duration', e.target.value)}
                error={validationErrors.duration}
                placeholder="Enter duration"
              />
              {validationErrors.duration && (
                <p className="text-sm text-red-500">{validationErrors.duration}</p>
              )}
            </div>

            <div className="space-y-2">
              <Label htmlFor="allowTeams">Team Mode</Label>
              <Select
                value={formData.allowTeams.toString()}
                onValueChange={value => handleInputChange('allowTeams', value === 'true')}
              >
                <SelectTrigger id="allowTeams">
                  <SelectValue placeholder="Select team mode" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="true">Enabled</SelectItem>
                  <SelectItem value="false">Disabled</SelectItem>
                </SelectContent>
              </Select>
            </div>

            {formData.allowTeams && (
              <div className="space-y-2">
                <Label htmlFor="maxParticipants">Max Team Size</Label>
                <Select
                  value={formData.maxParticipants.toString()}
                  onValueChange={value => handleInputChange('maxParticipants', value)}
                >
                  <SelectTrigger id="maxParticipants">
                    <SelectValue placeholder="Select max team size" />
                  </SelectTrigger>
                  <SelectContent>
                    {[2, 3, 4, 5].map(num => (
                      <SelectItem key={num} value={num.toString()}>
                        {num} players
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            )}
          </div>

          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <Checkbox
                id="is_active"
                checked={formData.is_active}
                onCheckedChange={(checked) => handleInputChange('is_active', checked)}
              />
              <Label htmlFor="is_active">Active Quest</Label>
            </div>
            <p className="text-sm text-muted-foreground pl-6">
              Inactive quests will not appear in the public quest list
            </p>
          </div>

          <div className="space-y-2">
            <Label htmlFor="description">Description</Label>
            <Textarea
              id="description"
              value={formData.description}
              onChange={e => handleInputChange('description', e.target.value)}
              error={validationErrors.description}
              placeholder="Enter quest description"
              rows={4}
            />
            {validationErrors.description && (
              <p className="text-sm text-red-500">{validationErrors.description}</p>
            )}
          </div>
        </div>
      ) : (
        <Tabs value={activeTab} onValueChange={setActiveTab}>
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="basic">Basic Information</TabsTrigger>
            <TabsTrigger value="points">Quest Points</TabsTrigger>
          </TabsList>

          <TabsContent value="basic" className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="name">Quest Name</Label>
                <Input
                  id="name"
                  value={formData.name}
                  onChange={e => handleInputChange('name', e.target.value)}
                  error={validationErrors.name}
                  placeholder="Enter quest name"
                />
                {validationErrors.name && (
                  <p className="text-sm text-red-500">{validationErrors.name}</p>
                )}
              </div>

              <div className="space-y-2">
                <Label htmlFor="difficulty">Difficulty</Label>
                <Select
                  value={formData.difficulty}
                  onValueChange={value => handleInputChange('difficulty', value)}
                >
                  <SelectTrigger id="difficulty">
                    <SelectValue placeholder="Select difficulty" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Easy">Easy</SelectItem>
                    <SelectItem value="Medium">Medium</SelectItem>
                    <SelectItem value="Hard">Hard</SelectItem>
                  </SelectContent>
                </Select>
              </div>

              <div className="space-y-2">
                <Label htmlFor="price">Price ($) (0 for free)</Label>
                <Input
                  id="price"
                  type="number"
                  min="0"
                  step="0.01"
                  value={formData.price}
                  onChange={e => handleInputChange('price', e.target.value)}
                  error={validationErrors.price}
                  placeholder="Enter price (0 for free)"
                />
                {validationErrors.price && (
                  <p className="text-sm text-red-500">{validationErrors.price}</p>
                )}
              </div>

              <div className="space-y-2">
                <Label htmlFor="duration">Duration (minutes)</Label>
                <Input
                  id="duration"
                  type="number"
                  min="1"
                  value={formData.duration}
                  onChange={e => handleInputChange('duration', e.target.value)}
                  error={validationErrors.duration}
                  placeholder="Enter duration"
                />
                {validationErrors.duration && (
                  <p className="text-sm text-red-500">{validationErrors.duration}</p>
                )}
              </div>

              <div className="space-y-2">
                <Label htmlFor="allowTeams">Team Mode</Label>
                <Select
                  value={formData.allowTeams.toString()}
                  onValueChange={value => handleInputChange('allowTeams', value === 'true')}
                >
                  <SelectTrigger id="allowTeams">
                    <SelectValue placeholder="Select team mode" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="true">Enabled</SelectItem>
                    <SelectItem value="false">Disabled</SelectItem>
                  </SelectContent>
                </Select>
              </div>

              {formData.allowTeams && (
                <div className="space-y-2">
                  <Label htmlFor="maxParticipants">Max Team Size</Label>
                  <Select
                    value={formData.maxParticipants.toString()}
                    onValueChange={value => handleInputChange('maxParticipants', value)}
                  >
                    <SelectTrigger id="maxParticipants">
                      <SelectValue placeholder="Select max team size" />
                    </SelectTrigger>
                    <SelectContent>
                      {[2, 3, 4, 5].map(num => (
                        <SelectItem key={num} value={num.toString()}>
                          {num} players
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              )}
            </div>

            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="is_active_tab"
                  checked={formData.is_active}
                  onCheckedChange={(checked) => handleInputChange('is_active', checked)}
                />
                <Label htmlFor="is_active_tab">Active Quest</Label>
              </div>
              <p className="text-sm text-muted-foreground pl-6">
                Inactive quests will not appear in the public quest list
              </p>
            </div>

            <div className="space-y-2">
              <Label htmlFor="description">Description</Label>
              <Textarea
                id="description"
                value={formData.description}
                onChange={e => handleInputChange('description', e.target.value)}
                error={validationErrors.description}
                placeholder="Enter quest description"
                rows={4}
              />
              {validationErrors.description && (
                <p className="text-sm text-red-500">{validationErrors.description}</p>
              )}
            </div>
          </TabsContent>

          <TabsContent value="points" className="space-y-4">
            {validationErrors.points && (
              <Alert variant="destructive" className="mb-4">
                <AlertCircle className="h-4 w-4" />
                <AlertDescription>{validationErrors.points}</AlertDescription>
              </Alert>
            )}
            
            <PointEditor 
              points={formData.points}
              onChange={handlePointsChange}
              onFileUpload={handleFileUpload}
              validationErrors={validationErrors}
              questId={questId}
              onOptionImageUpload={handleOptionImageUpload}
              onSequenceImageUpload={handleSequenceImageUpload}
              onStepDataChange={handleStepDataChange}
            />
          </TabsContent>
        </Tabs>
      )}
    </form>
  );
};

export default QuestForm;
