import * as React from "react";
import { useDropzone } from "react-dropzone";
import { cn } from "../../lib/utils";
import { Upload, File, X, Loader2, AlertCircle } from "lucide-react";
import { Button } from "./button";
import { Progress } from "./progress";
import { Alert, AlertDescription } from "./alert";

const FileUpload = React.forwardRef(({
  className,
  accept,
  maxSize = 5242880, // 5MB by default
  maxFiles = 1,
  onUpload,
  onRemove,
  loading = false,
  progress = 0,
  value,
  error,
  disabled = false,
  children,
  ...props
}, ref) => {
  const [isDragActive, setIsDragActive] = React.useState(false);
  const [localError, setLocalError] = React.useState(null);
  const [selectedFile, setSelectedFile] = React.useState(null);

  // Если value передан из родительского компонента, используем его
  React.useEffect(() => {
    if (value) {
      setSelectedFile(value);
    }
  }, [value]);

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    fileRejections,
  } = useDropzone({
    accept,
    maxSize,
    maxFiles,
    disabled: loading || disabled,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        try {
          setLocalError(null);
          const file = acceptedFiles[0];
          setSelectedFile(file);
          
          // Уведомляем родительский компонент о выборе файла, но не запускаем загрузку
          if (onUpload) {
            onUpload(file);
          }
        } catch (error) {
          setLocalError(error.message || "Error selecting file");
        }
      }
    },
    onDragEnter: () => setIsDragActive(true),
    onDragLeave: () => setIsDragActive(false),
  });

  const handleRemoveFile = (e) => {
    e.stopPropagation();
    setSelectedFile(null);
    if (onRemove) {
      onRemove();
    }
  };

  const getErrorMessage = (rejection) => {
    if (rejection.errors[0]?.code === "file-too-large") {
      return `File is too large. Max size is ${maxSize / 1024 / 1024}MB`;
    }
    if (rejection.errors[0]?.code === "file-invalid-type") {
      const acceptedTypes = Object.values(accept || {}).flat().join(", ");
      return `Invalid file type. Accepted types: ${acceptedTypes}`;
    }
    return rejection.errors[0]?.message || "Invalid file";
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  // Get accepted file types for display
  const getAcceptedFileTypes = () => {
    if (!accept) return "All files";
    return Object.entries(accept)
      .map(([type, extensions]) => {
        const typeLabel = type.split("/")[0];
        return `${typeLabel.charAt(0).toUpperCase() + typeLabel.slice(1)} (${extensions.join(", ")})`;
      })
      .join(", ");
  };

  return (
    <div className={cn("space-y-4", className)} {...props} ref={ref}>
      <div
        {...getRootProps()}
        className={cn(
          "relative cursor-pointer rounded-lg border-2 border-dashed border-muted-foreground/25 p-6 transition-colors",
          isDragActive && "border-primary/50 bg-primary/5",
          isDragAccept && "border-green-500/50 bg-green-500/5",
          isDragReject && "border-red-500/50 bg-red-500/5",
          (loading || disabled) && "pointer-events-none opacity-60",
          selectedFile && "border-solid border-muted bg-muted/50",
          className
        )}
      >
        <input {...getInputProps()} />
        
        {selectedFile ? (
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <File className="h-4 w-4 text-muted-foreground" />
              <span className="text-sm font-medium">
                {selectedFile.name}
              </span>
              {selectedFile.size && (
                <span className="text-xs text-muted-foreground">
                  ({formatFileSize(selectedFile.size)})
                </span>
              )}
            </div>
            {!loading && !disabled && (
              <Button
                variant="ghost"
                size="icon"
                onClick={handleRemoveFile}
                className="h-8 w-8 rounded-full"
              >
                <X className="h-4 w-4" />
              </Button>
            )}
            {loading && (
              <Loader2 className="h-4 w-4 animate-spin text-muted-foreground" />
            )}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center space-y-2 text-center">
            <div className="rounded-full bg-muted p-2">
              <Upload className="h-6 w-6 text-muted-foreground" />
            </div>
            <div className="text-sm font-medium">
              {children || (
                <>
                  {isDragActive ? (
                    isDragAccept ? (
                      "Drop to select file"
                    ) : (
                      "This file type is not accepted"
                    )
                  ) : (
                    <>
                      Drag & drop file here, or{" "}
                      <span className="text-primary">browse</span>
                    </>
                  )}
                </>
              )}
            </div>
            <div className="text-xs text-muted-foreground">
              {maxFiles === 1 ? "One file only" : `Up to ${maxFiles} files`}
              {" • "}Max size: {formatFileSize(maxSize)}
              <br />
              Accepted types: {getAcceptedFileTypes()}
            </div>
          </div>
        )}
      </div>

      {/* Error messages */}
      {(fileRejections.length > 0 || localError || error) && (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>
            {localError || error || fileRejections.map(rejection => (
              <div key={rejection.file.name}>
                {rejection.file.name} - {getErrorMessage(rejection)}
              </div>
            ))}
          </AlertDescription>
        </Alert>
      )}

      {/* Upload progress */}
      {loading && typeof progress === 'number' && progress > 0 && (
        <div className="space-y-2">
          <Progress value={progress} />
          <p className="text-xs text-muted-foreground text-center">
            Uploading... {progress}%
          </p>
        </div>
      )}
    </div>
  );
});

FileUpload.displayName = "FileUpload";

export { FileUpload };
