import React, { useState, useEffect } from 'react';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';
import { Label } from '../ui/label';
import { Alert, AlertDescription } from '../ui/alert';
import { FileUpload } from '../ui/fileUpload';
import { Progress } from '../ui/progress';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { 
  AlertCircle, 
  Upload, 
  X, 
  CheckSquare,
  Plus,
  ImageIcon,
  Loader2
} from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';

const OptionItem = ({ 
  option, 
  onRemove, 
  onChange, 
  onImageUpload, 
  isCorrect, 
  onToggleCorrect,
  questId,
  pointIndex,
  stepIndex
}) => {
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  
  const handleUploadImage = async (file) => {
    setUploading(true);
    setUploadError(null);
    setUploadProgress(0);
    
    try {
      const formData = new FormData();
      formData.append('quest_id', questId);
      formData.append('point_index', pointIndex);
      formData.append('step_index', stepIndex);
      formData.append('option_id', option.id);
      formData.append('file', file);
      
      // Simulate progress while uploading
      const mockProgress = setInterval(() => {
        setUploadProgress(prev => {
          if (prev >= 90) {
            clearInterval(mockProgress);
            return 90;
          }
          return prev + 10;
        });
      }, 200);
      
      const result = await onImageUpload(file);
      clearInterval(mockProgress);
      
      if (result) {
        setUploadProgress(100);
        // Set the filename from the response or use the uploaded file name
        onChange('image', result.filename || file.name);
        setTimeout(() => {
          setUploadProgress(0);
        }, 1000);
      }
    } catch (error) {
      console.error('Error uploading option image:', error);
      setUploadError('Failed to upload image');
    } finally {
      setUploading(false);
    }
  };
  
  return (
    <div className="border rounded-lg p-3 mb-2 relative">
      <div className="absolute top-2 right-2 flex gap-1">
        <Button 
          type="button"
          size="sm" 
          variant={isCorrect ? "default" : "outline"}
          onClick={onToggleCorrect}
          className="p-1 h-7 w-7"
          title={isCorrect ? "Correct option" : "Mark as correct"}
        >
          <CheckSquare className="h-4 w-4" />
        </Button>
        <Button 
          type="button"
          size="sm" 
          variant="ghost" 
          onClick={onRemove}
          className="text-red-500 p-1 h-7 w-7"
        >
          <X className="h-4 w-4" />
        </Button>
      </div>
      
      <div className="space-y-3 mt-6">
        <div>
          <Label className="text-xs">Option Text</Label>
          <Textarea
            value={option.text}
            onChange={(e) => onChange('text', e.target.value)}
            placeholder="Enter option text"
            className="mt-1"
            rows={2}
          />
        </div>
        
        <div>
          <Label className="text-xs">Option Image (Optional)</Label>
          <div className="flex items-center gap-2 mt-1">
            {option.image ? (
              <div className="flex items-center justify-between w-full border rounded p-2">
                <div className="flex items-center gap-2">
                  <ImageIcon className="h-4 w-4 text-muted-foreground" />
                  <span className="text-sm">{option.image}</span>
                </div>
                <Button
                  type="button"
                  variant="ghost"
                  size="sm"
                  onClick={() => onChange('image', null)}
                  className="text-red-500 p-1 h-7 w-7"
                >
                  <X className="h-4 w-4" />
                </Button>
              </div>
            ) : (
              <FileUpload
                accept={{ 'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.webp'] }}
                maxSize={5242880} // 5MB
                onUpload={handleUploadImage}
                className="w-full"
                disabled={uploading}
              >
                <Button
                  type="button"
                  variant="outline"
                  className="w-full"
                  disabled={uploading}
                >
                  {uploading ? (
                    <>
                      <Loader2 className="h-4 w-4 animate-spin" />
                      Uploading {uploadProgress}%
                    </>
                  ) : (
                    <>
                      <Upload className="h-4 w-4" />
                      Upload Image
                    </>
                  )}
                </Button>
              </FileUpload>
            )}
          </div>
          
          {uploadProgress > 0 && uploadProgress < 100 && (
            <Progress 
              value={uploadProgress} 
              className="h-1 mt-1" 
            />
          )}
          
          {uploadError && (
            <p className="text-sm text-red-500 mt-1">{uploadError}</p>
          )}
        </div>
      </div>
    </div>
  );
};

const OptionEditor = ({
  options = [],
  correctOptions = [],
  multipleSelect = false,
  onChange,
  onImageUpload,
  questId,
  pointIndex,
  stepIndex,
  validationErrors = {}
}) => {
  const [localOptions, setLocalOptions] = useState(options);
  const [localCorrectOptions, setLocalCorrectOptions] = useState(correctOptions);
  const [localMultipleSelect, setLocalMultipleSelect] = useState(multipleSelect);
  
  // Update parent component whenever local state changes
  useEffect(() => {
    onChange({
      options: localOptions,
      correctOptions: localCorrectOptions,
      multipleSelect: localMultipleSelect
    });
  }, [localOptions, localCorrectOptions, localMultipleSelect, onChange]);
  
  // Update local state when props change
  useEffect(() => {
    setLocalOptions(options);
    setLocalCorrectOptions(correctOptions);
    setLocalMultipleSelect(multipleSelect);
  }, [options, correctOptions, multipleSelect]);
  
  const handleAddOption = () => {
    const newOption = {
      id: uuidv4(),
      text: '',
      image: null
    };
    setLocalOptions([...localOptions, newOption]);
  };
  
  const handleRemoveOption = (optionId) => {
    setLocalOptions(localOptions.filter(option => option.id !== optionId));
    setLocalCorrectOptions(localCorrectOptions.filter(id => id !== optionId));
  };
  
  const handleOptionChange = (optionId, field, value) => {
    setLocalOptions(localOptions.map(option => 
      option.id === optionId ? { ...option, [field]: value } : option
    ));
  };
  
  const handleToggleCorrect = (optionId) => {
    if (localCorrectOptions.includes(optionId)) {
      setLocalCorrectOptions(localCorrectOptions.filter(id => id !== optionId));
    } else {
      if (localMultipleSelect) {
        setLocalCorrectOptions([...localCorrectOptions, optionId]);
      } else {
        setLocalCorrectOptions([optionId]);
      }
    }
  };
  
  const handleToggleMultipleSelect = (value) => {
    const isMultiple = value === 'true';
    setLocalMultipleSelect(isMultiple);
    
    // If switching to single select and multiple options are selected,
    // keep only the first one
    if (!isMultiple && localCorrectOptions.length > 1) {
      setLocalCorrectOptions([localCorrectOptions[0]]);
    }
  };
  
  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <Label className="text-base font-medium">Options</Label>
        <div className="space-x-2">
          <Select
            value={localMultipleSelect.toString()}
            onValueChange={handleToggleMultipleSelect}
          >
            <SelectTrigger className="w-40">
              <SelectValue placeholder="Selection type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="false">Single selection</SelectItem>
              <SelectItem value="true">Multiple selection</SelectItem>
            </SelectContent>
          </Select>
          <Button
            type="button"
            size="sm"
            onClick={handleAddOption}
          >
            <Plus className="h-4 w-4" /> 
            Add Option
          </Button>
        </div>
      </div>
      
      {localOptions.length === 0 ? (
        <Alert>
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>
            Add at least one option using the button above
          </AlertDescription>
        </Alert>
      ) : (
        <div className="space-y-2">
          {localOptions.map(option => (
            <OptionItem
              key={option.id}
              option={option}
              onRemove={() => handleRemoveOption(option.id)}
              onChange={(field, value) => handleOptionChange(option.id, field, value)}
              onImageUpload={(file) => onImageUpload && onImageUpload(option.id, file)}
              isCorrect={localCorrectOptions.includes(option.id)}
              onToggleCorrect={() => handleToggleCorrect(option.id)}
              questId={questId}
              pointIndex={pointIndex}
              stepIndex={stepIndex}
            />
          ))}
          
          {localCorrectOptions.length === 0 && (
            <Alert variant="destructive">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>
                Mark at least one option as correct
              </AlertDescription>
            </Alert>
          )}
        </div>
      )}
    </div>
  );
};

export default OptionEditor;
