import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs';
import { Alert, AlertDescription } from '../ui/alert';
import QuestManager from './QuestManager';
import UserManager from './UserManager';
import { AlertCircle, Users, Trophy, Book } from 'lucide-react';
import { adminApi } from '../../api/adminApi';

const AdminPanel = () => {
    const [statistics, setStatistics] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchStatistics = async () => {
            try {
                setIsLoading(true);
                const response = await adminApi.getStatistics();
                setStatistics(response.data);
            } catch (error) {
                setError(adminApi.handleError(error));
            } finally {
                setIsLoading(false);
            }
        };

        fetchStatistics();
    }, []);

    return (
        <div className="container mx-auto p-4 space-y-6">
            <Card>
                <CardHeader>
                    <CardTitle className="text-3xl font-bold">Admin Dashboard</CardTitle>
                </CardHeader>

                {error && (
                    <CardContent>
                        <Alert variant="destructive">
                            <AlertCircle className="h-4 w-4" />
                            <AlertDescription>{error.message}</AlertDescription>
                        </Alert>
                    </CardContent>
                )}

                {!isLoading && statistics && (
                    <CardContent>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <Card>
                                <CardContent className="pt-6">
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <p className="text-sm font-medium text-muted-foreground">Total Quests</p>
                                            <h3 className="text-2xl font-bold">{statistics.quests.total}</h3>
                                        </div>
                                        <Book className="h-8 w-8 text-primary" />
                                    </div>
                                    <div className="mt-4 text-sm text-muted-foreground">
                                        <div>Purchased: {statistics.quests.total_purchases}</div>
                                        <div>Completed: {statistics.quests.total_completed}</div>
                                    </div>
                                </CardContent>
                            </Card>

                            <Card>
                                <CardContent className="pt-6">
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <p className="text-sm font-medium text-muted-foreground">Active Teams</p>
                                            <h3 className="text-2xl font-bold">{statistics.teams.active}</h3>
                                        </div>
                                        <Users className="h-8 w-8 text-primary" />
                                    </div>
                                    <div className="mt-4 text-sm text-muted-foreground">
                                        <div>Total Teams: {statistics.teams.total}</div>
                                    </div>
                                </CardContent>
                            </Card>

                            <Card>
                                <CardContent className="pt-6">
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <p className="text-sm font-medium text-muted-foreground">Completion Rate</p>
                                            <h3 className="text-2xl font-bold">
                                                {((statistics.quests.total_completed / statistics.quests.total_purchases) * 100).toFixed(1)}%
                                            </h3>
                                        </div>
                                        <Trophy className="h-8 w-8 text-primary" />
                                    </div>
                                    <div className="mt-4 text-sm text-muted-foreground">
                                        <div>Last updated: {new Date(statistics.timestamp).toLocaleString()}</div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </CardContent>
                )}
            </Card>

            <Tabs defaultValue="quests" className="w-full">
                <TabsList>
                    <TabsTrigger value="quests">Quests</TabsTrigger>
                    <TabsTrigger value="users">Users</TabsTrigger>
                    <TabsTrigger value="statistics" disabled>Statistics</TabsTrigger>
                </TabsList>

                <TabsContent value="quests">
                    <QuestManager />
                </TabsContent>

                <TabsContent value="users">
                    <UserManager />
                </TabsContent>

                <TabsContent value="statistics">
                    <Card>
                        <CardContent className="p-6">
                            <p className="text-muted-foreground">Detailed statistics coming soon...</p>
                        </CardContent>
                    </Card>
                </TabsContent>
            </Tabs>
        </div>
    );
};

export default AdminPanel;
