import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Alert, AlertDescription } from './ui/alert';
import { Play, Pause, Lightbulb, Send, Loader2, Volume2 } from 'lucide-react';
import useAudioStore from '../hooks/useAudioStore';
import { formatText, hasRussianText } from '../utils/textFormatting';

const RiddleStage = ({
    character,
    text,
    audioFile,
    questId,
    inputText,
    onInputChange,
    onSubmit,
    showHint,
    onHint,
    resultMessage,
    isProcessing,
    hint,
    isTeam = false,
    teamId = null,
    role = ''
}) => {
    // Упрощаем состояние до минимума
    const [localHint, setLocalHint] = useState('');
    const inputRef = useRef(null);
    
    // Определяем, содержит ли текст русские символы
    const isRussianText = hasRussianText(text);
    const formattedText = formatText(text, isRussianText);
    const isRussianCharacter = hasRussianText(character);
    
    const {
        isPlaying,
        progress,
        isLoading: audioLoading,
        error: audioError,
        initSound,
        togglePlayPause,
        setVolume,
        cleanup
    } = useAudioStore();

    // Инициализируем аудио, если оно есть
    useEffect(() => {
        if (audioFile && questId) {
            const audioUrl = `/audio/quests/${questId}/${audioFile}`;
            try {
                initSound(audioUrl, {});
            } catch (error) {
                console.error('Error initializing audio:', error);
            }
        }

        // Автоматически устанавливаем фокус на поле ввода
        if (inputRef.current) {
            inputRef.current.focus();
        }

        return () => {
            cleanup();
        };
    }, [audioFile, questId, initSound, cleanup]);

    // Обработчик нажатия на кнопку подсказки
    // ВАЖНО: показываем подсказку немедленно и только потом вызываем onHint для API
    const handleHintClick = () => {
        // Сразу показываем подсказку без ожидания
        setLocalHint(hint);
        
        // Вызываем callback для API только после установки локального состояния
        setTimeout(() => {
            if (onHint) {
                onHint();
            }
        }, 10);
    };

    // Обработчик отправки формы
    const handleSubmit = (e) => {
        e.preventDefault();
        if (inputText.trim() && !isProcessing) {
            onSubmit(e);
        }
    };

    // Обработчик нажатия клавиши Enter
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && inputText.trim() && !isProcessing) {
            handleSubmit(e);
        }
    };

    return (
        <Card>
            <CardHeader>
                <CardTitle className={`text-xl md:text-2xl text-primary ${isRussianCharacter ? 'font-serif' : 'font-sans'}`}>
                    {character}
                    {isTeam && (
                        <span className="text-sm ml-2 text-muted-foreground">
                            ({role || 'No Role'})
                        </span>
                    )}
                </CardTitle>
            </CardHeader>

            <CardContent className="space-y-6">
                {audioFile && (
                    <div className="space-y-4">
                        <Button
                            onClick={togglePlayPause}
                            disabled={audioLoading}
                            className="w-full flex items-center justify-center space-x-2"
                            variant="outline"
                        >
                            {audioLoading ? (
                                <Loader2 className="h-5 w-5 animate-spin" />
                            ) : isPlaying ? (
                                <><Pause className="h-5 w-5" /> <span>Pause</span></>
                            ) : (
                                <><Play className="h-5 w-5" /> <span>Play Audio</span></>
                            )}
                        </Button>

                        {!audioError && (
                            <div className="space-y-2">
                                <div className="w-full bg-gray-200 rounded-full h-2">
                                    <div
                                        className="bg-primary rounded-full h-full"
                                        style={{ width: `${progress}%` }}
                                    />
                                </div>
                                <div className="flex items-center justify-between text-sm text-muted-foreground">
                                    <span>Progress: {Math.round(progress)}%</span>
                                    <div className="flex items-center gap-2">
                                        <Volume2 className="h-4 w-4" />
                                        <input 
                                            type="range"
                                            min="0"
                                            max="100"
                                            defaultValue="100"
                                            className="w-24"
                                            onChange={(e) => setVolume(e.target.value / 100)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {audioError && (
                            <Alert variant="destructive">
                                <AlertDescription>{audioError}</AlertDescription>
                            </Alert>
                        )}
                    </div>
                )}

                {/* Улучшенное отображение текста с поддержкой русского языка и выравниванием по левому краю */}
                <div className={`prose max-w-none text-content ${isRussianText ? 'text-ru' : ''}`}>
                    {formattedText.map((item, index) => {
                        if (item.type === 'heading1') {
                            return <h1 key={index} className={`${item.class} text-left`}>{item.content}</h1>;
                        }
                        if (item.type === 'heading2') {
                            return <h2 key={index} className={`${item.class} text-left`}>{item.content}</h2>;
                        }
                        if (item.type === 'quote') {
                            return <blockquote key={index} className={`${item.class} text-left`}>{item.content}</blockquote>;
                        }
                        return <p key={index} className={`${item.class} text-left`}>{item.content}</p>;
                    })}
                </div>

                {/* Непосредственно показываем подсказку, если она есть */}
                {localHint && (
                    <Alert className="bg-blue-50 border-blue-200">
                        <AlertDescription className={hasRussianText(localHint) ? 'text-ru text-left' : 'text-left'}>
                            Hint: {localHint}
                        </AlertDescription>
                    </Alert>
                )}

                {/* Показываем сообщение с результатом */}
                {resultMessage && (
                    <Alert variant={resultMessage.includes('Correct') ? 'default' : 'destructive'}>
                        <AlertDescription className={hasRussianText(resultMessage) ? 'text-ru text-left' : 'text-left'}>
                            {resultMessage}
                        </AlertDescription>
                    </Alert>
                )}

                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="relative">
                        <Input
                            type="text"
                            value={inputText}
                            onChange={onInputChange}
                            onKeyPress={handleKeyPress}
                            placeholder="Enter your answer"
                            disabled={isProcessing}
                            ref={inputRef}
                            className={hasRussianText(inputText) ? 'font-russian' : ''}
                        />
                    </div>

                    <div className="flex flex-col sm:flex-row gap-4">
                        <Button
                            type="submit"
                            disabled={isProcessing || !inputText.trim()}
                        >
                            {isProcessing ? (
                                <Loader2 className="h-5 w-5 animate-spin" />
                            ) : (
                                <><Send className="h-5 w-5 mr-2" /> Submit</>
                            )}
                        </Button>

                        {showHint && (
                            <Button
                                type="button"
                                variant="outline"
                                onClick={handleHintClick}
                                disabled={isProcessing || localHint !== ''}
                            >
                                <Lightbulb className="h-5 w-5 mr-2" />
                                Get Hint (-10 points)
                            </Button>
                        )}
                    </div>
                </form>
            </CardContent>
        </Card>
    );
};

RiddleStage.propTypes = {
    character: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    audioFile: PropTypes.string,
    questId: PropTypes.string.isRequired,
    inputText: PropTypes.string.isRequired,
    onInputChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    showHint: PropTypes.bool,
    onHint: PropTypes.func,
    resultMessage: PropTypes.string,
    isProcessing: PropTypes.bool,
    hint: PropTypes.string,
    isTeam: PropTypes.bool,
    teamId: PropTypes.string,
    role: PropTypes.string
};

export default RiddleStage;
