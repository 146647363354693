import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Alert, AlertDescription } from './ui/alert';
import api from '../api';
import {
    Users,
    Copy,
    CheckCircle,
    Clock,
    Loader2,
    Play,
    UserPlus,
    AlertCircle,
    Crown,
    Shield,
} from 'lucide-react';
import useSocket from '../hooks/useSocket';

const TeamWaitingRoom = () => {
    const { teamId } = useParams();
    const navigate = useNavigate();
    const [team, setTeam] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [copied, setCopied] = useState(false);
    const [selectedRole, setSelectedRole] = useState('');
    const [isUpdatingRole, setIsUpdatingRole] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);

    // Инициализируем WebSocket для командного режима через хук
    const { addEventListener, sendMessage } = useSocket(teamId, true, teamId);

    // Отладочный эффект для проверки teamId
    useEffect(() => {
        console.log("TeamWaitingRoom initialized with teamId:", teamId);
        if (!teamId) {
            console.error("ОШИБКА: teamId отсутствует в TeamWaitingRoom!");
            setError("Ошибка: ID команды не найден. Пожалуйста, вернитесь на главную и начните заново.");
        }
    }, [teamId]);

    const fetchTeamStatus = useCallback(async () => {
        try {
            console.log(`Fetching team status for team ${teamId}...`);
            const response = await api.get(`/team/status/${teamId}`);
            setTeam(response.data);
            
            console.log("Team data received:", response.data);

            // Если квест уже начался, переходим на страницу квеста
            if (response.data.status === 'in_progress') {
                console.log("Team quest already in progress, navigating to quest page...");
                
                // Сохраняем информацию о команде перед переходом
                const questModeData = {
                    isTeam: true,
                    role: currentUser?.role || "",
                    teamId: teamId
                };
                
                console.log("Saving team info to localStorage:", questModeData);
                localStorage.setItem(`questMode_${response.data.quest_id}`, JSON.stringify(questModeData));
                
                navigate(`/quest/${response.data.quest_id}/step/1/substep/0`);
                return;
            }

            // Получаем профиль пользователя, если ещё не получен
            if (!currentUser) {
                const profileResponse = await api.get('/account/profile');
                setCurrentUser(profileResponse.data);
            }
        } catch (error) {
            console.error('Error fetching team status:', error);
            setError(error.response?.data?.detail || 'Failed to load team information');
        } finally {
            setIsLoading(false);
        }
    }, [teamId, navigate, currentUser]);

    useEffect(() => {
        fetchTeamStatus();
        const interval = setInterval(fetchTeamStatus, 5000);
        return () => clearInterval(interval);
    }, [fetchTeamStatus]);

    // Подписываемся на обновления из вебсокета
    useEffect(() => {
        const handleConnectionStatus = (data) => {
            console.log('Connection status update:', data);
        };

        const handleTeamUpdate = (data) => {
            // Обновляем состояние команды по данным из вебсокета
            console.log('Team update received:', data);
            setTeam(prevTeam => ({ ...prevTeam, ...data }));
        };

        const handleQuestStarted = (data) => {
            console.log('Quest started event received:', data);
            
            // Сохраняем информацию о команде перед переходом
            if (currentUser && team) {
                const member = team.members.find(m => m.user_id === currentUser._id);
                const questModeData = {
                    isTeam: true,
                    role: member?.role || "",
                    teamId: teamId
                };
                
                console.log("Saving team info before navigation:", questModeData);
                localStorage.setItem(`questMode_${data.questId || team.quest_id}`, JSON.stringify(questModeData));
            }
            
            navigate(`/quest/${data.questId || team?.quest_id}/step/1/substep/0`);
        };

        const removeConnectionStatus = addEventListener('connection_status', handleConnectionStatus);
        const removeTeamUpdate = addEventListener('team_state_update', handleTeamUpdate);
        const removeQuestStarted = addEventListener('quest_started', handleQuestStarted);

        return () => {
            removeConnectionStatus();
            removeTeamUpdate();
            removeQuestStarted();
        };
    }, [addEventListener, navigate, teamId, currentUser, team]);

    const copyTeamCode = async () => {
        try {
            await navigator.clipboard.writeText(team.code);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            setError('Failed to copy team code');
        }
    };

    const handleRoleSelect = async (role) => {
        if (!currentUser) return;

        try {
            setIsUpdatingRole(true);
            setError(null);

            console.log(`Updating role to ${role} for user ${currentUser._id} in team ${teamId}`);
            const response = await api.post(`/team/${teamId}/update-role`, {
                role: role,
                user_id: currentUser._id
            });

            if (response.data) {
                setSelectedRole(role);
                await fetchTeamStatus(); // Обновляем состояние команды
            }
        } catch (error) {
            console.error('Error updating role:', error);
            setError(error.response?.data?.detail || 'Failed to update role');
            setSelectedRole('');
        } finally {
            setIsUpdatingRole(false);
        }
    };

    const handleStartQuest = async () => {
        if (!team) return;

        try {
            // Проверяем, выбраны ли роли у всех участников
            const allHaveRoles = team.members.every(member => member.role);
            if (!allHaveRoles) {
                setError('All team members must select roles before starting');
                return;
            }

            // Находим текущего пользователя и его роль в команде
            const currentMember = currentUser && team.members.find(
                m => m.user_id === currentUser._id
            );

            // ВАЖНО: Сохраняем информацию о команде перед началом квеста
            const questModeData = {
                isTeam: true,
                role: currentMember?.role || "",
                teamId: teamId
            };
            
            console.log("Saving team info to localStorage before starting quest:", questModeData);
            localStorage.setItem(`questMode_${team.quest_id}`, JSON.stringify(questModeData));

            console.log(`Starting quest for team ${teamId}`);
            const response = await api.post(`/team/${teamId}/start`);
            
            if (response.data) {
                // Проверим еще раз наличие данных в localStorage перед переходом
                const savedData = localStorage.getItem(`questMode_${team.quest_id}`);
                if (!savedData || !JSON.parse(savedData).teamId) {
                    console.error("ОШИБКА: Данные о команде не сохранены в localStorage!");
                    
                    // Повторно сохраняем данные
                    localStorage.setItem(`questMode_${team.quest_id}`, JSON.stringify(questModeData));
                    console.log("Повторно сохранены данные о команде:", questModeData);
                }
                
                navigate(`/quest/${team.quest_id}/step/1/substep/0`);
            }
        } catch (error) {
            console.error('Error starting quest:', error);
            setError(error.response?.data?.detail || 'Failed to start quest');
        }
    };

    if (isLoading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <Loader2 className="w-8 h-8 animate-spin text-primary" />
            </div>
        );
    }

    if (!team) {
        return (
            <Card className="w-full max-w-md mx-auto">
                <CardContent className="p-6">
                    <Alert variant="destructive">
                        <AlertCircle className="h-4 w-4" />
                        <AlertDescription>Team not found</AlertDescription>
                    </Alert>
                    <Button className="mt-4 w-full" onClick={() => navigate('/')}>
                        Return to Home
                    </Button>
                </CardContent>
            </Card>
        );
    }

    const isLeader = currentUser && team.members.some(
        m => m.user_id === currentUser._id && m.is_leader
    );

    const currentMember = currentUser && team.members.find(
        m => m.user_id === currentUser._id
    );

    return (
        <Card className="w-full max-w-2xl mx-auto">
            <CardHeader>
                <CardTitle className="flex items-center gap-2">
                    <Users className="h-6 w-6" />
                    {team.name}
                </CardTitle>
                <CardDescription>
                    Waiting for team members to join and select roles
                </CardDescription>
            </CardHeader>

            <CardContent className="space-y-6">
                {error && (
                    <Alert variant="destructive">
                        <AlertCircle className="h-4 w-4" />
                        <AlertDescription>{error}</AlertDescription>
                    </Alert>
                )}

                <div className="bg-background/95 p-4 rounded-lg border">
                    <div className="text-sm text-muted-foreground mb-2">Share this team code:</div>
                    <div className="flex items-center gap-2">
                        <code className="bg-background px-2 py-1 rounded text-lg font-mono">
                            {team.code}
                        </code>
                        <Button variant="ghost" size="sm" onClick={copyTeamCode} className="h-8 px-2">
                            {copied ? (
                                <CheckCircle className="h-4 w-4 text-green-500" />
                            ) : (
                                <Copy className="h-4 w-4" />
                            )}
                        </Button>
                    </div>
                </div>

                {currentMember && !currentMember.role && (
                    <div className="space-y-2">
                        <h3 className="text-sm font-medium">Select Your Role:</h3>
                        <div className="grid grid-cols-3 gap-2">
                            {['Gamer', 'Listener', 'Regular'].map((role) => {
                                const isDisabled = role === 'Gamer' &&
                                    team.members.filter(m => m.role === 'Gamer').length >= 2;

                                return (
                                    <Button
                                        key={role}
                                        variant={selectedRole === role ? 'default' : 'outline'}
                                        onClick={() => handleRoleSelect(role)}
                                        disabled={isUpdatingRole || isDisabled}
                                        className="flex items-center gap-2"
                                    >
                                        <Shield className="h-4 w-4" />
                                        {role}
                                    </Button>
                                );
                            })}
                        </div>
                    </div>
                )}

                <div className="space-y-4">
                    <div className="flex items-center justify-between">
                        <h3 className="font-semibold">
                            Team Members ({team.members.length}/{team.max_participants})
                        </h3>
                        <Clock className="h-5 w-5 text-muted-foreground" />
                    </div>

                    <div className="space-y-2">
                        {team.members.map((member) => (
                            <div
                                key={member.user_id}
                                className="flex items-center justify-between p-3 bg-background/50 rounded-lg"
                            >
                                <div className="flex items-center gap-2">
                                    {member.is_leader && (
                                        <Crown className="h-4 w-4 text-yellow-500" />
                                    )}
                                    <span className="font-medium">{member.username}</span>
                                </div>
                                <div className="flex items-center gap-2">
                                    {member.role ? (
                                        <span className={`text-sm font-medium ${
                                            member.role === 'Gamer' ? 'text-green-500' :
                                            member.role === 'Listener' ? 'text-blue-500' :
                                            'text-purple-500'
                                        }`}>
                                            {member.role}
                                        </span>
                                    ) : (
                                        <span className="text-sm text-gray-400">
                                            Selecting role...
                                        </span>
                                    )}
                                </div>
                            </div>
                        ))}

                        {[...Array(team.max_participants - team.members.length)].map((_, i) => (
                            <div
                                key={`empty-${i}`}
                                className="flex items-center justify-center p-3 bg-background/50 rounded-lg border-dashed border-2 border-muted"
                            >
                                <UserPlus className="h-5 w-5 text-muted-foreground" />
                            </div>
                        ))}
                    </div>
                </div>

                {isLeader && (
                    <Button
                        className="w-full"
                        onClick={handleStartQuest}
                        disabled={
                            team.members.length < team.max_participants ||
                            team.members.some(m => !m.role)
                        }
                    >
                        {team.members.length < team.max_participants ? (
                            <>
                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                Waiting for all members to join...
                            </>
                        ) : team.members.some(m => !m.role) ? (
                            <>
                                <Clock className="mr-2 h-4 w-4" />
                                Waiting for role selection...
                            </>
                        ) : (
                            <>
                                <Play className="mr-2 h-4 w-4" />
                                Start Quest
                            </>
                        )}
                    </Button>
                )}

                {!isLeader && (
                    <Alert>
                        <AlertDescription>
                            Waiting for team leader to start the quest...
                        </AlertDescription>
                    </Alert>
                )}

                <Alert className="bg-primary/10 border-primary/20">
                    <AlertDescription className="space-y-2">
                        <h4 className="font-medium">Role Limits:</h4>
                        <ul className="list-disc list-inside space-y-1 text-sm">
                            <li>Maximum 2 Gamers per team</li>
                            <li>No limit for Listeners</li>
                            <li>No limit for Regular members</li>
                        </ul>
                    </AlertDescription>
                </Alert>
            </CardContent>
        </Card>
    );
};

export default TeamWaitingRoom;
