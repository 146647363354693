import { useEffect, useRef, useCallback, useState } from 'react';
import log from 'loglevel';

log.setLevel('DEBUG');

const useSocket = (id, isTeam = false, teamId = null) => {
  const socketRef = useRef(null);
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState(null);
  const listenersRef = useRef(new Map());
  const reconnectAttemptsRef = useRef(0);
  const maxReconnectAttempts = 5;
  const reconnectDelay = 1000;

  // Отладка входных данных
  useEffect(() => {
    console.log("useSocket инициализирован с параметрами:", { id, isTeam, teamId });
    if (isTeam && !teamId) {
      console.warn("ПРЕДУПРЕЖДЕНИЕ: isTeam=true, но teamId отсутствует в useSocket");
    }
  }, [id, isTeam, teamId]);

  const getWebSocketUrl = useCallback(() => {
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const baseUrl = `${protocol}//${window.location.host}/ws`;
    
    // Если это командный режим и передан teamId, включаем его в URL
    if (isTeam && teamId) {
      console.log(`Формирование URL для командного режима: ${baseUrl}/${id}?team_id=${teamId}`);
      return `${baseUrl}/${id}?team_id=${teamId}`;
    }
    
    // Простой URL для индивидуального режима
    console.log(`Формирование URL для индивидуального режима: ${baseUrl}/${id}`);
    return `${baseUrl}/${id}`;
  }, [id, isTeam, teamId]);

  const createWebSocket = useCallback(() => {
    if (!id || id === 'null' || id === 'undefined') {
      console.error("Невозможно создать WebSocket: id отсутствует или некорректный");
      return null; // Явно возвращаем null вместо попытки создания
    }
    
    try {
      // Проверяем корректность параметров для команды
      if (isTeam && !teamId) {
        console.warn("Создание WebSocket для команды, но teamId отсутствует!");
      }
      
      const wsUrl = getWebSocketUrl();
      console.log(`Создание WebSocket соединения по адресу: ${wsUrl}`);
      
      const ws = new WebSocket(wsUrl);
      
      ws.onopen = () => {
        log.info(`WebSocket connected for ${isTeam ? 'team' : 'quest'} ${id}, teamId: ${teamId || 'N/A'}`);
        setIsConnected(true);
        setError(null);
        reconnectAttemptsRef.current = 0;
        
        // Отправляем информацию о соединении с явным включением teamId
        const connectionInfo = {
          type: 'connect',
          data: {
            id: id,
            isTeam: isTeam,
            teamId: teamId, // Всегда включаем teamId, даже если null
            timestamp: new Date().toISOString()
          }
        };
        
        console.log("Отправка данных инициализации соединения:", connectionInfo);
        ws.send(JSON.stringify(connectionInfo));
      };

      ws.onclose = (event) => {
        log.info(`WebSocket closed for ${isTeam ? 'team' : 'quest'} ${id}, teamId: ${teamId || 'N/A'}`, event);
        setIsConnected(false);
        if (!event.wasClean && reconnectAttemptsRef.current < maxReconnectAttempts) {
          const delay = Math.min(reconnectDelay * Math.pow(2, reconnectAttemptsRef.current), 30000);
          reconnectAttemptsRef.current++;
          log.info(`Attempting to reconnect in ${delay}ms (${reconnectAttemptsRef.current}/${maxReconnectAttempts})`);
          setTimeout(createWebSocket, delay);
        }
      };

      ws.onerror = (err) => {
        log.error(`WebSocket error for ${isTeam ? 'team' : 'quest'} ${id}, teamId: ${teamId || 'N/A'}:`, err);
        setError(err);
      };

      ws.onmessage = (event) => {
        try {
          const message = JSON.parse(event.data);
          log.debug(`Received WebSocket message: Type=${message.type}`, message.data);
          
          if (message.type === 'heartbeat') {
            ws.send(JSON.stringify({
              type: 'heartbeat_ack',
              timestamp: new Date().toISOString()
            }));
            return;
          }
          
          // Проверяем, есть ли обработчики для данного типа событий
          if (listenersRef.current.has(message.type)) {
            log.debug(`Dispatching message to ${listenersRef.current.get(message.type).size} listeners for event: ${message.type}`);
            listenersRef.current.get(message.type).forEach(callback => {
              try {
                callback(message.data);
              } catch (error) {
                log.error(`Error in message handler for ${message.type}:`, error);
              }
            });
          } else {
            log.debug(`No listeners registered for event type: ${message.type}`);
          }
          
          // Специальная обработка для событий с изменившимся типом
          // team_state_update может прийти вместо state_update в командном режиме
          if (isTeam && message.type === 'team_state_update' && listenersRef.current.has('state_update')) {
            log.debug(`Cross-dispatching team_state_update to state_update listeners`);
            listenersRef.current.get('state_update').forEach(callback => {
              try {
                callback(message.data);
              } catch (error) {
                log.error(`Error in cross-dispatch handler for state_update:`, error);
              }
            });
          }
          
          // И наоборот - state_update может прийти вместо team_state_update
          if (isTeam && message.type === 'state_update' && listenersRef.current.has('team_state_update')) {
            log.debug(`Cross-dispatching state_update to team_state_update listeners`);
            listenersRef.current.get('team_state_update').forEach(callback => {
              try {
                callback(message.data);
              } catch (error) {
                log.error(`Error in cross-dispatch handler for team_state_update:`, error);
              }
            });
          }
        } catch (error) {
          log.error('Error processing WebSocket message:', error);
        }
      };

      socketRef.current = ws;
      return ws;
    } catch (error) {
      log.error('Error creating WebSocket:', error);
      setError(error);
      return null;
    }
  }, [id, isTeam, teamId, getWebSocketUrl, maxReconnectAttempts, reconnectDelay]);

  const forceReconnect = useCallback(() => {
    log.info(`Force reconnecting WebSocket for ${isTeam ? 'team' : 'quest'} ${id}, teamId: ${teamId || 'N/A'}`);
    if (socketRef.current) {
      socketRef.current.close();
    }
    createWebSocket();
  }, [createWebSocket, id, isTeam, teamId]);

  useEffect(() => {
    log.debug(`Setting up WebSocket connection for ${isTeam ? 'team' : 'quest'} ${id}, teamId: ${teamId || 'N/A'}`);
    
    // Добавляем проверку на валидность id
    if (id && id !== 'null' && id !== 'undefined') {
      createWebSocket();
    } else {
      log.debug('Skipping WebSocket connection due to invalid id');
    }
    
    return () => {
      log.debug(`Cleaning up WebSocket connection for ${isTeam ? 'team' : 'quest'} ${id}, teamId: ${teamId || 'N/A'}`);
      if (socketRef.current) {
        try {
          if (socketRef.current.readyState === WebSocket.OPEN) {
            const disconnectInfo = {
              type: 'disconnect',
              data: {
                id: id,
                isTeam: isTeam,
                teamId: teamId, // Всегда включаем teamId, даже если null
                timestamp: new Date().toISOString()
              }
            };
            socketRef.current.send(JSON.stringify(disconnectInfo));
          }
        } catch (e) {
          log.warn('Error sending disconnect message:', e);
        }
        socketRef.current.close();
        setIsConnected(false);
      }
    };
  }, [id, isTeam, teamId, createWebSocket]);

  const sendMessage = useCallback((message) => {
    if (!socketRef.current || socketRef.current.readyState !== WebSocket.OPEN) {
      log.warn('WebSocket is not connected, message not sent:', message);
      return;
    }
    
    try {
      // Всегда инициализируем data если его нет
      if (!message.data) message.data = {};
      
      // Для режима команды всегда добавляем teamId
      if (isTeam && teamId) {
        message.data.teamId = teamId;
        message.data.team_id = teamId; // Дублируем в snake_case формате
      }
      
      // Если это обновление состояния для команды, но тип не правильный, исправляем
      if (isTeam && message.type === 'state_update') {
        console.log('Преобразуем state_update в team_state_update для командного режима');
        message.type = 'team_state_update';
      }
      
      // Добавляем id, isTeam и teamId в отправляемые сообщения
      const enrichedMessage = {
        ...message,
        id: id,
        isTeam: isTeam,
        // Гарантируем включение teamId в сообщение если он есть
        teamId: teamId || message.data?.teamId,
        timestamp: message.timestamp || new Date().toISOString()
      };
      
      // Финальная проверка корректности для команды
      if (isTeam && !enrichedMessage.teamId) {
        console.error("ОШИБКА: Отправка сообщения для команды без teamId:", enrichedMessage);
        // Добавляем teamId если он известен
        if (teamId) {
          enrichedMessage.teamId = teamId;
          if (enrichedMessage.data) {
            enrichedMessage.data.teamId = teamId;
            enrichedMessage.data.team_id = teamId;
          }
        }
      }
      
      console.log('Отправка сообщения через WebSocket:', enrichedMessage);
      socketRef.current.send(JSON.stringify(enrichedMessage));
    } catch (error) {
      log.error('Error sending WebSocket message:', error);
    }
  }, [id, isTeam, teamId]);

  const addEventListener = useCallback((eventType, callback) => {
    if (!listenersRef.current.has(eventType)) {
      listenersRef.current.set(eventType, new Set());
    }
    listenersRef.current.get(eventType).add(callback);
    log.debug(`Added listener for WebSocket event: ${eventType}. Total listeners: ${listenersRef.current.get(eventType).size}`);
    
    // Возвращаем функцию для удаления обработчика
    return () => {
      removeEventListener(eventType, callback);
    };
  }, []);

  const removeEventListener = useCallback((eventType, callback) => {
    if (listenersRef.current.has(eventType)) {
      listenersRef.current.get(eventType).delete(callback);
      if (listenersRef.current.get(eventType).size === 0) {
        listenersRef.current.delete(eventType);
        log.debug(`Removed last listener for WebSocket event: ${eventType}`);
      } else {
        log.debug(`Removed listener for WebSocket event: ${eventType}. Remaining listeners: ${listenersRef.current.get(eventType).size}`);
      }
    }
  }, []);

  return {
    isConnected,
    error,
    send: sendMessage,
    addEventListener,
    removeEventListener,
    reconnect: forceReconnect,
    reconnectAttempts: reconnectAttemptsRef.current
  };
};

export default useSocket;
