import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from './ui/button';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Alert, AlertDescription } from './ui/alert';
import {
  CheckCircle,
  Loader2,
  Volume2,
  RefreshCcw,
  Lightbulb,
  ListRestart
} from 'lucide-react';
import useAudioStore from '../hooks/useAudioStore';
import api from '../api';
import { formatText, hasRussianText } from '../utils/textFormatting';

const SequenceStage = ({
  character,
  text,
  audioFile,
  questId,
  sequenceItems = [],
  correctSequence = [],
  onNext,
  showHint,
  onHint,
  hint,
  isTeam = false,
  teamId = null,
  role = '',
  isExiting = false,
  preloadedData = null,
  currentStepNum,
  currentSubStepNum
}) => {
  // Состояние для выбора порядка элементов кликами
  const [selectedOrder, setSelectedOrder] = useState({});
  
  const [resultMessage, setResultMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hintText, setHintText] = useState('');
  const [localError, setLocalError] = useState(null);
  const [imageLoadErrors, setImageLoadErrors] = useState({});
  
  // Определяем, содержит ли текст русские символы
  const isRussianText = hasRussianText(text);
  const formattedText = formatText(text, isRussianText);
  const isRussianCharacter = hasRussianText(character);
  
  const mountedRef = useRef(true);

  const {
    isPlaying,
    progress,
    isLoading: audioLoading,
    error: audioError,
    initSound,
    togglePlayPause,
    setVolume,
    cleanup,
    preloadSound
  } = useAudioStore();

  // Предзагрузка следующего аудио при необходимости
  useEffect(() => {
    if (preloadedData && preloadedData.audioFile && questId) {
      const nextAudioUrl = `/audio/quests/${questId}/${preloadedData.audioFile}`;
      try {
        preloadSound(nextAudioUrl);
      } catch (error) {
        console.debug('Error preloading next audio:', error);
      }
    }
  }, [preloadedData, questId, preloadSound]);

  // Инициализация
  useEffect(() => {
    mountedRef.current = true;
    
    if (audioFile && questId) {
      const audioUrl = `/audio/quests/${questId}/${audioFile}`;
      try {
        initSound(audioUrl, {});
      } catch (error) {
        console.error('Error initializing audio:', error);
        if (mountedRef.current) {
          setLocalError('Failed to load audio file');
        }
      }
    }
    
    return () => {
      mountedRef.current = false;
      cleanup();
    };
  }, [audioFile, questId, initSound, cleanup]);

  // Функция для получения элемента последовательности по ID
  const getSequenceItemById = useCallback((itemId) => {
    if (!sequenceItems || !itemId) return null;
    return sequenceItems.find(item => item.id === itemId);
  }, [sequenceItems]);

  // Функция формирования пути к изображению
  const getSequenceImagePath = useCallback((itemId) => {
    if (!questId || !itemId) return null;
    const item = getSequenceItemById(itemId);
    
    // Проверяем, что элемент существует и у него есть свойство image
    if (!item || !item.image) {
      return null;
    }
    
    // Формируем правильный URL
    return `/sequence-images/${questId}/${item.image}`;
  }, [questId, getSequenceItemById]);

  // Обработчик клика на элемент последовательности
  const handleSequenceItemClick = useCallback((itemId) => {
    setSelectedOrder(prev => {
      const newOrder = {...prev};
      
      // Если элемент уже выбран, удаляем его
      if (newOrder[itemId]) {
        delete newOrder[itemId];
        
        // Перенумеруем оставшиеся элементы, чтобы убрать пропуски
        const items = Object.entries(newOrder)
          .sort((a, b) => a[1] - b[1])
          .map(([id]) => id);
        
        const result = items.reduce((acc, id, index) => {
          acc[id] = index + 1;
          return acc;
        }, {});
        
        return result;
      } else {
        // Добавляем элемент с следующим доступным номером
        const newNumber = Object.keys(newOrder).length + 1;
        newOrder[itemId] = newNumber;
        return newOrder;
      }
    });
  }, []);

  // Функция получения последовательности из выбранных элементов
  const getSequenceFromSelection = useCallback(() => {
    return Object.entries(selectedOrder)
      .sort((a, b) => a[1] - b[1])
      .map(([id]) => id);
  }, [selectedOrder]);

  // Сброс выбора элементов
  const resetSelection = useCallback(() => {
    setSelectedOrder({});
    setResultMessage('');
  }, []);

  // Обработчик показа подсказки
  const handleHintClick = useCallback(() => {
    setHintText(hint);
    if (onHint) {
      onHint();
    }
  }, [hint, onHint]);

  // Метод для обработки ошибок загрузки изображений
  const handleImageError = useCallback((itemId) => {
    console.error(`Failed to load image for item: ${itemId}`);
    setImageLoadErrors(prev => ({
      ...prev,
      [itemId]: true
    }));
  }, []);

  // Обработчик отправки
  const handleSubmit = useCallback(async () => {
    if (isSubmitting || !questId) return;
    
    // Получаем последовательность из выбранных элементов
    const submittedSequence = getSequenceFromSelection();
    
    // Проверяем, что все элементы выбраны
    if (submittedSequence.length !== sequenceItems.length) {
      setResultMessage("Please select all items in the sequence");
      return;
    }
    
    setIsSubmitting(true);
    setResultMessage('');
    
    try {
      const formData = new FormData();
      formData.append('quest_id', questId);
      formData.append('point_index', currentStepNum - 1);
      formData.append('step_index', currentSubStepNum);
      
      // Добавляем каждый элемент последовательности как отдельное поле
      submittedSequence.forEach(itemId => {
        formData.append('sequence_order', itemId);
      });
      
      if (isTeam && teamId) {
        formData.append('team_id', teamId);
      }
      
      const response = await api.post('/verify-sequence/', formData);
      
      if (response.data.success) {
        setResultMessage('Correct sequence!');
        
        // Задержка перед переходом к следующему этапу
        setTimeout(() => {
          if (mountedRef.current) {
            onNext();
          }
        }, 1500);
      } else {
        setResultMessage(response.data.message || 'Incorrect sequence. Try again.');
        // Сбрасываем выбор при неправильном ответе
        setSelectedOrder({});
      }
    } catch (error) {
      console.error('Error submitting sequence:', error);
      setLocalError(error.response?.data?.message || 'Error verifying sequence');
    } finally {
      if (mountedRef.current) {
        setIsSubmitting(false);
      }
    }
  }, [
    isSubmitting,
    questId,
    currentStepNum,
    currentSubStepNum,
    getSequenceFromSelection,
    sequenceItems.length,
    isTeam,
    teamId,
    onNext,
    mountedRef
  ]);

  return (
    <Card>
      <CardHeader>
        <CardTitle className={`text-xl md:text-2xl text-primary ${isRussianCharacter ? 'font-serif' : 'font-sans'}`}>
          {character}
          {isTeam && (
            <span className="text-sm ml-2 text-muted-foreground">
              ({role || 'No Role'})
            </span>
          )}
        </CardTitle>
      </CardHeader>

      <CardContent className="space-y-6">
        {/* Управление аудио */}
        {audioFile && (
          <div className="space-y-4">
            <Button
              onClick={togglePlayPause}
              disabled={audioLoading}
              className="w-full flex items-center justify-center space-x-2"
              variant="outline"
            >
              {audioLoading ? (
                <Loader2 className="h-5 w-5 animate-spin" />
              ) : isPlaying ? (
                <>
                  <RefreshCcw className="h-5 w-5" />
                  <span>Pause Audio</span>
                </>
              ) : (
                <>
                  <Volume2 className="h-5 w-5" />
                  <span>Play Audio</span>
                </>
              )}
            </Button>
            {!audioError && (
              <div className="space-y-2">
                <div className="w-full bg-gray-200 rounded-full h-2">
                  <div
                    className="bg-primary rounded-full h-full"
                    style={{ width: `${progress}%` }}
                  />
                </div>
                <div className="flex items-center justify-between text-sm text-muted-foreground">
                  <span>Progress: {Math.round(progress)}%</span>
                  <div className="flex items-center gap-2">
                    <Volume2 className="h-4 w-4" />
                    <input
                      type="range"
                      min="0"
                      max="100"
                      defaultValue="100"
                      className="w-24"
                      onChange={(e) => setVolume(e.target.value / 100)}
                    />
                  </div>
                </div>
              </div>
            )}
            {audioError && (
              <Alert variant="destructive">
                <AlertDescription>{audioError}</AlertDescription>
              </Alert>
            )}
          </div>
        )}

        {/* Улучшенное отображение текста с поддержкой русского языка и выравниванием по левому краю */}
        <div className={`prose max-w-none text-content ${isRussianText ? 'text-ru' : ''}`}>
          {formattedText.map((item, index) => {
            if (item.type === 'heading1') {
              return <h1 key={index} className={`${item.class} text-left`}>{item.content}</h1>;
            }
            if (item.type === 'heading2') {
              return <h2 key={index} className={`${item.class} text-left`}>{item.content}</h2>;
            }
            if (item.type === 'quote') {
              return <blockquote key={index} className={`${item.class} text-left`}>{item.content}</blockquote>;
            }
            return <p key={index} className={`${item.class} text-left`}>{item.content}</p>;
          })}
        </div>

        {/* Инструкция */}
        <Alert className="bg-blue-50 text-blue-800 border-blue-200">
          <AlertDescription className={isRussianText ? 'text-ru no-indent text-left' : 'text-left'}>
            {isRussianText 
              ? "Выберите изображения в правильном порядке. Нажмите на изображение, чтобы выбрать или отменить выбор."
              : "Click on the images to select the correct sequence order. Click again to remove from the sequence."}
          </AlertDescription>
        </Alert>

        {/* Элементы последовательности */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
          {sequenceItems.map((item) => {
            const itemId = item.id;
            const selectionNumber = selectedOrder[itemId];
            const imagePath = getSequenceImagePath(itemId);
            const hasImageError = imageLoadErrors[itemId];
            const isRussianItem = hasRussianText(item.text);
            
            return (
              <div 
                key={itemId} 
                className={`border rounded-lg p-3 bg-background shadow-sm relative cursor-pointer hover:border-primary ${
                  selectionNumber ? 'border-primary ring-1 ring-primary/20' : ''
                }`}
                onClick={() => handleSequenceItemClick(itemId)}
              >
                {/* Номер выбора (если есть) */}
                {selectionNumber !== undefined && (
                  <div className="absolute top-2 right-2 z-10 h-8 w-8 bg-primary text-white rounded-full flex items-center justify-center font-bold shadow-md">
                    {selectionNumber}
                  </div>
                )}
                
                <div className="flex flex-col">
                  {imagePath && !hasImageError && (
                    <div className="mb-2 relative">
                      <img 
                        src={imagePath}
                        alt={item.text}
                        className={`w-full h-40 object-cover rounded-md ${
                          selectionNumber ? 'ring-2 ring-primary' : ''
                        }`}
                        onError={(e) => {
                          handleImageError(itemId);
                          e.target.style.display = 'none';
                        }}
                      />
                    </div>
                  )}
                  <p className={`text-sm font-medium text-left ${isRussianItem ? 'font-russian' : ''}`}>
                    {item.text}
                  </p>
                  
                  {/* Отображение сообщения об ошибке загрузки изображения */}
                  {hasImageError && imagePath && (
                    <p className="text-xs text-red-500 mt-1 text-left">Failed to load image</p>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        {/* Кнопки управления */}
        <div className="flex flex-col sm:flex-row gap-4">
          {/* Кнопка для сброса выбора */}
          {Object.keys(selectedOrder).length > 0 && (
            <Button 
              variant="outline" 
              onClick={resetSelection}
              className="flex-1"
            >
              <ListRestart className="h-4 w-4 mr-2" />
              Reset Selection
            </Button>
          )}

          {/* Кнопка отправки */}
          <Button
            onClick={handleSubmit}
            disabled={Object.keys(selectedOrder).length !== sequenceItems.length || isSubmitting}
            className="flex-1"
          >
            {isSubmitting ? (
              <>
                <Loader2 className="h-5 w-5 mr-2 animate-spin" />
                Checking...
              </>
            ) : (
              <>
                <CheckCircle className="h-5 w-5 mr-2" />
                Submit Sequence
              </>
            )}
          </Button>

          {/* Кнопка подсказки */}
          {showHint && (
            <Button
              variant="outline"
              onClick={handleHintClick}
              disabled={isSubmitting || hintText !== ''}
              className="flex-1"
            >
              <Lightbulb className="h-5 w-5 mr-2" />
              Get Hint (-10 points)
            </Button>
          )}
        </div>

        {/* Показ подсказки */}
        {hintText && (
          <Alert>
            <AlertDescription className={hasRussianText(hintText) ? 'text-ru text-left' : 'text-left'}>
              {hintText}
            </AlertDescription>
          </Alert>
        )}

        {/* Сообщения */}
        {resultMessage && (
          <Alert variant={resultMessage.includes('Correct') ? 'default' : 'destructive'}>
            <AlertDescription className={hasRussianText(resultMessage) ? 'text-ru text-left' : 'text-left'}>
              {resultMessage}
            </AlertDescription>
          </Alert>
        )}

        {localError && (
          <Alert variant="destructive">
            <AlertDescription className={hasRussianText(localError) ? 'text-ru text-left' : 'text-left'}>
              {localError}
            </AlertDescription>
          </Alert>
        )}
      </CardContent>
    </Card>
  );
};

SequenceStage.propTypes = {
  character: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  audioFile: PropTypes.string,
  questId: PropTypes.string.isRequired,
  sequenceItems: PropTypes.array,
  correctSequence: PropTypes.array,
  onNext: PropTypes.func.isRequired,
  showHint: PropTypes.bool,
  onHint: PropTypes.func,
  hint: PropTypes.string,
  isTeam: PropTypes.bool,
  teamId: PropTypes.string,
  role: PropTypes.string,
  isExiting: PropTypes.bool,
  preloadedData: PropTypes.object,
  currentStepNum: PropTypes.number.isRequired,
  currentSubStepNum: PropTypes.number.isRequired
};

export default SequenceStage;
