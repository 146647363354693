import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';
import { Label } from '../ui/label';
import { Alert, AlertDescription } from '../ui/alert';
import { FileUpload } from '../ui/fileUpload';
import { Progress } from '../ui/progress';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../ui/select';
import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from '../ui/tooltip';
import { 
    Minus, 
    Upload, 
    AlertCircle, 
    Music,
    PlayCircle,
    Image as ImageIcon,
    X,
    Save,
    CheckCircle,
    Loader2,
    Info,
    MessageSquare
} from 'lucide-react';
import OptionEditor from './OptionEditor';
import SequenceEditor from './SequenceEditor';

const StepEditor = ({ 
    step, 
    index,
    questId,
    pointIndex,
    onRemove, 
    onChange,
    onFileUpload,
    onOptionImageUpload,
    onSequenceImageUpload,
    onStepDataChange,
    validationErrors = {}
}) => {
    // State for selected file
    const [selectedAudioFile, setSelectedAudioFile] = useState(null);
    const [selectedImageFile, setSelectedImageFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadError, setUploadError] = useState(null);
    const [isSavingTemplate, setIsSavingTemplate] = useState(false);
    const [templateSaved, setTemplateSaved] = useState(false);
    const [uploadResult, setUploadResult] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    
    // State for choice options and sequence items
    const [options, setOptions] = useState(step.options || []);
    const [correctOptions, setCorrectOptions] = useState(step.correctOptions || []);
    const [multipleSelect, setMultipleSelect] = useState(step.multipleSelect || false);
    const [sequenceItems, setSequenceItems] = useState(step.sequenceItems || []);
    const [correctSequence, setCorrectSequence] = useState(step.correctSequence || []);

    // Update options and sequence items when step changes
    useEffect(() => {
        setOptions(step.options || []);
        setCorrectOptions(step.correctOptions || []);
        setMultipleSelect(step.multipleSelect || false);
        setSequenceItems(step.sequenceItems || []);
        setCorrectSequence(step.correctSequence || []);
    }, [step]);

    // Update the parent component whenever local state changes
    useEffect(() => {
        onChange('options', options);
        onChange('correctOptions', correctOptions);
        onChange('multipleSelect', multipleSelect);
    }, [options, correctOptions, multipleSelect, onChange]);

    useEffect(() => {
        onChange('sequenceItems', sequenceItems);
        onChange('correctSequence', correctSequence);
    }, [sequenceItems, correctSequence, onChange]);

    // Separate file selection and upload
    const handleFileSelect = (file) => {
        console.log(`[StepEditor] File selected: ${file.name}, size: ${file.size}, type: ${file.type}`);
        setSelectedAudioFile(file);
        setUploadError(null);
        setUploadResult(null);
    };

    const handleImageFileSelect = (file) => {
        console.log(`[StepEditor] Image file selected: ${file.name}, size: ${file.size}, type: ${file.type}`);
        setSelectedImageFile(file);
        setUploadError(null);
        setUploadSuccess(false);
    };

    // Separate function to upload the selected file
    const handleAudioUpload = async (e) => {
        if (e) {
            e.preventDefault(); // Prevent form submission
        }
        
        if (!selectedAudioFile) {
            setUploadError("No file selected");
            return;
        }
        
        if (!questId) {
            setUploadError("Quest not saved yet. Save the quest first before uploading audio files.");
            return;
        }

        try {
            setIsUploading(true);
            setUploadError(null);
            setUploadProgress(0);
            setUploadResult(null);

            console.log('[StepEditor] Starting audio upload:', {
                questId, 
                pointIndex, 
                stepIndex: index, 
                fileName: selectedAudioFile.name,
                fileSize: selectedAudioFile.size,
                fileType: selectedAudioFile.type
            });

            // DIRECT FETCH approach bypassing the function call chain
            console.log('[StepEditor] Calling direct fetch upload to /api/admin/audio');
            try {
                // Create FormData
                const formData = new FormData();
                formData.append('quest_id', questId);
                formData.append('point_index', pointIndex);
                formData.append('step_index', index);
                formData.append('file', selectedAudioFile);
                
                // Log the FormData for debugging
                console.log('[StepEditor] FormData prepared:', {
                    quest_id: questId,
                    point_index: pointIndex,
                    step_index: index,
                    file: selectedAudioFile.name
                });
                
                // Direct fetch call
                const response = await fetch('/api/admin/audio', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                        // Do NOT set Content-Type, browser will set it with correct boundary
                    },
                    body: formData
                });
                
                // Check response status
                if (!response.ok) {
                    throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
                }
                
                // Parse response data
                const responseData = await response.json();
                console.log('[StepEditor] Upload success response:', responseData);
                
                // Update UI with success
                onChange('audioFile', responseData.filename || selectedAudioFile.name);
                setSelectedAudioFile(null);
                setUploadResult({
                    success: true,
                    message: 'Audio file uploaded successfully!',
                    ...responseData
                });
                
                // Clear success message after 5 seconds
                setTimeout(() => {
                    setUploadResult(null);
                }, 5000);
            } catch (fetchError) {
                console.error('[StepEditor] Direct fetch upload error:', fetchError);
                setUploadError(`Upload failed: ${fetchError.message}`);
            }
        } catch (error) {
            console.error('[StepEditor] Error uploading audio:', error);
            
            let errorMessage = error.message || "Failed to upload audio file";
            
            // Check for server response in error
            if (error.response) {
                errorMessage = `Server error: ${error.response.status} - ${error.response.data?.detail || error.response.statusText}`;
                console.error('[StepEditor] Server error response:', error.response);
            } else if (error.request) {
                errorMessage = "No response from server. Check your network connection.";
                console.error('[StepEditor] No response from server:', error.request);
            }
            
            setUploadError(errorMessage);
        } finally {
            setIsUploading(false);
            setUploadProgress(0);
        }
    };

    const handleAIChatImageUpload = async (e) => {
        if (e) {
            e.preventDefault();
        }
        
        if (!selectedImageFile) {
            setUploadError("No image file selected");
            return;
        }
        
        if (!questId) {
            setUploadError("Quest not saved yet. Save the quest first before uploading images.");
            return;
        }

        try {
            setIsUploading(true);
            setUploadError(null);
            setUploadProgress(0);
            
            console.log('[StepEditor] Starting AI Chat image upload:', {
                questId, 
                pointIndex, 
                stepIndex: index, 
                fileName: selectedImageFile.name,
                fileSize: selectedImageFile.size,
                fileType: selectedImageFile.type
            });
            
            const formData = new FormData();
            formData.append('quest_id', questId);
            formData.append('point_index', pointIndex);
            formData.append('step_index', index);
            formData.append('file', selectedImageFile);
            
            const response = await fetch('/api/admin/aichat-image', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: formData
            });
            
            if (!response.ok) {
                throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
            }
            
            const responseData = await response.json();
            console.log('[StepEditor] AI Chat image upload success:', responseData);
            
            // Update UI with success
            onChange('imageFile', responseData.filename || selectedImageFile.name);
            setSelectedImageFile(null);
            setUploadSuccess(true);
            
            // Clear success message after 5 seconds
            setTimeout(() => {
                setUploadSuccess(false);
            }, 5000);
            
        } catch (error) {
            console.error('[StepEditor] Error uploading AI Chat image:', error);
            setUploadError(`Upload failed: ${error.message}`);
        } finally {
            setIsUploading(false);
            setUploadProgress(0);
        }
    };

    const handleSaveTemplate = async (e) => {
        if (e) {
            e.preventDefault(); // Prevent form submission
        }
        
        try {
            setIsSavingTemplate(true);
            setUploadError(null);
            
            // Check if template text is filled
            if (!step.templateText || !step.templateText.trim()) {
                throw new Error("Template text is required");
            }
            
            if (!questId) {
                throw new Error("Quest not saved yet. Save the quest first before saving templates.");
            }
            
            // Check if similarity threshold is set
            if (!step.similarityThreshold) {
                throw new Error("Similarity threshold is required");
            }

            console.log('[StepEditor] Saving template:', {
                questId,
                pointIndex,
                stepIndex: index,
                templateText: step.templateText,
                similarityThreshold: step.similarityThreshold
            });

            // For template, we only pass text and similarity threshold
            const templateData = {
                templateText: step.templateText,
                similarityThreshold: step.similarityThreshold
            };
            
            // Try direct fetch approach for template too
            try {
                const formData = new FormData();
                formData.append('quest_id', questId);
                formData.append('point_index', pointIndex);
                formData.append('step_index', index);
                formData.append('template_text', step.templateText);
                formData.append('similarity_threshold', step.similarityThreshold);
                
                const response = await fetch('/api/admin/save-template', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    body: formData
                });
                
                if (!response.ok) {
                    throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
                }
                
                const result = await response.json();
                console.log('[StepEditor] Template save success response:', result);
                
                setTemplateSaved(true);
                setTimeout(() => setTemplateSaved(false), 3000);
            } catch (fetchError) {
                console.error('[StepEditor] Direct fetch template save error:', fetchError);
                throw fetchError;
            }
        } catch (error) {
            console.error('[StepEditor] Error saving template:', error);
            setUploadError(error.message || "Failed to save template");
        } finally {
            setIsSavingTemplate(false);
        }
    };

    const handleRemoveAudio = () => {
        onChange('audioFile', '');
        setUploadResult(null);
    };

    const handleRemoveImage = () => {
        onChange('imageFile', '');
        setUploadSuccess(false);
    };

    // Handle option image upload
    const handleOptionImageUpload = async (optionId, file) => {
        if (!questId) {
            setUploadError("Quest not saved yet. Save the quest first before uploading images.");
            return null;
        }

        try {
            // If we have a custom function provided for uploading option images, use it
            if (onOptionImageUpload) {
                return await onOptionImageUpload(questId, pointIndex, index, optionId, file);
            }

            // Otherwise use direct fetch
            const formData = new FormData();
            formData.append('quest_id', questId);
            formData.append('point_index', pointIndex);
            formData.append('step_index', index);
            formData.append('option_id', optionId);
            formData.append('file', file);
            
            const response = await fetch('/api/admin/option-image', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: formData
            });
            
            if (!response.ok) {
                throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
            }
            
            const responseData = await response.json();
            console.log('[StepEditor] Option image upload success:', responseData);
            
            return responseData;
        } catch (error) {
            console.error('[StepEditor] Error uploading option image:', error);
            throw error;
        }
    };

    // Handle sequence item image upload - исправлено для лучшей обработки ответа сервера
    const handleSequenceImageUpload = async (itemId, file) => {
        if (!questId) {
            setUploadError("Quest not saved yet. Save the quest first before uploading images.");
            return null;
        }

        try {
            console.log('[StepEditor] Starting sequence image upload:', {
                questId,
                pointIndex,
                stepIndex: index,
                itemId,
                fileName: file.name
            });

            // If we have a custom function provided for uploading sequence images, use it
            if (onSequenceImageUpload) {
                const result = await onSequenceImageUpload(questId, pointIndex, index, itemId, file);
                console.log('[StepEditor] Sequence image upload result:', result);
                
                // Обновляем локальное состояние после успешной загрузки
                if (result && result.filename) {
                    // Найти элемент последовательности и обновить его изображение
                    const updatedItems = sequenceItems.map(item => 
                        item.id === itemId 
                            ? { ...item, image: result.filename } 
                            : item
                    );
                    setSequenceItems(updatedItems);
                    
                    // Если используется onStepDataChange, вызвать его тоже
                    if (onStepDataChange) {
                        onStepDataChange(pointIndex, index, {
                            sequenceItems: updatedItems,
                            correctSequence
                        });
                    }
                }
                return result;
            }

            // Otherwise use direct fetch
            const formData = new FormData();
            formData.append('quest_id', questId);
            formData.append('point_index', pointIndex);
            formData.append('step_index', index);
            formData.append('item_id', itemId);
            formData.append('file', file);
            
            const response = await fetch('/api/admin/sequence-image', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: formData
            });
            
            if (!response.ok) {
                throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
            }
            
            const responseData = await response.json();
            console.log('[StepEditor] Sequence image upload success:', responseData);

            // Обновляем локальное состояние после успешной загрузки через прямой fetch
            if (responseData && responseData.filename) {
                const updatedItems = sequenceItems.map(item => 
                    item.id === itemId 
                        ? { ...item, image: responseData.filename } 
                        : item
                );
                setSequenceItems(updatedItems);
            }
            
            return responseData;
        } catch (error) {
            console.error('[StepEditor] Error uploading sequence image:', error);
            throw error;
        }
    };

    // Handle choice options and sequence changes
    const handleChoiceUpdate = (data) => {
        setOptions(data.options);
        setCorrectOptions(data.correctOptions);
        setMultipleSelect(data.multipleSelect);
    };
    
    const handleSequenceUpdate = (data) => {
        setSequenceItems(data.sequenceItems);
        setCorrectSequence(data.correctSequence);
    };

    const getErrorId = (field) => `point_${pointIndex}_step_${index}_${field}`;

    return (
        <Card className="relative">
            <CardContent className="pt-4 space-y-4">
                <div className="flex items-center justify-between">
                    <div className="flex-1 mr-4">
                        <Label>Step Type</Label>
                        <Select
                            value={step.type}
                            onValueChange={value => onChange('type', value)}
                        >
                            <SelectTrigger>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="speech">Speech</SelectItem>
                                <SelectItem value="riddle">Riddle</SelectItem>
                                <SelectItem value="image">Image</SelectItem>
                                <SelectItem value="choice">Multiple Choice</SelectItem>
                                <SelectItem value="sequence">Sequence Ordering</SelectItem>
                                <SelectItem value="aichat">AI Chat</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <Button
                                type="button"
                                variant="ghost"
                                size="sm"
                                onClick={onRemove}
                                className="h-8 w-8 text-red-500 hover:text-red-700 hover:bg-red-100"
                            >
                                <Minus className="h-4 w-4" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent>Remove step</TooltipContent>
                    </Tooltip>
                </div>

                <div className="space-y-2">
                    <Label>Text</Label>
                    <Textarea
                        value={step.text}
                        onChange={e => onChange('text', e.target.value)}
                        placeholder={`Enter ${step.type} text`}
                        rows={3}
                        error={validationErrors[getErrorId('text')]}
                    />
                    {validationErrors[getErrorId('text')] && (
                        <p className="text-sm text-red-500">
                            {validationErrors[getErrorId('text')]}
                        </p>
                    )}
                </div>

                {step.type === 'riddle' && (
                    <div className="space-y-2">
                        <Label>Correct Answer</Label>
                        <Input
                            value={step.correctAnswer || ''}
                            onChange={e => onChange('correctAnswer', e.target.value)}
                            placeholder="Enter correct answer (separate multiple answers with |)"
                            error={validationErrors[getErrorId('answer')]}
                        />
                        {validationErrors[getErrorId('answer')] && (
                            <p className="text-sm text-red-500">
                                {validationErrors[getErrorId('answer')]}
                            </p>
                        )}
                        <p className="text-xs text-muted-foreground">
                            Use | to separate multiple answers, e.g. "answer1|answer2"
                        </p>
                    </div>
                )}

                {step.type === 'choice' && (
                    <OptionEditor
                        options={options}
                        correctOptions={correctOptions}
                        multipleSelect={multipleSelect}
                        onChange={handleChoiceUpdate}
                        onImageUpload={handleOptionImageUpload}
                        questId={questId}
                        pointIndex={pointIndex}
                        stepIndex={index}
                        validationErrors={validationErrors}
                    />
                )}

                {step.type === 'sequence' && (
                    <SequenceEditor
                        sequenceItems={sequenceItems}
                        correctSequence={correctSequence}
                        onChange={handleSequenceUpdate}
                        onImageUpload={handleSequenceImageUpload}
                        questId={questId}
                        pointIndex={pointIndex}
                        stepIndex={index}
                        validationErrors={validationErrors}
                    />
                )}

                {step.type === 'image' && (
                    <div className="space-y-4">
                        <div className="space-y-2">
                            <div className="flex justify-between items-center">
                                <Label>Template Text for Image Comparison <span className="text-red-500">*</span></Label>
                                {questId ? (
                                    <Button
                                        type="button"
                                        size="sm"
                                        onClick={handleSaveTemplate}
                                        disabled={!step.templateText || isSavingTemplate || !step.similarityThreshold}
                                    >
                                        {isSavingTemplate ? (
                                            <>
                                                <Loader2 className="h-4 w-4 animate-spin" />
                                                Saving...
                                            </>
                                        ) : (
                                            <>
                                                <Save className="h-4 w-4" />
                                                {templateSaved ? 'Saved!' : 'Save Template'}
                                            </>
                                        )}
                                    </Button>
                                ) : (
                                    <Tooltip>
                                        <TooltipTrigger>
                                            <div className="flex items-center text-amber-500">
                                                <Info className="h-4 w-4 mr-1" />
                                                <span className="text-xs">Save quest first</span>
                                            </div>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            Save the quest before adding templates
                                        </TooltipContent>
                                    </Tooltip>
                                )}
                            </div>
                            <Textarea
                                value={step.templateText || ''}
                                onChange={e => onChange('templateText', e.target.value)}
                                placeholder="Enter text that describes what should be in the image"
                                rows={3}
                                error={validationErrors[getErrorId('template')]}
                            />
                            {validationErrors[getErrorId('template')] && (
                                <p className="text-sm text-red-500">
                                    {validationErrors[getErrorId('template')]}
                                </p>
                            )}
                            <p className="text-xs text-muted-foreground">
                                This text will be used to compare uploaded images. Describe what the image should contain.
                                <br />
                                <strong>Make sure to save the template after entering or changing this text!</strong>
                            </p>
                        </div>
                        
                        <div className="space-y-2">
                            <Label>Similarity Threshold (%) <span className="text-red-500">*</span></Label>
                            <Input
                                type="number"
                                min="0"
                                max="100"
                                step="0.1"
                                value={step.similarityThreshold}
                                onChange={e => onChange('similarityThreshold', parseFloat(e.target.value))}
                                placeholder="Enter similarity threshold (0-100)"
                                required
                            />
                            {!step.similarityThreshold && (
                                <p className="text-sm text-amber-500">
                                    This field is required. Please set a threshold value.
                                </p>
                            )}
                            <p className="text-xs text-muted-foreground">
                                Images must match the template text with at least this percentage to be accepted
                            </p>
                        </div>
                    </div>
                )}

                {step.type === 'aichat' && (
                    <div className="space-y-4">
                        <div className="space-y-2">
                            <Label>AI Instructions <span className="text-red-500">*</span></Label>
                            <Textarea
                                value={step.aiInstructions || ''}
                                onChange={e => onChange('aiInstructions', e.target.value)}
                                placeholder="Enter instructions for the AI (e.g., role, behavior, response format)"
                                rows={4}
                                error={validationErrors[getErrorId('aiInstructions')]}
                            />
                            {validationErrors[getErrorId('aiInstructions')] && (
                                <p className="text-sm text-red-500">
                                    {validationErrors[getErrorId('aiInstructions')]}
                                </p>
                            )}
                            <p className="text-xs text-muted-foreground">
                                Detailed instructions for how the AI should respond. Example: "You are a historical figure. Answer questions about your life, but don't reveal your name. After the user asks [maxQuestions] questions, ask them to guess who you are."
                            </p>
                        </div>

                        <div className="space-y-2">
                            <Label>Max Questions</Label>
                            <Input
                                type="number"
                                min="1"
                                max="10"
                                value={step.maxQuestions || 3}
                                onChange={e => onChange('maxQuestions', parseInt(e.target.value))}
                                placeholder="Maximum number of questions (default: 3)"
                            />
                            <p className="text-xs text-muted-foreground">
                                Number of questions the user can ask before they must give a final answer
                            </p>
                        </div>

                        <div className="space-y-2">
                            <Label>Correct Answer(s) <span className="text-red-500">*</span></Label>
                            <Input
                                value={step.correctAnswer || ''}
                                onChange={e => onChange('correctAnswer', e.target.value)}
                                placeholder="Enter correct answers (separate multiple with |)"
                                error={validationErrors[getErrorId('correctAnswer')]}
                            />
                            {validationErrors[getErrorId('correctAnswer')] && (
                                <p className="text-sm text-red-500">
                                    {validationErrors[getErrorId('correctAnswer')]}
                                </p>
                            )}
                            <p className="text-xs text-muted-foreground">
                                Use | to separate multiple answers, e.g. "answer1|answer2"
                            </p>
                        </div>

                        <div className="space-y-2">
                            <Label>AI Chat Image (Optional)</Label>
                            
                            {!step.imageFile ? (
                                <div className="space-y-4">
                                    <FileUpload
                                        accept={{ 'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.webp'] }}
                                        maxSize={5242880} // 5MB
                                        onUpload={handleImageFileSelect}
                                        value={selectedImageFile}
                                        loading={isUploading}
                                        progress={uploadProgress}
                                    >
                                        <div className="flex items-center space-x-2">
                                            <ImageIcon className="h-4 w-4" />
                                            <span>Select Image File</span>
                                        </div>
                                    </FileUpload>
                                    
                                    {selectedImageFile && (
                                        <div className="p-3 bg-muted rounded-lg flex items-center justify-between">
                                            <div className="flex items-center space-x-2">
                                                <ImageIcon className="h-4 w-4 text-muted-foreground" />
                                                <span className="text-sm font-medium">{selectedImageFile.name}</span>
                                                <span className="text-xs text-muted-foreground">
                                                    ({(selectedImageFile.size / 1024 / 1024).toFixed(2)} MB)
                                                </span>
                                            </div>
                                            <Button
                                                type="button"
                                                size="sm"
                                                variant="ghost"
                                                onClick={() => setSelectedImageFile(null)}
                                                className="text-red-500 hover:text-red-700"
                                            >
                                                <X className="h-4 w-4" />
                                            </Button>
                                        </div>
                                    )}
                                    
                                    {selectedImageFile && (
                                        <Button
                                            type="button"
                                            onClick={handleAIChatImageUpload}
                                            disabled={isUploading || !questId}
                                            className="w-full"
                                        >
                                            {isUploading ? (
                                                <>
                                                    <Loader2 className="h-4 w-4 animate-spin" />
                                                    Uploading... {uploadProgress > 0 ? `${uploadProgress}%` : ''}
                                                </>
                                            ) : (
                                                <>
                                                    <Upload className="h-4 w-4" />
                                                    Upload Image
                                                </>
                                            )}
                                        </Button>
                                    )}
                                    
                                    {!questId && selectedImageFile && (
                                        <Alert variant="warning">
                                            <AlertCircle className="h-4 w-4" />
                                            <AlertDescription>
                                                Save the quest first to enable image uploads
                                            </AlertDescription>
                                        </Alert>
                                    )}
                                </div>
                            ) : (
                                <div className="flex items-center justify-between p-2 bg-muted rounded-md">
                                    <div className="flex items-center space-x-2">
                                        <ImageIcon className="h-4 w-4 text-muted-foreground" />
                                        <span className="text-sm">{step.imageFile}</span>
                                    </div>
                                    <Button
                                        type="button"
                                        variant="ghost"
                                        size="sm"
                                        onClick={handleRemoveImage}
                                        className="text-red-500 hover:text-red-700"
                                    >
                                        <X className="h-4 w-4" />
                                    </Button>
                                </div>
                            )}
                            <p className="text-xs text-muted-foreground">
                                Optional image to display during AI chat
                            </p>
                        </div>
                    </div>
                )}

                <div className="space-y-2">
                    <Label>Hint (Optional)</Label>
                    <Textarea
                        value={step.hint || ''}
                        onChange={e => onChange('hint', e.target.value)}
                        placeholder="Enter hint text"
                        rows={2}
                    />
                </div>

                {(step.type === 'speech' || step.type === 'image' || step.type === 'choice' || step.type === 'sequence' || step.type === 'aichat') && (
                    <div className="space-y-2">
                        <Label>Audio File <span className="text-red-500">*</span></Label>
                        {!step.audioFile ? (
                            <div className="space-y-4">
                                <FileUpload
                                    accept={{ 'audio/*': ['.mp3', '.wav', '.ogg'] }}
                                    maxSize={10485760} // 10MB
                                    onUpload={handleFileSelect}
                                    value={selectedAudioFile}
                                    loading={isUploading}
                                    progress={uploadProgress}
                                >
                                    <div className="flex items-center space-x-2">
                                        <Music className="h-4 w-4" />
                                        <span>Select Audio File</span>
                                    </div>
                                </FileUpload>
                                
                                {selectedAudioFile && (
                                    <div className="p-3 bg-muted rounded-lg flex items-center justify-between">
                                        <div className="flex items-center space-x-2">
                                            <Music className="h-4 w-4 text-muted-foreground" />
                                            <span className="text-sm font-medium">{selectedAudioFile.name}</span>
                                            <span className="text-xs text-muted-foreground">
                                                ({(selectedAudioFile.size / 1024 / 1024).toFixed(2)} MB)
                                            </span>
                                        </div>
                                        <Button
                                            type="button"
                                            size="sm"
                                            variant="ghost"
                                            onClick={() => setSelectedAudioFile(null)}
                                            className="text-red-500 hover:text-red-700"
                                        >
                                            <X className="h-4 w-4" />
                                        </Button>
                                    </div>
                                )}
                                
                                {selectedAudioFile && (
                                    <Button
                                        type="button"
                                        onClick={handleAudioUpload}
                                        disabled={isUploading || !questId}
                                        className="w-full"
                                    >
                                        {isUploading ? (
                                            <>
                                                <Loader2 className="h-4 w-4 animate-spin" />
                                                Uploading... {uploadProgress > 0 ? `${uploadProgress}%` : ''}
                                            </>
                                        ) : (
                                            <>
                                                <Upload className="h-4 w-4" />
                                                Upload Audio File
                                            </>
                                        )}
                                    </Button>
                                )}
                                
                                {!questId && selectedAudioFile && (
                                    <Alert variant="warning">
                                        <AlertCircle className="h-4 w-4" />
                                        <AlertDescription>
                                            Save the quest first to enable audio uploads
                                        </AlertDescription>
                                    </Alert>
                                )}
                            </div>
                        ) : (
                            <div className="flex items-center justify-between p-2 bg-muted rounded-md">
                                <div className="flex items-center space-x-2">
                                    <Music className="h-4 w-4 text-muted-foreground" />
                                    <span className="text-sm">{step.audioFile}</span>
                                </div>
                                <div className="flex items-center space-x-2">
                                    {questId && (
                                        <Button
                                            type="button"
                                            variant="ghost"
                                            size="sm"
                                            onClick={() => window.open(`/audio/quests/${questId}/${step.audioFile}`, '_blank')}
                                            title="Test audio file"
                                        >
                                            <PlayCircle className="h-4 w-4" />
                                        </Button>
                                    )}
                                    <Button
                                        type="button"
                                        variant="ghost"
                                        size="sm"
                                        onClick={handleRemoveAudio}
                                        className="text-red-500 hover:text-red-700"
                                    >
                                        <X className="h-4 w-4" />
                                    </Button>
                                </div>
                            </div>
                        )}
                        <p className="text-xs text-muted-foreground">
                            Audio instructions for this step (required for speech, image, choice, sequence, and AI chat steps)
                        </p>
                    </div>
                )}

                {isUploading && (
                    <div className="space-y-2">
                        <Progress value={uploadProgress} />
                        <p className="text-sm text-muted-foreground">
                            Uploading... {uploadProgress}%
                        </p>
                    </div>
                )}

                {uploadError && (
                    <Alert variant="destructive">
                        <AlertCircle className="h-4 w-4" />
                        <AlertDescription>{uploadError}</AlertDescription>
                    </Alert>
                )}

                {uploadResult?.success && (
                    <Alert className="bg-green-50 border-green-200">
                        <CheckCircle className="h-4 w-4 text-green-500" />
                        <AlertDescription className="text-green-700">
                            {uploadResult.message}
                        </AlertDescription>
                    </Alert>
                )}

                {uploadSuccess && (
                    <Alert className="bg-green-50 border-green-200">
                        <CheckCircle className="h-4 w-4 text-green-500" />
                        <AlertDescription className="text-green-700">
                            Image uploaded successfully!
                        </AlertDescription>
                    </Alert>
                )}

                {templateSaved && (
                    <Alert className="bg-green-50 border-green-200">
                        <CheckCircle className="h-4 w-4 text-green-500" />
                        <AlertDescription className="text-green-700">
                            Template saved successfully!
                        </AlertDescription>
                    </Alert>
                )}
            </CardContent>
        </Card>
    );
};

export default StepEditor;
