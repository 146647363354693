import React, {
  useEffect,
  useState,
  useCallback,
  useRef
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useQuestState } from './QuestStateProvider';
import useSocket from '../hooks/useSocket';
import SpeechStage from './SpeechStage';
import RiddleStage from './RiddleStage';
import ImageStage from './ImageStage';
import ChoiceStage from './ChoiceStage';
import SequenceStage from './SequenceStage';
import AIChatStage from './AIChatStage';
import LoadingSpinner from './LoadingSpinner';
import ErrorMessage from './ErrorMessage';
import api from '../api';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Progress } from './ui/progress';
import { Alert, AlertDescription } from './ui/alert';
import { Button } from './ui/button';
import {
  Shield,
  Crown,
  Users,
  Award,
  RefreshCcw,
  AlertCircle,
  Clock,
  CheckCircle,
  XCircle
} from 'lucide-react';

const MAX_RECONNECT_ATTEMPTS = 3;
const CONNECTION_LOSS_DELAY = 2000; // 2 секунды до показа сообщения о потере соединения

const QuestContent = ({ quest }) => {
  const navigate = useNavigate();
  const { step: routeStep, substep: routeSubStep } = useParams();
  const {
    state: {
      questId,
      currentStep,
      currentSubStep,
      points,
      role,
      isTeam,
      teamId,
      teamMembers
    },
    updateState,
    isProcessing,
    forceSyncWithServer
  } = useQuestState();

  // Для командного режима используем questId в качестве sessionId и передаём teamId как параметр
  const {
    isConnected,
    error: socketError,
    send,
    reconnect,
    reconnectAttempts,
    removeEventListener
  } = useSocket(questId, isTeam, teamId);

  const [inputText, setInputText] = useState('');
  const [image, setImage] = useState(null);
  const [resultMessage, setResultMessage] = useState('');
  const [localError, setLocalError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [shouldShowConnectionError, setShouldShowConnectionError] = useState(false);
  const connectionLostTimerRef = useRef(null);
  const progressionLockRef = useRef(false);
  const navigationLockRef = useRef(false);
  const completionInProgressRef = useRef(false); // Флаг для предотвращения повторных завершений
  const syncEnabledRef = useRef(true); // Флаг для контроля синхронизации
  const mountedRef = useRef(true);
  const lastSyncTimeRef = useRef(Date.now());
  // Новый ref для отслеживания перенаправления на страницу завершения
  const redirectingToCompleteRef = useRef(false);
  // Новый ref для хранения интервалов синхронизации
  const syncIntervalsRef = useRef([]);

  const totalSteps = quest.points.reduce((total, point) => total + point.steps.length, 0);
  const currentTotalStep = quest.points.slice(0, currentStep - 1).reduce(
    (total, point) => total + point.steps.length, 0
  ) + currentSubStep + 1;
  const progress = (currentTotalStep / totalSteps) * 100;

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
      // Очищаем таймер при размонтировании
      if (connectionLostTimerRef.current) {
        clearTimeout(connectionLostTimerRef.current);
      }
      // Очищаем все интервалы синхронизации
      syncIntervalsRef.current.forEach(interval => clearInterval(interval));
    };
  }, []);

  // Проверка завершения квеста
  const isQuestCompleted = useCallback(() => {
    try {
      // Проверяем флаг завершения в localStorage - новая проверка
      if (localStorage.getItem(`quest_${questId}_completed`) === 'true') {
        return true;
      }
      
      const questState = localStorage.getItem(`questState_${questId}`);
      if (questState) {
        const parsedState = JSON.parse(questState);
        return parsedState.is_completed === true || parsedState.status === 'completed';
      }
      return false;
    } catch (error) {
      console.error('Error checking quest completion status:', error);
      return false;
    }
  }, [questId]);

  // Функция для очистки всех таймеров и интервалов
  const clearAllTimers = useCallback(() => {
    console.log("Очистка всех таймеров и интервалов синхронизации");
    // Очищаем все известные интервалы синхронизации
    syncIntervalsRef.current.forEach(interval => clearInterval(interval));
    syncIntervalsRef.current = [];
    
    // Очищаем большой диапазон ID для гарантии очистки всех интервалов
    for (let i = 0; i < 1000; i++) {
      clearInterval(i);
      clearTimeout(i);
    }
  }, []);

  // Обработка состояния соединения с задержкой отображения ошибки
  useEffect(() => {
    if (!isConnected) {
      // Если соединение потеряно, запускаем таймер
      if (!connectionLostTimerRef.current && reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
        connectionLostTimerRef.current = setTimeout(() => {
          if (mountedRef.current) {
            setShouldShowConnectionError(true);
          }
        }, CONNECTION_LOSS_DELAY);
      }
    } else {
      // Если соединение восстановлено, очищаем таймер и скрываем ошибку
      if (connectionLostTimerRef.current) {
        clearTimeout(connectionLostTimerRef.current);
        connectionLostTimerRef.current = null;
      }
      setShouldShowConnectionError(false);
    }
    
    // Очистка при размонтировании
    return () => {
      if (connectionLostTimerRef.current) {
        clearTimeout(connectionLostTimerRef.current);
        connectionLostTimerRef.current = null;
      }
    };
  }, [isConnected, reconnectAttempts]);

  const getCurrentStage = useCallback(() => {
    // Сначала проверяем, завершен ли квест
    if (isQuestCompleted() || redirectingToCompleteRef.current) {
      // Возвращаем специальный объект вместо null
      return { type: 'completed', text: 'Quest completed!' };
    }
    
    if (!quest?.points?.[currentStep - 1]?.steps?.[currentSubStep]) {
      return null;
    }
    return quest.points[currentStep - 1].steps[currentSubStep];
  }, [quest, currentStep, currentSubStep, isQuestCompleted]);

  const safeNavigate = useCallback(async (path, options = {}) => {
    if (navigationLockRef.current && !options.force) return;
    navigationLockRef.current = true;
    try {
      await new Promise(resolve => setTimeout(resolve, options.delay || 100));
      if (mountedRef.current) {
        console.log(`Navigating to: ${path}${options.force ? ' (forced)' : ''}`);
        
        // Если это переход на страницу завершения, устанавливаем флаг
        if (path.includes('/complete')) {
          redirectingToCompleteRef.current = true;
        }
        
        navigate(path, { replace: true });
      }
    } finally {
      setTimeout(() => {
        navigationLockRef.current = false;
      }, 500);
    }
  }, [navigate]);

  const syncTeamState = useCallback(async () => {
    // Проверяем флаг завершения в localStorage - новая проверка
    if (localStorage.getItem(`quest_${questId}_completed`) === 'true') {
        console.log("Квест отмечен как завершенный в localStorage, синхронизация отключена");
        return;
    }
    
    // Пропускаем синхронизацию, если флаг выключен или если квест завершен
    if (!syncEnabledRef.current || redirectingToCompleteRef.current || isQuestCompleted()) {
      console.log("Синхронизация с сервером отключена (возможно, квест завершается)");
      return;
    }

    if (!isTeam) {
      return; 
    }
    
    if (!teamId) {
      console.error("Невозможно синхронизировать: teamId отсутствует!");
      return;
    }
    
    // Ограничиваем частоту синхронизации, чтобы не перегружать сервер
    const now = Date.now();
    if (now - lastSyncTimeRef.current < 1000) { // Не синхронизируем чаще, чем раз в секунду
      return;
    }
    lastSyncTimeRef.current = now;

    try {
      console.log(`Синхронизация состояния команды ${teamId} для квеста ${questId}...`);
      const response = await api.get(`/team/status/${teamId}`);
      if (!mountedRef.current) return;

      const { current_step, current_substep, points: teamPoints } = response.data;

      console.log(`Серверное состояние команды: Step ${current_step}, SubStep ${current_substep}, Points: ${teamPoints}`);
      console.log(`Локальное состояние: Step ${currentStep}, SubStep ${currentSubStep}, Points: ${points}`);

      // Проверяем статус команды - если completed, не обновляем состояние
      if (response.data.status === 'completed') {
        console.log("Команда уже завершила квест! Переходим на финальный экран...");
        
        // Отключаем дальнейшую синхронизацию и устанавливаем флаги
        syncEnabledRef.current = false;
        redirectingToCompleteRef.current = true;
        localStorage.setItem(`quest_${questId}_completed`, 'true');
        
        // Очищаем все таймеры
        clearAllTimers();
        
        // Сохраняем финальное состояние в localStorage
        const completeState = {
          questId: questId,
          isTeam: true,
          teamId: teamId,
          role: role,
          points: teamPoints || points,
          is_completed: true,
          completed_at: response.data.completed_at || new Date().toISOString(),
          status: 'completed'
        };
        
        console.log("Сохраняем финальное состояние:", completeState);
        localStorage.setItem(`questState_${questId}`, JSON.stringify(completeState));
        
        // Принудительный переход вместо React Router
        window.location.href = `/quest/${questId}/complete`;
        return;
      }

      if (current_step !== currentStep || current_substep !== currentSubStep) {
        console.log("Состояния различаются, обновляем локальное состояние и навигацию...");
        await updateState({
          currentStep: current_step,
          currentSubStep: current_substep,
          points: teamPoints,
          teamId: teamId
        });

        await safeNavigate(
          `/quest/${questId}/step/${current_step}/substep/${current_substep}`
        );
      }
    } catch (error) {
      console.error('Ошибка синхронизации состояния команды:', error);
      if (error.response?.status === 404 && retryCount < MAX_RECONNECT_ATTEMPTS) {
        setRetryCount(prev => prev + 1);
        setTimeout(syncTeamState, 1000 * (retryCount + 1));
      }
    }
  }, [isTeam, teamId, currentStep, currentSubStep, questId, updateState, safeNavigate, retryCount, points, role, isQuestCompleted, clearAllTimers]);

  useEffect(() => {
    if (isTeam && teamId && syncEnabledRef.current && !redirectingToCompleteRef.current && !isQuestCompleted()) {
      // Немедленная синхронизация при монтировании
      syncTeamState();
      
      // Очищаем старые интервалы при изменении зависимостей
      syncIntervalsRef.current.forEach(interval => clearInterval(interval));
      syncIntervalsRef.current = [];
      
      // Более частая синхронизация для командного режима - каждую секунду
      const syncInterval = setInterval(() => {
        if (syncEnabledRef.current && !redirectingToCompleteRef.current && !isQuestCompleted()) {
          syncTeamState();
        }
      }, 1000);
      
      // Сохраняем ID интервала для последующей очистки
      syncIntervalsRef.current.push(syncInterval);
      
      return () => {
        clearInterval(syncInterval);
        // Удаляем этот интервал из списка
        syncIntervalsRef.current = syncIntervalsRef.current.filter(id => id !== syncInterval);
      };
    }
  }, [isTeam, teamId, syncTeamState, isQuestCompleted]);

  // Функция для завершения квеста
  const completeQuest = useCallback(async () => {
    // Предотвращаем повторные вызовы
    if (completionInProgressRef.current) {
      console.log("Завершение квеста уже в процессе, пропускаем повторный вызов");
      return;
    }
    
    completionInProgressRef.current = true;
    console.log("===== НАЧАЛО ПРОЦЕССА ЗАВЕРШЕНИЯ КВЕСТА =====");
    
    try {
        // 1. Отключаем все возможные синхронизации
        syncEnabledRef.current = false;
        redirectingToCompleteRef.current = true;
        
        // 2. Очищаем все интервалы синхронизации - радикальное решение
        clearAllTimers();
        
        // 3. Создаем финальное состояние
        const finalState = {
            is_completed: true,
            completed_at: new Date().toISOString(),
            points: points,
            status: 'completed',
            current_step: currentStep,
            current_substep: currentSubStep,
            quest_id: questId,
            currentStep: currentStep,
            currentSubStep: currentSubStep,
            questId: questId
        };

        const completeState = {
            ...finalState,
            questId: questId,
            isTeam: isTeam,
            teamId: teamId,
            role: role,
            points: points
        };
        
        // 4. Сохраняем локально ПЕРЕД любыми запросами к API
        console.log('Сохраняем состояние завершения в localStorage:', completeState);
        localStorage.setItem(`questState_${questId}`, JSON.stringify(completeState));
        
        // 5. Добавляем дополнительный флаг для предотвращения синхронизации
        localStorage.setItem(`quest_${questId}_completed`, 'true');
        
        // 6. Короткая пауза для завершения любых текущих операций
        await new Promise(resolve => setTimeout(resolve, 100));
        
        // 7. Отправляем запрос на сервер и НЕ ЖДЕМ ответа
        if (isTeam && teamId) {
            console.log(`Отправляем запрос завершения команды на сервер для team_id=${teamId}`);
            
            // Не ждем завершения запроса
            api.post(`/team/${teamId}/complete`, finalState)
                .then(() => console.log('Запрос на завершение команды успешно отправлен'))
                .catch(err => console.error('Ошибка при отправке запроса завершения:', err));
        } else {
            console.log('Обновляем состояние индивидуального квеста (завершение)');
            updateState(finalState, true).catch(err => 
                console.error('Ошибка обновления состояния:', err)
            );
        }
        
        // 8. Принудительный переход минуя React Router - радикальное решение
        console.log(`Принудительный переход на финальный экран: /quest/${questId}/complete`);
        
        // Даем немного времени на завершение всех процессов
        setTimeout(() => {
            // Прямая навигация через window.location вместо React Router
            window.location.href = `/quest/${questId}/complete`;
        }, 300);
        
    } catch (error) {
        console.error('Критическая ошибка в процессе завершения:', error);
        setLocalError('Ошибка при завершении квеста. Пожалуйста, обновите страницу.');
        
        // В случае ошибки, пытаемся восстановить состояние
        completionInProgressRef.current = false;
        syncEnabledRef.current = true;
        redirectingToCompleteRef.current = false;
    }
  }, [isTeam, teamId, questId, currentStep, currentSubStep, points, role, updateState, clearAllTimers]);

  const handleStepProgression = useCallback(async () => {
    if (progressionLockRef.current || isProcessing || !isConnected) {
      console.log('Progression blocked:', {
        locked: progressionLockRef.current,
        processing: isProcessing,
        connected: isConnected
      });
      return;
    }

    if (isTeam && !teamId) {
      console.error("КРИТИЧЕСКАЯ ОШИБКА: teamId отсутствует для командного режима");
      setLocalError('Ошибка ID команды - обратитесь в поддержку');
      return;
    }

    progressionLockRef.current = true;
    setIsLoading(true);

    try {
      const currentPoint = quest.points[currentStep - 1];
      if (!currentPoint) {
        throw new Error('Invalid current point');
      }

      let nextStep = currentStep;
      let nextSubStep = currentSubStep;

      if (currentSubStep + 1 >= currentPoint.steps.length) {
        if (currentStep >= quest.points.length) {
          // Обнаружено завершение квеста - вызываем специальную функцию
          console.log('Обнаружено завершение квеста! Вызываем специальную функцию completeQuest()');
          await completeQuest();
          
          // Завершаем функцию здесь
          progressionLockRef.current = false;
          if (mountedRef.current) {
            setIsLoading(false);
          }
          return;
        }
        
        // Переход к следующей точке (нормальная прогрессия)
        nextStep = currentStep + 1;
        nextSubStep = 0;
      } else {
        // Переход к следующему шагу в текущей точке
        nextSubStep = currentSubStep + 1;
      }

      // Перед обновлением состояния сохраняем режим для командного квеста
      if (isTeam && teamId) {
        // Сохраняем режим в localStorage для надежности при каждом переходе
        localStorage.setItem(`questMode_${questId}`, JSON.stringify({
            isTeam: true,
            role: role || "",
            teamId: teamId
        }));
      }

      const newState = {
        currentStep: nextStep,
        currentSubStep: nextSubStep,
        points: points,
        teamId: teamId, // Явно сохраняем teamId
        completedSteps: nextStep > currentStep
          ? [...(quest.completedSteps || []), currentStep]
          : quest.completedSteps || []
      };

      // Подготавливаем данные в формате сервера
      const serverData = {
        current_step: nextStep,
        current_substep: nextSubStep,
        points: points,
        // Дублируем в camelCase для обратной совместимости
        currentStep: nextStep,
        currentSubStep: nextSubStep,
        teamId: teamId,
        team_id: teamId, // Дублируем для сервера
        questId: questId
      };

      if (isTeam && teamId) {
        // ВАЖНО: Сначала прямой API запрос для гарантированного обновления
        console.log(`Отправка обновления напрямую через API для команды ${teamId}:`, serverData);
        try {
          await api.post(`/team/status/${teamId}`, serverData);
        } catch (apiError) {
          console.error("Ошибка отправки через API:", apiError);
          throw apiError; // Пробрасываем ошибку дальше
        }
      }

      // Затем отправка через WebSocket для мгновенного обновления
      if (isConnected) {
        console.log(`Отправка обновления через WebSocket: Step ${nextStep}, SubStep ${nextSubStep}, teamId: ${teamId}`);
        send({
          type: isTeam ? 'team_state_update' : 'state_update',
          data: serverData
        });
      }

      // Обновляем локальное состояние
      await updateState(newState);

      // Переход к новому шагу
      await safeNavigate(
        `/quest/${questId}/step/${nextStep}/substep/${nextSubStep}`
      );

      // Для командного режима принудительно синхронизируемся с сервером
      if (isTeam && teamId) {
        setTimeout(() => {
          if (syncEnabledRef.current && !redirectingToCompleteRef.current && !isQuestCompleted()) {
            syncTeamState();
          }
        }, 500);
      }

    } catch (error) {
      console.error('Error in progression:', error);
      setLocalError(`Ошибка перехода: ${error.message}`);

      if (error.response?.status === 404 && isTeam) {
        await syncTeamState();
      }
    } finally {
      if (mountedRef.current) {
        progressionLockRef.current = false;
        setIsLoading(false);
      }
    }
  }, [
    quest,
    currentStep,
    currentSubStep,
    questId,
    isProcessing,
    isConnected,
    isTeam,
    teamId,
    points,
    updateState,
    send,
    safeNavigate,
    syncTeamState,
    role,
    completeQuest,
    isQuestCompleted
  ]);

  const handleTextSubmit = async (event) => {
    event.preventDefault();
    if (isProcessing || !inputText.trim() || !isConnected) return;

    const currentStage = getCurrentStage();
    if (!currentStage?.correctAnswer) return;

    setIsLoading(true);
    try {
      const correctAnswers = currentStage.correctAnswer
        .split('|')
        .map(answer => answer.trim().toLowerCase());
      const userAnswer = inputText.trim().toLowerCase();
      const isCorrect = correctAnswers.includes(userAnswer);

      if (isCorrect) {
        setResultMessage('Correct!');
        setInputText('');
        console.log('Правильный ответ! Переходим к следующему шагу...');
        await new Promise(resolve => setTimeout(resolve, 1000));
        await handleStepProgression();
      } else {
        const result = await updateState({
          points: Math.max(0, points - 5),
          incorrectAttempts: (quest.incorrectAttempts || 0) + 1,
          teamId: teamId,
        });

        if (mountedRef.current) {
          setResultMessage(
            `Incorrect answer. Please try again! Points remaining: ${result?.points || points}`
          );
        }
      }
    } catch (error) {
      console.error('Error processing answer:', error);
      if (mountedRef.current) {
        setLocalError('Error checking answer');
      }
    } finally {
      if (mountedRef.current) {
        setIsLoading(false);
      }
    }
  };

  const handleImageSubmit = async (event) => {
    event.preventDefault();
    if (isProcessing || !image || !isConnected) return;

    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', image);
    formData.append('quest_id', questId);
    formData.append('point_index', currentStep - 1);
    formData.append('step_index', currentSubStep);
    
    // Для командного режима добавляем team_id
    if (isTeam && teamId) {
        formData.append('team_id', teamId);
    }

    try {
      const response = await api.uploadImage(formData, questId, currentStep - 1, currentSubStep);
      if (!mountedRef.current) return;

      const similarity = response.data.similarity || 0;
      const similarityThreshold = response.data.threshold || 0;

      if (response.data.success) {
        setImage(null);
        setResultMessage('Image matches the template!');
        console.log('Изображение соответствует шаблону! Переходим к следующему шагу...');
        await new Promise(resolve => setTimeout(resolve, 1000));
        await handleStepProgression();
      } else {
        const result = await updateState({
          points: Math.max(0, points - 5),
          incorrectAttempts: (quest.incorrectAttempts || 0) + 1,
          teamId: teamId,
        });

        if (mountedRef.current) {
          setResultMessage(
            `Image doesn't match. Required similarity: ${similarityThreshold}%, got: ${similarity.toFixed(
              2
            )}%. Points: ${result?.points || points}`
          );
        }
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      if (mountedRef.current) {
        setLocalError(error.response?.data?.message || 'Error uploading image');
      }
    } finally {
      if (mountedRef.current) {
        setIsLoading(false);
      }
    }
  };

  const handleHint = async (hintId) => {
    if (!hintId || isProcessing || !isConnected) return;

    try {
      const result = await updateState({
        points: Math.max(0, points - 10),
        usedHints: [...(quest.usedHints || []), hintId],
        teamId: teamId,
      });

      if (result && mountedRef.current) {
        setResultMessage(`Hint applied. Points remaining: ${result.points}`);
      }
    } catch (error) {
      console.error('Error applying hint:', error);
      if (mountedRef.current) {
        setLocalError('Error showing hint');
      }
    }
  };

  useEffect(() => {
    if (mountedRef.current) {
      setResultMessage('');
    }
  }, [currentStep, currentSubStep]);

  // Добавляем кнопку ручной синхронизации для командного режима
  const handleManualSync = async () => {
    try {
      setIsLoading(true);
      
      if (isTeam && teamId) {
        console.log("Запуск принудительной синхронизации с сервером...");
        await forceSyncWithServer();
      }
    } catch (error) {
      console.error("Ошибка ручной синхронизации:", error);
      setLocalError("Ошибка синхронизации с сервером");
    } finally {
      setIsLoading(false);
    }
  };

  // Эффект для проверки соответствия параметров URL текущему состоянию
  useEffect(() => {
    // Пропускаем если квест уже завершен или идет перенаправление
    if (redirectingToCompleteRef.current || isQuestCompleted()) {
      return;
    }
    
    if (routeStep !== undefined && routeSubStep !== undefined) {
      const routeStepNum = parseInt(routeStep, 10);
      const routeSubStepNum = parseInt(routeSubStep, 10);
      
      if (routeStepNum !== currentStep || routeSubStepNum !== currentSubStep) {
        console.log(`URL не соответствует текущему состоянию. URL: ${routeStepNum}/${routeSubStepNum}, Состояние: ${currentStep}/${currentSubStep}`);
        
        // Проверяем что шаги допустимы для квеста
        if (quest?.points?.length >= routeStepNum && 
            quest?.points[routeStepNum-1]?.steps?.length >= routeSubStepNum) {
          console.log('Синхронизируем состояние с URL...');
          updateState({
            currentStep: routeStepNum,
            currentSubStep: routeSubStepNum,
            teamId: teamId
          });
        } else {
          console.log('URL содержит недопустимые шаги, перенаправляем на текущее состояние...');
          safeNavigate(`/quest/${questId}/step/${currentStep}/substep/${currentSubStep}`);
        }
      }
    }
  }, [routeStep, routeSubStep, currentStep, currentSubStep, safeNavigate, questId, quest, updateState, teamId, isQuestCompleted]);

  // При загрузке компонента и каждый раз при изменении режима квеста проверяем наличие teamId в localStorage
  useEffect(() => {
    if (isTeam && !teamId) {
      console.error("ОШИБКА: isTeam=true, но teamId отсутствует в компоненте QuestContent");
      
      // Пытаемся восстановить teamId из localStorage
      try {
        const questMode = localStorage.getItem(`questMode_${questId}`);
        if (questMode) {
          const questModeData = JSON.parse(questMode);
          if (questModeData.teamId) {
            console.log("Восстанавливаем teamId из questMode:", questModeData.teamId);
            updateState({
              teamId: questModeData.teamId,
              isTeam: true,
              role: questModeData.role || role
            }, true);
          }
        }
      } catch (error) {
        console.error("Ошибка восстановления teamId из localStorage:", error);
        setLocalError("Ошибка загрузки данных команды. Пожалуйста, перезагрузите страницу.");
      }
    }
  }, [isTeam, teamId, questId, updateState, role]);

  // Проверка, перенаправляется ли пользователь на страницу завершения
  useEffect(() => {
    if (redirectingToCompleteRef.current || isQuestCompleted()) {
      console.log("Обнаружен флаг завершения, выполняем принудительный переход");
      localStorage.setItem(`quest_${questId}_completed`, 'true');
      
      // Отключаем синхронизацию
      syncEnabledRef.current = false;
      
      // Очищаем все таймеры
      clearAllTimers();
      
      // Принудительный переход
      window.location.href = `/quest/${questId}/complete`;
    }
  }, [questId, isQuestCompleted, clearAllTimers]);

  if (!isConnected && shouldShowConnectionError && reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
    return (
      <Card className="w-full max-w-md mx-auto">
        <CardContent className="p-6">
          <Alert variant="destructive" className="mb-4">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription className="flex items-center justify-between">
              <span>Lost connection to server (Attempt {reconnectAttempts + 1}/{MAX_RECONNECT_ATTEMPTS})</span>
              <Button
                variant="outline"
                size="sm"
                onClick={reconnect}
                className="ml-4"
              >
                <RefreshCcw className="h-4 w-4 mr-2" />
                Reconnect
              </Button>
            </AlertDescription>
          </Alert>
        </CardContent>
      </Card>
    );
  }

  if (socketError) {
    return (
      <ErrorMessage
        message={`Connection error: ${socketError}`}
        critical={true}
        retryAction={reconnect}
      />
    );
  }

  if (isLoading || isProcessing) {
    return <LoadingSpinner message="Processing..." />;
  }

  const currentStage = getCurrentStage();
  if (!currentStage) {
    return <ErrorMessage message="Invalid quest stage" />;
  }

  // Обработка специального типа 'completed' (когда квест завершен, но компонент всё ещё монтирован)
  if (currentStage.type === 'completed') {
    // Мы уже должны перенаправляться, показываем загрузку
    return <LoadingSpinner message="Quest completed! Redirecting to summary..." />;
  }

  return (
    <div className="quest-content">
      <Card className="mb-6">
        <CardHeader className="pb-2">
          <CardTitle className="flex items-center justify-between">
            <h1>{quest.name}</h1>
            <div className="flex items-center gap-2 text-sm text-muted-foreground">
              {isTeam ? (
                <>
                  <Users className="h-4 w-4" />
                  <span>Team Quest</span>
                  <Shield className="h-4 w-4" />
                  <span>{role || 'No Role'}</span>
                </>
              ) : (
                <>
                  <Shield className="h-4 w-4" />
                  <span>Solo Quest</span>
                </>
              )}
            </div>
          </CardTitle>
        </CardHeader>

        <CardContent>
          {isTeam && teamMembers && (
            <div className="mb-4 p-4 bg-background/50 rounded-lg">
              <div className="flex items-center gap-2">
                <Users className="h-5 w-5 text-primary" />
                <h3 className="font-medium">Team Members</h3>
                {isTeam && (
                  <Button 
                    size="sm" 
                    variant="outline" 
                    onClick={handleManualSync}
                    className="ml-auto"
                  >
                    <RefreshCcw className="h-3 w-3 mr-1" />
                    Sync
                  </Button>
                )}
              </div>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                {teamMembers.map((member) => (
                  <div
                    key={member.userId}
                    className="flex items-center gap-2 text-sm p-2 rounded-lg bg-background/75"
                  >
                    <div className="flex items-center gap-1">
                      {member.isLeader && <Crown className="h-4 w-4 text-yellow-500" />}
                      <span className="font-medium">{member.username}</span>
                    </div>
                    <div className="flex items-center gap-1 ml-auto">
                      <Shield className={`h-4 w-4 ${member.role === 'Gamer' ? 'text-green-500' :
                          member.role === 'Listener' ? 'text-blue-500' :
                            'text-purple-500'
                        }`} />
                      <span className="text-xs text-gray-500">{member.role}</span>
                      {member.status === 'online' ? (
                        <CheckCircle className="h-3 w-3 text-green-500" />
                      ) : (
                        <Clock className="h-3 w-3 text-gray-400" />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="space-y-2">
            <div className="flex justify-between text-sm">
              <span>
                Step {currentStep} of {quest.points.length}
              </span>
              <span>Points: {points}</span>
            </div>
            <Progress value={progress} className="h-2" />
          </div>
        </CardContent>
      </Card>

      {localError && (
        <ErrorMessage
          message={localError}
          retryAction={() => setLocalError(null)}
          timeout={5000}
        />
      )}

      {(() => {
        const commonProps = {
          questId,
          character: quest.points[currentStep - 1].character,
          text: currentStage.text,
          audioFile: currentStage.audioFile,
          isTeam,
          teamId,
          role
        };

        switch (currentStage.type) {
          case 'speech':
            return (
              <SpeechStage
                {...commonProps}
                onNext={handleStepProgression}
                disabled={isTeam && role === 'Listener'}
              />
            );
          case 'riddle':
            return (
              <RiddleStage
                {...commonProps}
                inputText={inputText}
                onInputChange={(e) => setInputText(e.target.value)}
                onSubmit={handleTextSubmit}
                showHint={!!currentStage.hint && points > 10}
                onHint={() => handleHint(currentStage.id)}
                resultMessage={resultMessage}
                isProcessing={isLoading}
                hint={currentStage.hint}
                disabled={isTeam && role === 'Listener'}
              />
            );
          case 'image':
            return (
              <ImageStage
                {...commonProps}
                onFileChange={(e) => setImage(e.target.files[0])}
                onSubmit={handleImageSubmit}
                showHint={!!currentStage.hint && points > 10}
                onHint={() => handleHint(currentStage.id)}
                resultMessage={resultMessage}
                isProcessing={isLoading}
                hint={currentStage.hint}
                disabled={isTeam && role !== 'Gamer'}
              />
            );
          case 'choice':
            return (
              <ChoiceStage
                {...commonProps}
                options={currentStage.options}
                correctOptions={currentStage.correctOptions}
                multipleSelect={currentStage.multipleSelect}
                onNext={handleStepProgression}
                showHint={!!currentStage.hint && points > 10}
                onHint={() => handleHint(currentStage.id)}
                resultMessage={resultMessage}
                isProcessing={isLoading}
                hint={currentStage.hint}
                currentStepNum={currentStep}
                currentSubStepNum={currentSubStep}
              />
            );
          case 'sequence':
            return (
              <SequenceStage
                {...commonProps}
                sequenceItems={currentStage.sequenceItems}
                correctSequence={currentStage.correctSequence}
                onNext={handleStepProgression}
                showHint={!!currentStage.hint && points > 10}
                onHint={() => handleHint(currentStage.id)}
                resultMessage={resultMessage}
                isProcessing={isLoading}
                hint={currentStage.hint}
                currentStepNum={currentStep}
                currentSubStepNum={currentSubStep}
              />
            );
          case 'aichat':
            return (
              <AIChatStage
                {...commonProps}
                pointIndex={currentStep - 1}
                stepIndex={currentSubStep}
                showHint={!!currentStage.hint && points > 10}
                onHint={() => handleHint(currentStage.id)}
                isProcessing={isLoading}
                hint={currentStage.hint}
                imageFile={currentStage.imageFile}
                onNext={handleStepProgression}
              />
            );
          default:
            return (
              <Card className="w-full max-w-md mx-auto">
                <CardContent className="p-6">
                  <Alert variant="destructive">
                    <AlertCircle className="h-4 w-4" />
                    <AlertDescription>
                      Unknown stage type: {currentStage.type}
                    </AlertDescription>
                  </Alert>
                </CardContent>
              </Card>
            );
        }
      })()}

      {isTeam && (
        <Card className="mt-4">
          <CardContent className="p-4">
            <Alert className="bg-primary/10 border-primary/20">
              <AlertDescription className="flex items-center gap-2">
                <Shield className="h-4 w-4" />
                {role === 'Gamer' ? (
                  'As a Gamer, you can submit answers and solve image puzzles'
                ) : role === 'Listener' ? (
                  'As a Listener, focus on audio challenges and support your team'
                ) : (
                  'As a Regular member, you can help solve riddles but not submit images'
                )}
              </AlertDescription>
            </Alert>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

QuestContent.propTypes = {
  quest: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    points: PropTypes.arrayOf(
      PropTypes.shape({
        character: PropTypes.string.isRequired,
        steps: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.oneOf(['speech', 'riddle', 'image', 'choice', 'sequence', 'aichat']).isRequired,
            text: PropTypes.string.isRequired,
            correctAnswer: PropTypes.string,
            hint: PropTypes.string,
            audioFile: PropTypes.string,
            id: PropTypes.string,
          })
        ).isRequired,
      })
    ).isRequired,
    completedSteps: PropTypes.arrayOf(PropTypes.number),
    incorrectAttempts: PropTypes.number,
    usedHints: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default QuestContent;
