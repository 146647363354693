import React, { useEffect, useState, useCallback } from 'react';
import './App.css';
import StartScreen from './components/StartScreen';
import FinishScreen from './components/FinishScreen';
import Account from './components/Account';
import PaymentSuccess from './components/PaymentSuccess';
import PaymentCancel from './components/PaymentCancel';
import QuestPage from './components/QuestPage';
import QuestContent from './components/QuestContent';
import TeamCreate from './components/TeamCreate';
import TeamWaitingRoom from './components/TeamWaitingRoom';
import TeamJoin from './components/TeamJoin';
import Login from './components/Login';
import Register from './components/Register';
import LoadingSpinner from './components/LoadingSpinner';
import ErrorMessage from './components/ErrorMessage';
import { useQuest } from './hooks/useQuest';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    useParams,
    useNavigate,
} from 'react-router-dom';
import { QuestStateProvider } from './components/QuestStateProvider';
import { TooltipProvider } from "./components/ui/tooltip";
import api from './api';
import useSocket from './hooks/useSocket';
import AdminPanel from './components/admin/AdminPanel';

// Функция для очистки всех таймеров и интервалов
const clearAllTimers = () => {
    console.log("Очистка всех таймеров и интервалов");
    // Очищаем большой диапазон ID для гарантии очистки всех интервалов
    for (let i = 0; i < 1000; i++) {
        clearInterval(i);
        clearTimeout(i);
    }
};

const QuestRoute = ({ initialQuest, isLoading }) => {
    const { id: questId } = useParams();
    const navigate = useNavigate();
    const [localError, setLocalError] = useState(null);
    const [questState, setQuestState] = useState(null);
    const [loadingState, setLoadingState] = useState(true);
    const { quest, loading: questLoading, error: questError } = useQuest(questId);

    // Проверка на завершение квеста
    useEffect(() => {
        // Проверяем, завершен ли квест
        const checkIfCompleted = () => {
            try {
                // Проверяем сначала специальный флаг
                if (localStorage.getItem(`quest_${questId}_completed`) === 'true') {
                    return true;
                }
                
                const questState = localStorage.getItem(`questState_${questId}`);
                if (questState) {
                    const parsedState = JSON.parse(questState);
                    return parsedState.is_completed === true || parsedState.status === 'completed';
                }
                return false;
            } catch (error) {
                console.error('Error checking quest completion status:', error);
                return false;
            }
        };

        // Если квест завершен, перенаправляем на страницу завершения
        if (checkIfCompleted()) {
            console.log(`Квест ${questId} завершен, перенаправляем на финальный экран`);
            // Очищаем все таймеры и интервалы
            clearAllTimers();
            // Используем window.location вместо navigate для полной перезагрузки
            window.location.href = `/quest/${questId}/complete`;
        }
    }, [questId, navigate]);

    // Получаем информацию о режиме квеста из localStorage
    const questMode = localStorage.getItem(`questMode_${questId}`);
    const questModeData = questMode ? JSON.parse(questMode) : null;
    const isTeam = questModeData?.isTeam;
    const teamId = questModeData?.teamId;
    const role = questModeData?.role;

    // Добавляем отладочный вывод для диагностики проблемы с teamId
    useEffect(() => {
        console.log("QuestRoute initializing with:", {
            questId,
            isTeam,
            teamId,
            role,
            questModeData
        });

        // Проверка на корректность данных о команде
        if (isTeam && !teamId) {
            console.error("КРИТИЧЕСКАЯ ОШИБКА: isTeam=true, но teamId отсутствует в localStorage!");
            setLocalError("Ошибка получения ID команды. Пожалуйста, вернитесь на главную страницу и начните заново.");
        }
    }, [questId, isTeam, teamId, role, questModeData]);

    useEffect(() => {
        const fetchQuestState = async () => {
            try {
                // Проверка на корректность данных о команде перед запросом
                if (isTeam && !teamId) {
                    throw new Error("teamId отсутствует для командного режима");
                }

                let response;
                if (isTeam && teamId) {
                    console.log(`Fetching team state for team ${teamId}`);
                    response = await api.get(`/team/status/${teamId}`);
                } else {
                    console.log(`Fetching individual quest state for quest ${questId}`);
                    response = await api.get(`/quest-state/${questId}`);
                }

                const stateData = response.data;
                console.log("Received state data:", stateData);

                // Обязательно проверяем и гарантируем наличие teamId для командного режима
                if (isTeam && !teamId) {
                    console.error("teamId отсутствует для командного режима после получения данных");
                    throw new Error("ID команды не найден. Пожалуйста, начните заново.");
                }

                // Проверяем, завершен ли квест
                if (stateData.status === 'completed' || stateData.is_completed) {
                    console.log(`Квест ${questId} завершен, перенаправляем на финальный экран`);
                    localStorage.setItem(`quest_${questId}_completed`, 'true');
                    // Очищаем все таймеры перед переходом
                    clearAllTimers();
                    // Используем window.location для гарантированного перехода
                    window.location.href = `/quest/${questId}/complete`;
                    return;
                }

                const newQuestState = {
                    questId,
                    currentStep: stateData.current_step || stateData.currentStep || 1,
                    currentSubStep: stateData.current_substep || stateData.currentSubStep || 0,
                    points: stateData.points || 100,
                    role: stateData.role || role,
                    isTeam: !!isTeam, // Убедимся, что это булево значение
                    teamId: teamId, // КРИТИЧЕСКИ ВАЖНО: Явно передаем teamId
                    incorrectAttempts: stateData.incorrect_attempts || stateData.incorrectAttempts || 0,
                    usedHints: stateData.used_hints || stateData.usedHints || [],
                    completedSteps: stateData.completed_steps || stateData.completedSteps || [],
                    teamMembers: stateData.members || [],
                    error: null,
                };

                console.log("Setting quest state:", newQuestState);
                setQuestState(newQuestState);
                setLoadingState(false);

                navigate(
                    `/quest/${questId}/step/${newQuestState.currentStep}/substep/${newQuestState.currentSubStep}`,
                    { replace: true }
                );
            } catch (error) {
                console.error('Error fetching quest state:', error);
                setLocalError(`Failed to load quest state: ${error.message}`);
                setLoadingState(false);
            }
        };

        if (quest) {
            fetchQuestState();
        }
    }, [quest, isTeam, teamId, questId, role, navigate]);

    if (questLoading || loadingState) {
        return <LoadingSpinner message="Loading quest..." />;
    }

    if (questError || localError) {
        return <ErrorMessage message={questError || localError} />;
    }

    if (!quest) {
        return <ErrorMessage message="Quest not found" />;
    }

    if (!questState) {
        return <LoadingSpinner message="Initializing quest..." />;
    }

    // Проверка перед передачей в QuestStateProvider
    if (questState.isTeam && !questState.teamId) {
        return <ErrorMessage message="Team ID is missing. Please restart the quest." />;
    }

    return (
        <QuestStateProvider initialState={questState}>
            <QuestContent quest={quest} onError={setLocalError} />
        </QuestStateProvider>
    );
};

const App = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [quest, setQuest] = useState(() => {
        const saved = localStorage.getItem('currentQuest');
        return saved ? JSON.parse(saved) : null;
    });

    useEffect(() => {
        if (quest) {
            localStorage.setItem('currentQuest', JSON.stringify(quest));
        }
    }, [quest]);

    const questIdForSocket = quest ? quest.id : null;
    const questModeData = quest ? JSON.parse(localStorage.getItem(`questMode_${quest.id}`) || "{}") : {};
    const isTeamMode = questModeData.isTeam || false;
    const currentTeamId = questModeData.teamId || null;

    // Отладочный вывод для проверки корректности данных
    useEffect(() => {
        if (quest) {
            console.log("App component initialized with:", {
                questId: quest.id,
                isTeamMode,
                teamId: currentTeamId,
                questModeData
            });
            
            // Проверка на корректность teamId
            if (isTeamMode && !currentTeamId) {
                console.error("ОШИБКА: isTeamMode=true, но teamId отсутствует в App!");
            }
        }
    }, [quest, isTeamMode, currentTeamId, questModeData]);

    const { isConnected, sendMessage, closeWebSocket } = useSocket(questIdForSocket, isTeamMode, currentTeamId);

    const handleStartQuest = useCallback(
        async (questId, selectedRole, teamId = null) => {
            try {
                setIsLoading(true);
                setError(null);

                console.log(`Starting quest: ${questId}, role: ${selectedRole}, teamId: ${teamId}`);
                
                // Создание и сохранение информации о режиме квеста
                const questModeData = {
                    isTeam: !!teamId,
                    role: selectedRole,
                    teamId: teamId
                };
                
                // Проверка корректности teamId для командного режима
                if (questModeData.isTeam && !questModeData.teamId) {
                    console.error("КРИТИЧЕСКАЯ ОШИБКА: isTeam=true, но teamId отсутствует при сохранении режима квеста");
                    setError('Ошибка получения ID команды');
                    setIsLoading(false);
                    return;
                }
                
                console.log("Saving quest mode data:", questModeData);
                localStorage.setItem(`questMode_${questId}`, JSON.stringify(questModeData));
                
                // Сбрасываем флаг завершения при новом старте
                localStorage.removeItem(`quest_${questId}_completed`);

                // Получение информации о квесте
                const response = await api.get(`/quests/${questId}`);
                setQuest(response.data);
                localStorage.setItem('currentQuest', JSON.stringify(response.data));

                // Отправка уведомления о начале квеста
                sendMessage({
                    type: 'start_quest',
                    data: {
                        questId,
                        role: selectedRole,
                        teamId, // teamId может быть null для индивидуального режима
                        timestamp: new Date().toISOString(),
                    },
                });

                setIsLoading(false);
                return `/quest/${questId}`;
            } catch (error) {
                console.error('Error starting quest:', error);
                setError(error.message || 'Failed to start quest');
                setIsLoading(false);
                throw error;
            }
        },
        [sendMessage]
    );

    const ProtectedRoute = ({ children, adminRequired = false }) => {
        const token = localStorage.getItem('token');
        if (!token) {
            return <Navigate to="/login" />;
        }
        if (adminRequired) {
            const userRole = localStorage.getItem('role');
            if (userRole !== 'admin') {
                return <Navigate to="/" />;
            }
        }
        return children;
    };

    const handleError = useCallback((error) => {
        console.error('Application error:', error);
        setError(
            error.response?.data?.message ||
            error.message ||
            'An unexpected error occurred'
        );
        setIsLoading(false);
    }, []);

    const clearError = useCallback(() => {
        setError(null);
    }, []);

    useEffect(() => {
        return () => {
            closeWebSocket();
            clearAllTimers();
        };
    }, [closeWebSocket]);

    return (
        <TooltipProvider>
            <Router>
                <div className="min-h-screen bg-background">
                    <div className="container mx-auto px-4 py-4">
                        {error && (
                            <ErrorMessage
                                message={error}
                                retryAction={clearError}
                                timeout={5000}
                            />
                        )}
                        <Routes>
                            <Route path="/" element={<StartScreen />} />
                            <Route
                                path="/account"
                                element={
                                    <ProtectedRoute>
                                        <Account />
                                    </ProtectedRoute>
                                }
                            />
                            <Route path="/login" element={<Login />} />
                            <Route path="/register" element={<Register />} />
                            <Route
                                path="/quest/:id/start"
                                element={
                                    <ProtectedRoute>
                                        <QuestPage onStart={handleStartQuest} isLoading={isLoading} />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/quest/:id/create-team"
                                element={
                                    <ProtectedRoute>
                                        <TeamCreate />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/quest/:id/join-team"
                                element={
                                    <ProtectedRoute>
                                        <TeamJoin />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/team/:teamId/waiting"
                                element={
                                    <ProtectedRoute>
                                        <TeamWaitingRoom />
                                    </ProtectedRoute>
                                }
                            />
                            <Route path="/payment-success" element={<PaymentSuccess />} />
                            <Route path="/payment-cancel" element={<PaymentCancel />} />
                            {/* ВАЖНО: Определяем маршрут для финального экрана ПЕРЕД остальными маршрутами квеста */}
                            <Route
                                path="/quest/:id/complete"
                                element={
                                    <ProtectedRoute>
                                        <FinishScreen />
                                    </ProtectedRoute>
                                }
                            />
                            {/* Затем определяем все остальные маршруты для квеста */}
                            <Route
                                path="/quest/:id/*"
                                element={
                                    <ProtectedRoute>
                                        <React.Suspense
                                            fallback={<LoadingSpinner message="Loading quest content..." />}
                                        >
                                            <QuestRoute initialQuest={quest} isLoading={isLoading} />
                                        </React.Suspense>
                                    </ProtectedRoute>
                                }
                            />
                            <Route path="/quest" element={<Navigate to="/" replace />} />
                            <Route 
                                path="/admin/*" 
                                element={
                                    <ProtectedRoute adminRequired>
                                        <AdminPanel />
                                    </ProtectedRoute>
                                } 
                            />
                            <Route
                                path="*"
                                element={
                                    <div className="flex flex-col items-center justify-center min-h-[400px] space-y-4">
                                        <h2 className="text-2xl font-bold">Page Not Found</h2>
                                        <p className="text-muted-foreground">The page you're looking for doesn't exist.</p>
                                        <button 
                                            onClick={() => window.history.back()}
                                            className="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2"
                                        >
                                            Go Back
                                        </button>
                                    </div>
                                }
                            />
                        </Routes>

                        {isLoading && (
                            <div className="fixed inset-0 bg-background/80 backdrop-blur-sm flex items-center justify-center">
                                <LoadingSpinner message="Loading..." />
                            </div>
                        )}
                    </div>
                </div>
            </Router>
        </TooltipProvider>
    );
};

export default App;
