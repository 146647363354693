import React from 'react';
import PropTypes from 'prop-types';

/**
 * Textarea component is a styled textarea element that supports ref forwarding.
 * It accepts standard textarea props along with an optional custom className.
 *
 * @param {object} props - Component props.
 * @param {string} [props.className] - Additional classes for custom styling.
 * @returns {JSX.Element} The rendered textarea element.
 */
const Textarea = React.forwardRef(({ className, ...props }, ref) => {
  return (
    <textarea
      ref={ref}
      className={`block w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-gray-900 shadow-sm placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm ${className || ''}`}
      {...props}
    />
  );
});

Textarea.displayName = 'Textarea';

Textarea.propTypes = {
  className: PropTypes.string,
};

export { Textarea };

