import React from 'react';
import PropTypes from 'prop-types';

/**
 * A simple Label component that wraps the HTML <label> element.
 * It supports forwarding refs and accepts custom classes for styling.
 *
 * @param {object} props - Component props.
 * @param {React.ReactNode} props.children - The label content.
 * @param {string} [props.className] - Additional classes for custom styling.
 * @param {object} rest - Other props to be passed to the <label> element (e.g., htmlFor).
 */
const Label = React.forwardRef(({ children, className, ...rest }, ref) => {
  return (
    <label
      ref={ref}
      className={`block text-sm font-medium text-gray-700 ${className || ''}`}
      {...rest}
    >
      {children}
    </label>
  );
});

Label.displayName = 'Label';

Label.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export { Label };

