import axios from 'axios';

const getToken = () => localStorage.getItem('token');
const getRefreshToken = () => localStorage.getItem('refreshToken');

const saveToken = (tokens) => {
  const { access_token, refresh_token } = tokens;
  localStorage.setItem('token', access_token);
  localStorage.setItem('refreshToken', refresh_token);
};

const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('currentQuest');
  localStorage.removeItem('questState');
  window.location.href = '/login';
};

const refreshToken = async () => {
  try {
    const existingRefreshToken = getRefreshToken();
    if (!existingRefreshToken) {
      throw new Error('No refresh token available');
    }
    const response = await axios.post('/api/account/refresh-token', { refresh_token: existingRefreshToken });
    const tokens = response.data;
    saveToken(tokens);
    return tokens.access_token;
  } catch (error) {
    console.error('Error refreshing token:', error);
    logout();
    return null;
  }
};

const api = axios.create({
  baseURL: '/api',
});

// Добавлено: Логирование запросов
api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    
    // Добавляем детальное логирование запросов
    console.log(`🌐 API Request: ${config.method.toUpperCase()} ${config.baseURL}${config.url}`, {
      headers: config.headers,
      params: config.params,
      data: config.data instanceof FormData 
        ? '<<FormData>>' 
        : config.data,
      url: `${config.baseURL}${config.url}`
    });
    
    return config;
  },
  (error) => {
    console.error('🚫 API Request Error:', error);
    return Promise.reject(error);
  }
);

// Добавлено: Логирование ответов
api.interceptors.response.use(
  (response) => {
    console.log(`✅ API Response: ${response.status} ${response.statusText}`, {
      data: response.data,
      headers: response.headers,
      url: response.config.url
    });
    return response;
  },
  async (error) => {
    if (error.response) {
      console.error(`❌ API Response Error (${error.response.status}):`, {
        status: error.response.status,
        statusText: error.response.statusText,
        data: error.response.data,
        headers: error.response.headers,
        url: error.config?.url
      });
    } else if (error.request) {
      console.error('❌ API Request Error (No Response):', {
        request: error.request,
        message: error.message,
        url: error.config?.url
      });
    } else {
      console.error('❌ API Error:', error.message);
    }

    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const newToken = await refreshToken();
      if (newToken) {
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        return api(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

const apiEndpoints = {
  login: (data) => api.post('/account/login', data),
  register: (data) => api.post('/account/register', data),
  refreshToken: (data) => api.post('/account/refresh-token', data),
  getQuests: () => api.get('/quests'),
  getQuest: (id) => api.get(`/quests/${id}`),
  getQuestState: (id) => api.get(`/quest-state/${id}`),
  updateQuestState: (id, data) => api.post(`/quest-state/${id}`, data),
  resetQuestState: (id) => api.post(`/quest-state/${id}/reset`),
  getQuestProgress: (id) => api.get(`/quest-progress/${id}`),
  completeQuest: (id, data) => api.post(`/quest/${id}/complete`, data),
  createTeam: (data) => api.post('/team/create', data),
  validateTeamCode: (code, questId) => api.post('/team/validate-code', { code }),
  joinTeamByCode: (code) => api.post('/team/join-by-code', { code }),
  startTeamQuest: (teamId, data) => api.post(`/team/${teamId}/start`, data),
  getTeamStatus: (teamId) => api.get(`/team/status/${teamId}`),
  getTeamMembers: (teamId) => api.get(`/team/members/${teamId}`),
  updateTeamRole: (teamId, data) => api.post(`/team/${teamId}/update-role`, data),
  updateTeamState: (teamId, data) => api.post(`/team/status/${teamId}`, data),
  initiatePayment: (data) => api.post('/account/pay', data),
  completePayment: (data) => api.post('/account/complete-payment', data),
  purchaseFreeQuest: (data) => api.post('/account/purchase-free-quest', data),
  getPaymentStatus: (paymentId) => api.get(`/account/payment-status/${paymentId}`),
  getProfile: () => api.get('/account/profile'),
  updateProfile: (data) => api.put('/account/profile', data),
  getQuestHistory: () => api.get('/account/quest-history'),
  getTransactionHistory: () => api.get('/account/transaction-history'),
  getPurchasedQuests: () => api.get('/account/purchased-quests'),
  getQuestStatus: (id) => api.get(`/account/quest-status/${id}`),
  uploadImage: (formData, questId, pointIndex, stepIndex) => {
    return api.post('/upload-image/', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      params: { quest_id: questId, point_index: pointIndex, step_index: stepIndex },
    });
  },
  getWebsocketToken: () => api.get('/websocket/token'),
  validateWebsocketToken: (token) => api.post('/websocket/validate-token', { token }),
  reportError: (error) =>
    api.post('/error-report', {
      error: {
        message: error.message,
        stack: error.stack,
        timestamp: new Date().toISOString(),
      },
    }),
  clearQuestState: () => {
    localStorage.removeItem('currentQuest');
    localStorage.removeItem('questState');
  },
  clearTeamState: (questId) => {
    localStorage.removeItem(`questMode_${questId}`);
    localStorage.removeItem(`teamCode_${questId}`);
  },
  saveQuestMode: (questId, modeData) => {
    localStorage.setItem(`questMode_${questId}`, JSON.stringify(modeData));
  },
  getQuestMode: (questId) => {
    try {
      const data = localStorage.getItem(`questMode_${questId}`);
      return data ? JSON.parse(data) : null;
    } catch (error) {
      console.error('Error parsing quest mode data:', error);
      return null;
    }
  }
};

Object.assign(api, apiEndpoints);

export default api;
export { saveToken, logout, refreshToken };
