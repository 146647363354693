import React, {
    createContext,
    useContext,
    useState,
    useCallback,
    useEffect,
    useRef
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import useSocket from '../hooks/useSocket';
import api from '../api';

const QuestStateContext = createContext(null);

export const useQuestState = () => {
    const context = useContext(QuestStateContext);
    if (!context) {
        throw new Error('useQuestState must be used within a QuestStateProvider');
    }
    return context;
};

// Функция для преобразования серверного формата в клиентский
const mapServerToClientState = (data, currentState = {}) => {
    return {
        currentStep: data.current_step || data.currentStep || 1,
        currentSubStep: data.current_substep || data.currentSubStep || 0,
        points: data.points || 100,
        role: data.role || '',
        isTeam: data.is_team || data.isTeam || false,
        // Важное изменение: сохраняем teamId из текущего состояния, если его нет в данных
        teamId: data.team_id || data.teamId || (currentState.isTeam ? currentState.teamId : null),
        incorrectAttempts: data.incorrect_attempts || data.incorrectAttempts || 0,
        usedHints: data.used_hints || data.usedHints || [],
        completedSteps: data.completed_steps || data.completedSteps || [],
        lastUpdated: data.timestamp ? new Date(data.timestamp) : new Date(),
    };
};

export const QuestStateProvider = ({ children, initialState }) => {
    const navigate = useNavigate();
    const [state, setState] = useState(() => ({
        questId: '',
        currentStep: 1,
        currentSubStep: 0,
        points: 100,
        role: '',
        isTeam: false,
        teamId: null,
        incorrectAttempts: 0,
        usedHints: [],
        completedSteps: [],
        error: null,
        lastUpdated: new Date(),
        ...initialState,
    }));

    const [isSyncing, setIsSyncing] = useState(false);
    const lastUpdateRef = useRef(null);
    const updateTimeoutRef = useRef(null);
    const stateRef = useRef(state);
    const forceSyncTimeoutRef = useRef(null);
    // Создаем ref для функции forceSyncWithServer
    const forceSyncWithServerRef = useRef(null);

    // Обновлённый вызов useSocket: для командного режима используем questId в качестве sessionId
    const {
        isConnected,
        error: socketError,
        send,
        addEventListener,
        removeEventListener
    } = useSocket(state.questId, state.isTeam, state.teamId);

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    const syncToStorage = useCallback((newState) => {
        try {
            // Проверка на корректность состояния перед сохранением
            if (newState.isTeam && !newState.teamId) {
                console.error("КРИТИЧЕСКАЯ ОШИБКА: teamId отсутствует при сохранении в localStorage");
                // Если возможно, восстанавливаем из initialState
                if (initialState && initialState.teamId) {
                    newState.teamId = initialState.teamId;
                    console.log("Восстановлен teamId из initialState:", initialState.teamId);
                }
            }
            
            const storageKey = newState.isTeam ?
                `teamState_${newState.teamId}` :
                `questState_${newState.questId}`;
            console.log(`Сохранение состояния в localStorage (${storageKey}):`, newState);
            localStorage.setItem(storageKey, JSON.stringify(newState));
            
            // Дополнительное сохранение в questMode для надежности
            if (newState.isTeam && newState.teamId) {
                localStorage.setItem(`questMode_${newState.questId}`, JSON.stringify({
                    isTeam: true,
                    role: newState.role || "",
                    teamId: newState.teamId,
                    questId: newState.questId
                }));
            }
        } catch (error) {
            console.error('Error syncing to storage:', error);
        }
    }, [initialState]);

    const updateState = useCallback(async (updates, skipSync = false) => {
        if (!skipSync && (isSyncing || !isConnected)) {
            console.log('UpdateState пропущен: isSyncing=', isSyncing, 'isConnected=', isConnected);
            return;
        }

        try {
            setIsSyncing(true);
            const timestamp = new Date();

            // Проверка дупликатов
            if (lastUpdateRef.current &&
                JSON.stringify(lastUpdateRef.current.data) === JSON.stringify(updates) &&
                timestamp - lastUpdateRef.current.timestamp < 1000) {
                console.log('Дубликат обновления, пропускаем');
                return;
            }

            console.log('Обновление состояния:', updates);
            
            // Обеспечиваем сохранение критически важных полей
            const currentState = stateRef.current;
            const newState = {
                ...currentState,
                ...updates,
                // Если isTeam=true, гарантируем сохранение teamId
                teamId: updates.teamId || (currentState.isTeam ? currentState.teamId : null),
                lastUpdated: timestamp,
            };
            
            // Проверка на teamId для командного режима
            if (newState.isTeam && !newState.teamId) {
                console.error("ОШИБКА: teamId отсутствует в обновленном состоянии при isTeam=true");
                // Пытаемся восстановить из questMode
                try {
                    const questMode = localStorage.getItem(`questMode_${newState.questId}`);
                    if (questMode) {
                        const questModeData = JSON.parse(questMode);
                        if (questModeData.teamId) {
                            newState.teamId = questModeData.teamId;
                            console.log("Восстановлен teamId из questMode:", questModeData.teamId);
                        }
                    }
                } catch (e) {
                    console.error("Ошибка восстановления teamId из questMode:", e);
                }
            }
            
            setState(newState);
            lastUpdateRef.current = { data: updates, timestamp };

            if (!skipSync) {
                syncToStorage(newState);

                if (updateTimeoutRef.current) {
                    clearTimeout(updateTimeoutRef.current);
                }

                updateTimeoutRef.current = setTimeout(async () => {
                    try {
                        // Подготовка данных для отправки с поддержкой обоих форматов
                        const stateData = {
                            // snake_case для сервера
                            current_step: newState.currentStep,
                            current_substep: newState.currentSubStep,
                            points: newState.points,
                            role: newState.role,
                            is_team: newState.isTeam,
                            team_id: newState.teamId,
                            incorrect_attempts: newState.incorrectAttempts,
                            used_hints: newState.usedHints,
                            completed_steps: newState.completedSteps,
                            status: 'in_progress',
                            // camelCase для клиента
                            currentStep: newState.currentStep,
                            currentSubStep: newState.currentSubStep,
                            isTeam: newState.isTeam,
                            teamId: newState.teamId,
                            incorrectAttempts: newState.incorrectAttempts,
                            usedHints: newState.usedHints,
                            completedSteps: newState.completedSteps,
                            questId: newState.questId
                        };

                        console.log('Отправка данных состояния на сервер:', stateData);

                        // Отправка запроса API в зависимости от режима
                        if (newState.isTeam) {
                            if (!newState.teamId) {
                                console.error("ОШИБКА: teamId отсутствует при isTeam=true", newState);
                                setState(prev => ({ ...prev, error: 'teamId is missing' }));
                                return;
                            }
                            console.log(`Отправка API запроса на /team/status/${newState.teamId}`);
                            await api.post(`/team/status/${newState.teamId}`, stateData);
                        } else {
                            console.log(`Отправка API запроса на /quest-state/${newState.questId}`);
                            await api.post(`/quest-state/${newState.questId}`, stateData);
                        }

                        // WebSocket отправка для мгновенного обновления
                        console.log(`Отправка WebSocket-сообщения типа ${newState.isTeam ? 'team_state_update' : 'state_update'}`);
                        send({
                            type: newState.isTeam ? 'team_state_update' : 'state_update',
                            data: {
                                ...stateData,
                                timestamp: timestamp.toISOString(),
                            },
                        });
                        
                        // Дополнительный запрос синхронизации для командного режима
                        if (newState.isTeam && newState.teamId) {
                            if (forceSyncTimeoutRef.current) {
                                clearTimeout(forceSyncTimeoutRef.current);
                            }
                            
                            // Запускаем принудительную синхронизацию через 2 секунды
                            forceSyncTimeoutRef.current = setTimeout(() => {
                                if (forceSyncWithServerRef.current) {
                                    forceSyncWithServerRef.current().catch(err => 
                                        console.error("Ошибка отложенной синхронизации:", err)
                                    );
                                }
                            }, 2000);
                        }
                    } catch (error) {
                        console.error('Error syncing state:', error);
                        setState(prev => ({ ...prev, error: 'Failed to sync state' }));
                    }
                }, 300);
            }

            return newState;
        } finally {
            setIsSyncing(false);
        }
    }, [isSyncing, syncToStorage, isConnected, send]);

    // Функция для принудительной синхронизации с сервером - переопределяем через useEffect
    // для избежания циклической зависимости
    useEffect(() => {
        forceSyncWithServerRef.current = async () => {
            if (!stateRef.current.isTeam || !stateRef.current.teamId) {
                console.log("Принудительная синхронизация доступна только для командного режима");
                return;
            }

            console.log(`Принудительная синхронизация для команды ${stateRef.current.teamId}`);
            try {
                const response = await api.get(`/team/status/${stateRef.current.teamId}`);
                const serverState = response.data;
                
                console.log("Получено состояние с сервера:", serverState);
                
                // Сравниваем и применяем изменения, если они есть
                if (serverState.current_step !== stateRef.current.currentStep || 
                    serverState.current_substep !== stateRef.current.currentSubStep) {
                    
                    console.log(`Обнаружено различие в этапах: сервер (${serverState.current_step}/${serverState.current_substep}) vs локальное (${stateRef.current.currentStep}/${stateRef.current.currentSubStep})`);
                    
                    // Формируем объект обновления с гарантированным teamId
                    const update = {
                        currentStep: serverState.current_step,
                        currentSubStep: serverState.current_substep,
                        points: serverState.points,
                        teamId: stateRef.current.teamId,
                        questId: stateRef.current.questId
                    };
                    
                    // Обновляем состояние без отправки на сервер (skipSync=true)
                    await updateState(update, true);
                    
                    // Перенаправляем на актуальный этап
                    navigate(
                        `/quest/${stateRef.current.questId}/step/${serverState.current_step}/substep/${serverState.current_substep}`,
                        { replace: true }
                    );
                    
                    return true; // Синхронизация была выполнена
                } else {
                    console.log("Состояние на сервере соответствует локальному, синхронизация не требуется");
                    return false; // Синхронизация не требовалась
                }
            } catch (error) {
                console.error("Ошибка принудительной синхронизации:", error);
                throw error;
            }
        };
    }, [navigate, updateState]);

    // Экспортируем функцию forceSyncWithServer
    const forceSyncWithServer = useCallback(async () => {
        if (forceSyncWithServerRef.current) {
            return forceSyncWithServerRef.current();
        }
        return false;
    }, []);

    // Запускаем периодическую синхронизацию для командного режима
    useEffect(() => {
        if (state.isTeam && state.teamId) {
            console.log("Запуск периодической синхронизации для команды");
            const syncInterval = setInterval(async () => {
                try {
                    await forceSyncWithServer();
                } catch (error) {
                    console.error("Ошибка в периодической синхронизации:", error);
                }
            }, 5000); // Каждые 5 секунд
            
            return () => {
                clearInterval(syncInterval);
            };
        }
    }, [state.isTeam, state.teamId, forceSyncWithServer]);

    const addIncorrectAttempt = useCallback(async () => {
        const currentState = stateRef.current;
        const newPoints = Math.max(0, currentState.points - 5);
        const newAttempts = (currentState.incorrectAttempts || 0) + 1;

        const result = await updateState({
            points: newPoints,
            incorrectAttempts: newAttempts,
            teamId: currentState.teamId,
        });

        return result ? {
            points: result.points,
            attempts: result.incorrectAttempts
        } : null;
    }, [updateState]);

    const applyHint = useCallback(async (hintId) => {
        const currentState = stateRef.current;
        if (currentState.usedHints.includes(hintId)) {
            return;
        }

        const newPoints = Math.max(0, currentState.points - 10);
        const newHints = [...currentState.usedHints, hintId];

        const result = await updateState({
            points: newPoints,
            usedHints: newHints,
            teamId: currentState.teamId,
        });

        return result ? {
            points: result.points,
            usedHints: result.usedHints
        } : null;
    }, [updateState]);

    // Обработчик WebSocket событий
    useEffect(() => {
        const handleStateUpdate = (data) => {
            if (!data?.timestamp || isSyncing) {
                console.log("Обновление состояния пропущено: нет timestamp или идет синхронизация");
                return;
            }

            console.log("Получено обновление состояния по WebSocket:", data);
            
            const currentState = stateRef.current;
            const newTimestamp = new Date(data.timestamp);
            const lastUpdateTimestamp = currentState.lastUpdated
                ? new Date(currentState.lastUpdated)
                : new Date(0);

            console.log("Timestamp - Новый:", newTimestamp, "Последний:", lastUpdateTimestamp);

            if (newTimestamp > lastUpdateTimestamp) {
                // Проверяем принадлежность обновления к нашей команде/квесту
                if (currentState.isTeam && 
                    data.teamId !== currentState.teamId && 
                    data.team_id !== currentState.teamId) {
                    console.log("Игнорируем обновление для другой команды:", data.teamId || data.team_id);
                    return;
                }
                if (!currentState.isTeam && 
                    data.questId !== currentState.questId && 
                    data.quest_id !== currentState.questId) {
                    console.log("Игнорируем обновление для другого квеста:", data.questId || data.quest_id);
                    return;
                }

                console.log("Применяем обновление состояния:", data);
                console.log("Текущее состояние до обновления:", currentState);
                
                // Преобразуем данные из серверного формата в клиентский с передачей текущего состояния
                const mappedData = mapServerToClientState(data, currentState);
                
                // Убедимся, что teamId сохраняется для командного режима
                if (currentState.isTeam && !mappedData.teamId) {
                    mappedData.teamId = currentState.teamId;
                    console.log("Восстановлен teamId после преобразования:", mappedData.teamId);
                }
                
                console.log("Преобразованные данные:", mappedData);
                
                // Обновляем состояние без отправки на сервер (skipSync=true)
                updateState(mappedData, true);

                // Обновляем URL при изменении шага
                if (mappedData.currentStep !== currentState.currentStep ||
                    mappedData.currentSubStep !== currentState.currentSubStep) {
                    
                    console.log("Переходим на новый шаг:", 
                        mappedData.currentStep, mappedData.currentSubStep);
                        
                    navigate(
                        `/quest/${currentState.questId}/step/${mappedData.currentStep}/substep/${mappedData.currentSubStep}`,
                        { replace: true }
                    );
                }
            } else {
                console.log("Игнорируем устаревшее обновление. Новый timestamp:", newTimestamp, 
                            "Последнее обновление:", lastUpdateTimestamp);
            }
        };

        // Обработчик события quest_started
        const handleQuestStarted = async (data) => {
            console.log('Received quest_started event:', data);
            
            try {
                if (data.state) {
                    // Проверяем наличие teamId в данных
                    const teamIdFromEvent = data.team_id || data.teamId;
                    if (!teamIdFromEvent) {
                        console.error("ОШИБКА: teamId отсутствует в событии quest_started", data);
                    }
                    
                    await updateState({
                        ...mapServerToClientState(data.state, stateRef.current),
                        currentStep: 1,
                        currentSubStep: 0,
                        lastUpdated: new Date(),
                        teamId: teamIdFromEvent || state.teamId
                    }, true);

                    // Получаем актуальное состояние команды с сервера
                    if (teamIdFromEvent) {
                        const response = await api.get(`/team/status/${teamIdFromEvent}`);
                        if (response.data) {
                            await updateState({
                                currentStep: response.data.current_step || 1,
                                currentSubStep: response.data.current_substep || 0,
                                points: response.data.points || 100,
                                teamId: teamIdFromEvent,
                                lastUpdated: new Date()
                            }, true);
                            
                            // Переходим на первый шаг
                            navigate(
                                `/quest/${state.questId}/step/1/substep/0`,
                                { replace: true }
                            );
                        }
                    }
                }

                send({
                    type: 'quest_start_acknowledged',
                    data: {
                        teamId: state.teamId,
                        questId: state.questId,
                        userId: state.userId,
                        timestamp: new Date().toISOString()
                    }
                });
            } catch (error) {
                console.error('Error handling quest_started:', error);
            }
        };

        // Добавляем обработчик события quest_completed
        const handleQuestCompleted = async (data) => {
            console.log('Received quest_completed event:', data);
            
            try {
                // Сохраняем финальное состояние в localStorage для использования в FinishScreen
                const finalState = {
                    is_completed: true,
                    completed_at: data.completed_at || new Date().toISOString(),
                    points: data.points || stateRef.current.points,
                    questId: data.quest_id || data.questId || stateRef.current.questId,
                    isTeam: stateRef.current.isTeam,
                    teamId: stateRef.current.teamId,
                    role: stateRef.current.role,
                    status: 'completed'
                };
                
                // Важно: сохраняем состояние под ключом questState_questId
                // так как FinishScreen ищет его именно там
                localStorage.setItem(`questState_${finalState.questId}`, JSON.stringify(finalState));
                
                // Обновляем состояние
                await updateState({
                    is_completed: true,
                    completed_at: data.completed_at || new Date().toISOString(),
                    points: data.points || stateRef.current.points,
                    status: 'completed'
                }, true);
                
                // Переходим на финальный экран
                navigate(`/quest/${stateRef.current.questId}/complete`, { replace: true });
            } catch (error) {
                console.error('Error handling quest_completed:', error);
            }
        };

        // Добавляем слушатели для обоих типов событий обновления состояния
        console.log(`Устанавливаем обработчики событий. isTeam=${state.isTeam}, teamId=${state.teamId}`);
        
        // Для совместимости слушаем оба типа событий
        addEventListener('state_update', handleStateUpdate);
        addEventListener('team_state_update', handleStateUpdate);
        addEventListener('quest_started', handleQuestStarted);
        addEventListener('quest_completed', handleQuestCompleted);

        // Запускаем немедленную синхронизацию для командного режима
        if (state.isTeam && state.teamId) {
            console.log("Запуск первичной синхронизации при монтировании");
            setTimeout(() => {
                forceSyncWithServer().catch(err => 
                    console.error("Ошибка первичной синхронизации:", err)
                );
            }, 1000);
        }

        // Очистка при размонтировании
        return () => {
            removeEventListener('state_update', handleStateUpdate);
            removeEventListener('team_state_update', handleStateUpdate);
            removeEventListener('quest_started', handleQuestStarted);
            removeEventListener('quest_completed', handleQuestCompleted);
            
            if (forceSyncTimeoutRef.current) {
                clearTimeout(forceSyncTimeoutRef.current);
            }
        };
    }, [
        state.isTeam,
        state.teamId,
        state.questId,
        state.userId,
        navigate,
        isSyncing,
        updateState,
        addEventListener,
        removeEventListener,
        send,
        forceSyncWithServer
    ]);

    // Восстановление состояния из localStorage
    useEffect(() => {
        const storageKey = initialState.isTeam ?
            `teamState_${initialState.teamId}` :
            `questState_${initialState.questId}`;

        try {
            const savedState = localStorage.getItem(storageKey);
            if (savedState) {
                const parsed = JSON.parse(savedState);
                console.log(`Восстановлено состояние из localStorage (${storageKey}):`, parsed);
                
                // Проверка на корректность восстановленного состояния
                if (parsed.isTeam && !parsed.teamId) {
                    console.error("ОШИБКА: isTeam=true, но teamId отсутствует в сохраненном состоянии");
                    // Дополняем teamId из initialState если возможно
                    if (initialState.teamId) {
                        parsed.teamId = initialState.teamId;
                        console.log("Восстановлен teamId из initialState:", initialState.teamId);
                    }
                    // Проверяем questMode для дополнительного восстановления
                    try {
                        const questMode = localStorage.getItem(`questMode_${initialState.questId}`);
                        if (questMode) {
                            const questModeData = JSON.parse(questMode);
                            if (questModeData.teamId) {
                                parsed.teamId = questModeData.teamId;
                                console.log("Восстановлен teamId из questMode:", questModeData.teamId);
                            }
                        }
                    } catch (e) {
                        console.error("Ошибка восстановления teamId из questMode:", e);
                    }
                }
                
                setState(prev => ({
                    ...prev,
                    ...parsed,
                }));
            }
        } catch (error) {
            console.error('Error restoring state:', error);
        }
    }, [initialState.questId, initialState.teamId, initialState.isTeam]);

    // Обработка ошибок WebSocket
    useEffect(() => {
        if (socketError) {
            console.error('WebSocket error in QuestStateProvider:', socketError);
            setState(prev => ({
                ...prev,
                error: `WebSocket error: ${socketError}`
            }));
        }
    }, [socketError]);

    // Экспорт функций и состояния
    const value = {
        state,
        updateState,
        applyHint,
        addIncorrectAttempt,
        isSyncing,
        isConnected,
        isProcessing: isSyncing,
        error: state.error || socketError,
        forceSyncWithServer // Экспортируем функцию принудительной синхронизации
    };

    return (
        <QuestStateContext.Provider value={value}>
            {children}
        </QuestStateContext.Provider>
    );
};

QuestStateProvider.propTypes = {
    children: PropTypes.node.isRequired,
    initialState: PropTypes.shape({
        questId: PropTypes.string,
        currentStep: PropTypes.number,
        currentSubStep: PropTypes.number,
        points: PropTypes.number,
        role: PropTypes.string,
        isTeam: PropTypes.bool,
        teamId: PropTypes.string,
        incorrectAttempts: PropTypes.number,
        usedHints: PropTypes.arrayOf(PropTypes.string),
        completedSteps: PropTypes.arrayOf(PropTypes.number),
    }),
};

QuestStateProvider.defaultProps = {
    initialState: {
        questId: '',
        currentStep: 1,
        currentSubStep: 0,
        points: 100,
        role: '',
        isTeam: false,
        teamId: null,
        incorrectAttempts: 0,
        usedHints: [],
        completedSteps: [],
    },
};

export default QuestStateProvider;

