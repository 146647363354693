import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Alert, AlertDescription } from '../ui/alert';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '../ui/alert-dialog';
import { AlertCircle, Search, RefreshCcw, Loader2 } from 'lucide-react';
import { adminApi } from '../../api/adminApi';
import UserList from './UserList';

const UserManager = () => {
    const [users, setUsers] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState(null);
    
    const pageSize = 10;

    const fetchUsers = async () => {
        try {
            setIsLoading(true);
            setError(null);
            const response = await adminApi.getUsers({
                skip: page * pageSize,
                limit: pageSize,
                search: searchQuery.trim()
            });
            setUsers(response.users);
            setTotalUsers(response.total);
        } catch (error) {
            console.error('Error fetching users:', error);
            const handledError = adminApi.handleError(error);
            setError(handledError);
            setUsers([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, [page, searchQuery]);

    const handleSearch = useCallback((event) => {
        setSearchQuery(event.target.value);
        setPage(0);
    }, []);

    const handleDeleteClick = (user) => {
        setDeleteTarget(user);
        setShowDeleteConfirm(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            setIsLoading(true);
            await adminApi.deleteUser(deleteTarget.id);
            setShowDeleteConfirm(false);
            setDeleteTarget(null);
            await fetchUsers();
        } catch (error) {
            console.error('Error deleting user:', error);
            setError(adminApi.handleError(error));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="space-y-6">
            <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                    <CardTitle className="text-2xl font-bold">User Management</CardTitle>
                </CardHeader>

                <CardContent>
                    <div className="flex items-center space-x-2 mb-4">
                        <div className="relative flex-1">
                            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                            <Input
                                placeholder="Search users..."
                                value={searchQuery}
                                onChange={handleSearch}
                                className="pl-8"
                                disabled={isLoading}
                            />
                        </div>
                        
                        <Button
                            variant="outline"
                            size="icon"
                            onClick={fetchUsers}
                            disabled={isLoading}
                            title="Refresh user list"
                        >
                            <RefreshCcw className="h-4 w-4" />
                        </Button>
                    </div>

                    {error && (
                        <Alert variant="destructive" className="mb-4">
                            <AlertCircle className="h-4 w-4" />
                            <AlertDescription>{error.message}</AlertDescription>
                        </Alert>
                    )}

                    <UserList
                        users={users}
                        isLoading={isLoading}
                        onDelete={handleDeleteClick}
                    />

                    {totalUsers > pageSize && (
                        <div className="flex items-center justify-between mt-4">
                            <Button
                                variant="outline"
                                onClick={() => setPage(p => Math.max(0, p - 1))}
                                disabled={page === 0 || isLoading}
                            >
                                Previous
                            </Button>
                            <span className="text-sm text-muted-foreground">
                                Page {page + 1} of {Math.ceil(totalUsers / pageSize)}
                            </span>
                            <Button
                                variant="outline"
                                onClick={() => setPage(p => p + 1)}
                                disabled={page >= Math.ceil(totalUsers / pageSize) - 1 || isLoading}
                            >
                                Next
                            </Button>
                        </div>
                    )}
                </CardContent>
            </Card>

            {showDeleteConfirm && deleteTarget && (
                <AlertDialog open={showDeleteConfirm} onOpenChange={setShowDeleteConfirm}>
                    <AlertDialogContent>
                        <AlertDialogHeader>
                            <AlertDialogTitle>Delete User</AlertDialogTitle>
                            <AlertDialogDescription>
                                Are you sure you want to delete user "{deleteTarget.username}" ({deleteTarget.email})? 
                                This will remove all their data including purchased quests, progress, and feedback.
                                This action cannot be undone.
                            </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction 
                                onClick={handleDeleteConfirm}
                                className="bg-red-600 hover:bg-red-700"
                            >
                                {isLoading ? (
                                    <>
                                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                        Deleting...
                                    </>
                                ) : (
                                    "Delete User"
                                )}
                            </AlertDialogAction>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>
            )}
        </div>
    );
};

export default UserManager;
